import { Button, Checkbox, Group, Modal, Text } from "@mantine/core";
import { useState } from "react";

export const ACADetailsModal = ({ isOpen, onClose, onSave, plan }) => {
  const [planAttributes, setPlanAttributes] = useState({
    providesMinimumValue: plan.provides_minimum_value,
    providesEssentialCoverage: plan.provides_essential_coverage,
    isLowCost: plan.is_low_cost,
    spouseCoverageExcluded: plan.spouse_coverage_excluded,
    dependentCoverageExcluded: plan.dependent_coverage_excluded,
  });

  const handleAttributeChange = (attribute) => (event) => {
    const isChecked = event.currentTarget.checked;
    setPlanAttributes((prev) => ({
      ...prev,
      [attribute]: isChecked,
    }));
  };

  const handleSave = async () => {
    await onSave(planAttributes); // Pass the attributes state up to the parent component
    onClose(); // Close the modal
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="ACA Compliance Requirements"
    >
      <Text mb="lg">
        Confirm the plan's adherence to ACA standards by checking the relevant
        criteria below.
      </Text>
      <Group mb="lg">
        <Checkbox
          label="Plan provides Minimum Value"
          checked={planAttributes.providesMinimumValue}
          onChange={handleAttributeChange("providesMinimumValue")}
        />
        <Checkbox
          label="Plan provides Minimum Essential Coverage"
          checked={planAttributes.providesEssentialCoverage}
          onChange={handleAttributeChange("providesEssentialCoverage")}
        />
        <Checkbox
          label="Plan is deemed Low Cost under ACA terms"
          checked={planAttributes.isLowCost}
          onChange={handleAttributeChange("isLowCost")}
        />
        <Checkbox
          label="Spouse Coverage Excluded"
          checked={planAttributes.spouseCoverageExcluded}
          onChange={() =>
            setPlanAttributes((prev) => ({
              ...prev,
              spouseCoverageExcluded: !prev.spouseCoverageExcluded,
            }))
          }
        />
        <Checkbox
          label="Dependent Coverage Excluded"
          checked={planAttributes.dependentCoverageExcluded}
          onChange={() =>
            setPlanAttributes((prev) => ({
              ...prev,
              dependentCoverageExcluded: !prev.dependentCoverageExcluded,
            }))
          }
        />
      </Group>
      <Group mt="md">
        {" "}
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Modal>
  );
};
