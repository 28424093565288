import { clientAtom, configAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import { WritableMember } from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { Atom } from "jotai";
import { atomsWithMutation, queryClientAtom } from "jotai-tanstack-query";

export const [, updateMemberSsnAndBiologicalSexAtom] = atomsWithMutation(
  (get) => ({
    mutationKey: ["postMember"],
    mutationFn: async (
      body: Pick<WritableMember, "ssn" | "biological_sex">,
    ) => {
      const client = await get(clientAtom);
      return await client.updateMember(
        get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
        body,
      );
    },
    onSuccess(_, { memberId }) {
      const queryClient = get(queryClientAtom);
      queryClient.invalidateQueries({ queryKey: ["members", memberId] });
    },
  }),
) as [any, mutationAtomType];
