import { Button, Group, Modal, ModalProps, Text, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useAtom, useAtomValue } from "jotai";
import { setEmployeeAcaDigitalFormsConsentAtom } from "../Enroll/TermsAndConditions/API";
import { memberInfoAtom } from "./API";

export const ACAConsentModal = (props: ModalProps) => {
  const member = useAtomValue(memberInfoAtom[0]);
  const [{ isPending }, setEmployeeAcaDigitalFormConsent] = useAtom(
    setEmployeeAcaDigitalFormsConsentAtom,
  );

  const handleSave = async () => {
    await setEmployeeAcaDigitalFormConsent([
      member.aca_digital_forms_enabled_at === null,
    ]);

    notifications.show({
      title: "ACA consent updated",
      message: `ACA consent has successfully been updated.`,
    });

    props.onClose?.();
  };

  return (
    <Modal
      {...props}
      title={
        <Title order={3}>
          {member.aca_digital_forms_enabled_at
            ? "Opt-out of electronic ACA forms"
            : "Opt-in to electronic ACA forms"}
        </Title>
      }
    >
      <Text size="sm" c="gray.7">
        {member.aca_digital_forms_enabled_at
          ? "By clicking opt-out, you choose to no longer receive ACA compliance forms electronically. You will receive all necessary forms via paper mail."
          : "By clicking opt-in, you consent to receive all ACA compliance forms electronically. This includes forms such as 1095-C and other required documentation."}
      </Text>
      <Group mt="md" justify="flex-end">
        <Button variant="default" onClick={props.onClose}>
          Cancel
        </Button>
        <Button loading={isPending} onClick={handleSave}>
          Save
        </Button>
      </Group>
    </Modal>
  );
};
