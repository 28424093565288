import { carriersAtom } from "@/stores";
import { Image } from "@mantine/core";
import { useAtomValue } from "jotai";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLImageElement> {
  carrierId: "humana" | "guardian" | string; // TODO : remove string
  h?: number | string;
  w?: number | string;
}
export const CarrierImage = ({ carrierId, h, w, ...rest }: Props) => {
  const carriers = useAtomValue(carriersAtom);
  const src = carriers.get(carrierId)?.logo_url;
  return <Image src={src} h={h || 100} w={w || 100} fit="contain" {...rest} />;
};
