import { Group, NumberInput, Select, Table } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import {
  ContributionType,
  GlobalStrategyFormValues,
  PerPlanFormValues,
} from "./FormContext";

type ContributionStrategyInputProps = {
  label: string;
  formPath: string;
  form: UseFormReturnType<any>;
};

const ContributionStrategyInput = ({
  label,
  formPath,
  form,
}: ContributionStrategyInputProps) => {
  const contributionTypeInput = form.getInputProps(
    `${formPath}.contributionType`,
  );

  const useDollarSign =
    ContributionType[
      contributionTypeInput.value as keyof typeof ContributionType
    ]?.unit !== "%";

  return (
    <Table.Tr>
      <Table.Td>{label}</Table.Td>
      <Table.Td>
        <Select
          variant="filled"
          placeholder="Select a contribution strategy"
          data={Object.entries(ContributionType).map(
            ([contributionType, { label }]) => {
              return {
                value: contributionType,
                label,
              };
            },
          )}
          size="xs"
          {...contributionTypeInput}
        />
      </Table.Td>
      <Table.Td>
        <NumberInput
          variant="filled"
          hideControls
          prefix={useDollarSign ? "$" : undefined}
          suffix={!useDollarSign ? "%" : undefined}
          placeholder={useDollarSign ? "$" : "%"}
          decimalScale={2}
          size="xs"
          {...form.getInputProps(`${formPath}.contribution`)}
        />
      </Table.Td>
      <Table.Td>
        <Group>
          <NumberInput
            variant="filled"
            hideControls
            prefix="$"
            placeholder="Min Amount ($)"
            decimalScale={2}
            size="xs"
            {...form.getInputProps(`${formPath}.monthlyMinThreshold`)}
          />
          <NumberInput
            variant="filled"
            hideControls
            prefix="$"
            placeholder="Max Amount ($)"
            decimalScale={2}
            size="xs"
            {...form.getInputProps(`${formPath}.monthlyMaxThreshold`)}
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};

type ContributionStrategyTableProps = {
  formPath: string;
  form:
    | UseFormReturnType<GlobalStrategyFormValues>
    | UseFormReturnType<PerPlanFormValues>;
};
export const ContributionStrategyTable = ({
  formPath,
  form,
}: ContributionStrategyTableProps) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Dependent</Table.Th>
          <Table.Th>Contribution Type</Table.Th>
          <Table.Th>Contribution</Table.Th>
          <Table.Th>Set Monthly Thresholds (Optional)</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <ContributionStrategyInput
          label="Employee"
          formPath={`${formPath}.member`}
          form={form}
        />
        <ContributionStrategyInput
          label="Employee + Spouse"
          formPath={`${formPath}.memberSpouse`}
          form={form}
        />
        <ContributionStrategyInput
          label="Employee + Child"
          formPath={`${formPath}.memberChild`}
          form={form}
        />
        <ContributionStrategyInput
          label="Employee + Child(ren)"
          formPath={`${formPath}.memberChildren`}
          form={form}
        />
        <ContributionStrategyInput
          label="Employee + Family"
          formPath={`${formPath}.memberFamily`}
          form={form}
        />
      </Table.Tbody>
    </Table>
  );
};
