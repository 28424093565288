import { TablerIconsProps } from "@tabler/icons-react";

export const IconChevronsSort = ({
  size,
  color,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stroke,
  ...restProps
}: TablerIconsProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.66667 7.50001L10 4.16667L13.3333 7.50001M13.3333 12.5L10 15.8333L6.66667 12.5"
        stroke={color?.toString()}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
