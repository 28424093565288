import { UsCurrencyFormatter, snakeCaseToTitleCase } from "@/helpers/string";
import { LineOfCoverage, VoluntaryCoverageOption } from "@/types/api";
import { Select, Stack, Text, Title } from "@mantine/core";
import { useAtomValue } from "jotai";
import { memberDependentsAtom, memberInfoAtom } from "../../API";
import { useVoluntarySupplementalBenefitCardFormContext } from "./form";

const getElectedAmounts = (option: VoluntaryCoverageOption) => {
  const amounts = new Array<number>();

  if (option) {
    for (
      let i = Number(option.minimum_coverage);
      i <= Number(option.maximum_coverage);
      i += Number(option.increment)
    ) {
      amounts.push(i);
    }
  }

  return amounts;
};

const NotElectedOption = {
  label: `${UsCurrencyFormatter.format(0)} - Waive`,
  value: "0",
};

export const ElectionFormField = ({
  entityId,
  coverageOption,
  lineOfCoverage,
  readOnly,
}: {
  entityId: string;
  coverageOption: VoluntaryCoverageOption;
  lineOfCoverage: LineOfCoverage;
  readOnly?: boolean;
}) => {
  const options = getElectedAmounts(coverageOption);
  const dependents = useAtomValue(memberDependentsAtom);
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const form = useVoluntarySupplementalBenefitCardFormContext();
  const dependent = dependents.find(({ id }) => entityId === id);
  const index = form.values[lineOfCoverage].findIndex(
    (election) => election.entityId === entityId,
  );
  const guaranteedIssue = Number(coverageOption.guaranteed_issue);

  return (
    <Stack gap="md">
      <Title fw={600} c="gray.7" order={6}>
        {entityId === memberInfo.id && (
          <Text>
            {memberInfo.first_name} {memberInfo.last_name} Elected Amount
          </Text>
        )}
        {dependent && (
          <Text>
            {dependent.first_name} {dependent.last_name} (
            {snakeCaseToTitleCase(dependent.member_relationship)}) Elected
            Amount
          </Text>
        )}
      </Title>
      <Select
        style={{ maxWidth: 396 }}
        disabled={options.length === 0 || readOnly}
        allowDeselect={false}
        data={[
          NotElectedOption,
          ...options.map((value) => ({
            label:
              guaranteedIssue === value
                ? `${UsCurrencyFormatter.format(value)} - Guaranteed Issue`
                : UsCurrencyFormatter.format(value),
            value: value.toString(),
          })),
        ]}
        {...form.getInputProps(`${lineOfCoverage}.${index}.volume`)}
      />
    </Stack>
  );
};
