import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { Provider } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { useHydrateAtoms } from "jotai/utils";
import { PropsWithChildren } from "react";

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(error) {
        let isHandledError = false;
        if (isAxiosError(error)) {
          const status = error.response?.status ?? 0;
          if (status >= 400 && status < 600) {
            const message =
              status < 500
                ? "Sorry, we're unable to process your request due to an input error. Please check your entries and try again."
                : "Sorry, we are currently experiencing an issue on our end. Please give us a moment and try again.";
            notifications.show({
              color: "red",
              message,
              autoClose: false,
            });
            isHandledError = true;
          }
        }
        if (!isHandledError) {
          Sentry.captureException(error);
        }
      },
    },
  },
});

const HydrateAtoms = ({ children }: PropsWithChildren) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

interface Props extends PropsWithChildren {}

export const StateManagementWrapper = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <HydrateAtoms>{children}</HydrateAtoms>
      </Provider>
    </QueryClientProvider>
  );
};
