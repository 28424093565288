import { states } from "@/constants";
import { z } from "zod";

export const AddressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional().nullable(),
  city: z.string(),
  state: z.enum(states),
  zip_code: z.string().regex(/^\d{5}$/, "Zip code must be 5 digits"),
});
