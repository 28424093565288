import { clientAtom } from "@/stores";
import { mutationAtomType } from "@/types/jotai";
import { atomsWithMutation } from "jotai-tanstack-query";

export const [, reopenEnrollmentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["reopenEnrollment"],
  mutationFn: async (enrollmentId: string) => {
    const client = await get(clientAtom);
    return await client.reopenEnrollment(enrollmentId);
  },
})) as [any, mutationAtomType];
