import { authAtom } from "@/App";
import { ClaspEmployee } from "@/ClaspEmployee";
import { entityIdAtom } from "@/stores";
import { PartnerConfig } from "@/types/api";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

export const HostedClaspEmployee = ({
  claspStateOverride,
}: {
  claspStateOverride?: string;
}) => {
  const memberId = useAtomValue(entityIdAtom);
  const [claspState, setClaspState] = useState(
    localStorage.getItem("claspState"),
  );
  const environment = localStorage.getItem("environment");
  const navigate = useNavigate();
  const auth = useAtomValue(authAtom);
  const partnerConfig = useOutletContext() as PartnerConfig;
  const [, setSearchParams] = useSearchParams();
  const theme =
    JSON.parse(localStorage.getItem("theme")) || partnerConfig.theme;
  useEffect(() => {
    claspStateOverride &&
      setSearchParams((prevParams) => {
        prevParams.set("claspState", claspStateOverride);
        return prevParams;
      });
  }, [claspStateOverride]);
  return (
    <ClaspEmployee
      config={{
        auth: auth["access_token"] ? () => auth : false,
        // @ts-ignore
        apiClient: environment,
        onNavigate: (to) => {
          const newState = to.split("claspState=")[1];
          const currentState = new URLSearchParams(window.location.search).get(
            "claspState",
          );
          /**
           * Hacky workaround to routing. Routing updates are triggered
           * several times per page navigation causing the browser history
           * to get messed up. This aims to deduplicate majority of the
           * navigation alerts.
           * TODO: Revist navigation holistically to ensure we are only
           * firing events as needed.
           */
          if (newState != currentState) {
            navigate(to);
            setClaspState(undefined);
          }
        },
        navigation: "url",
        employeeId: memberId,
        links: partnerConfig.links,
      }}
      theme={theme}
      claspState={claspState}
    />
  );
};
