import { Card, Center, Image, Stack, Title } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { StytchLogin } from "@stytch/react";
import { Products, StytchLoginConfig } from "@stytch/vanilla-js";
import { getLogoSource } from "@/helpers";

export const AppLogin = () => {
  const { width } = useViewportSize();
  const sdkConfig: StytchLoginConfig = {
    products: [Products.emailMagicLinks],
    emailMagicLinksOptions: {
      loginRedirectURL: `${window.location.origin}/app/authenticate`,
      loginExpirationMinutes: 60 * 24,
      signupRedirectURL: `${window.location.origin}/app/signup`,
      createUserAsPending: true,
    },
  };
  return (
    <Center h="100vh">
      <Stack gap="xl" align="center" pb={60}>
        <Image w={180} fit="contain" src={getLogoSource()} />
        <Title order={2}>Benefit Administration</Title>
        <Card shadow="xs" withBorder p="xs" radius="md">
          <StytchLogin
            config={sdkConfig}
            styles={{
              container: {
                width: `${Math.min(width - 25, 600)}px`,
                backgroundColor: "#FFFFFF",
                borderColor: "#ffffff",
              },
              colors: {
                primary: "#19303D",
                secondary: "#5C727D",
                success: "#0C5A56",
                error: "#8B1214",
              },
              buttons: {
                primary: {
                  backgroundColor: "#000",
                  textColor: "#FFFFFF",
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                secondary: {
                  backgroundColor: "#FFFFFF",
                  textColor: "#19303D",
                  borderColor: "#19303D",
                  borderRadius: "8px",
                },
              },
              inputs: {
                backgroundColor: "#FFFFFF00",
                borderColor: "#19303D",
                borderRadius: "4px",
                placeholderColor: "#8296A1",
                textColor: "#19303D",
              },
              fontFamily: "Inter",
            }}
          />
        </Card>
      </Stack>
    </Center>
  );
};
