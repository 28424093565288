import { StepFooter } from "@/components/StepFooter";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { configAtom, partnerConfigAtom, useNavigate } from "@/stores";
import { Enrollment, EnrollmentPlan, Plan } from "@/types/api";
import { Select, Stack, Text, Title } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import {
  membersAtom,
  payrollBenefitsAtom,
  reconcilePayrollBenefitsMutationAtom,
} from "./API";

interface ExtendedEnrollmentPlan extends EnrollmentPlan {
  member: string;
  first_name: string;
  last_name: string;
}

const CREATE_NEW_BENEFIT = "create_new_benefit";

export const ClaspBenefitMappingPage = () => {
  const navigate = useNavigate();
  const config = useAtomValue(configAtom);
  const payrollBenefits = useAtomValue(payrollBenefitsAtom);
  const [mapping, setMapping] = useState(new Map());
  const members = useAtomValue(membersAtom);
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const [, reconcilePayrollBenefitsMutation] = useAtom(
    reconcilePayrollBenefitsMutationAtom,
  );

  const onSubmit = async () => {
    const data = Array.from(mapping).map(([enrollmentPlan, payrollBenefit]) => {
      return payrollBenefit === CREATE_NEW_BENEFIT
        ? {
            enrollmentPlan,
            createNewBenefit: true,
          }
        : { enrollmentPlan, payrollBenefit };
    });
    await reconcilePayrollBenefitsMutation([data]);
    navigate("medical_review");
  };

  // Group payroll benefits by member and line of coverage to populate
  // dropdowns
  const payrollBenefitsByMember = useMemo(() => {
    const map = {};
    for (const payrollBenefit of payrollBenefits) {
      const member = payrollBenefit.member as string;
      const payrollBenefitOption = {
        label: `${
          payrollBenefit.description || payrollBenefit.line_of_coverage
        } - ${payrollBenefit.member_contribution}`,
        value: payrollBenefit.id,
      };
      if (map[member] === undefined) {
        map[member] = {};
      }

      if (map[member][payrollBenefit.line_of_coverage]) {
        map[member][payrollBenefit.line_of_coverage].push(payrollBenefitOption);
      } else {
        map[member][payrollBenefit.line_of_coverage] = [payrollBenefitOption];
      }
    }
    return map;
  }, [payrollBenefits]);

  // Flatten all enrollment plans
  const enrollmentPlans = useMemo(() => {
    return members.flatMap((member) => {
      const enrollment = member.enrollments?.[0] as Enrollment | undefined;
      return enrollment
        ? (enrollment.enrolled_plans as EnrollmentPlan[])
            .filter(
              (enrollmentPlan) => !enrollmentPlan.payroll_provider_external_id,
            )
            .map((enrollment_plan) => ({
              ...enrollment_plan,
              first_name: member.first_name,
              last_name: member.last_name,
              member: member.id,
            }))
        : [];
    });
  }, [members]);

  const getPayrollBenefitOptions = (
    enrollment_plan: ExtendedEnrollmentPlan,
  ) => {
    enrollment_plan.plan = enrollment_plan.plan as Plan;
    const baseOptions =
      payrollBenefitsByMember[enrollment_plan.member]?.[
        enrollment_plan.plan.line_of_coverage
      ] || [];
    return [
      ...baseOptions,
      { label: "--- Create new benefit ---", value: CREATE_NEW_BENEFIT },
    ];
  };

  const columns = useMemo(() => {
    return [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: "Member",
      },
      {
        header: "Plan Name",
        accessorKey: "plan.plan_name",
        size: 225,
      },
      {
        header: "Line of Coverage",
        accessorKey: "plan.line_of_coverage",
      },
      {
        header: `${platformName} - Contribution Amount`,
        accessorKey: "member_contribution",
        enableGrouping: false,
      },
      {
        header: `Payroll Benefit`,
        accessorFn: (row) => mapping[row.id],
        size: 350,
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <Select
              data={getPayrollBenefitOptions(row.original)}
              value={cell.getValue() as string}
              onChange={(value) => {
                setMapping(new Map(mapping.set(row.original.id, value)));
              }}
            />
          );
        },
      },
    ];
  }, [config]);

  const valid = useMemo(() => {
    return mapping.size === enrollmentPlans.length;
  }, [mapping, enrollmentPlans]);

  const table = useMantineReactTable({
    columns,
    data: enrollmentPlans,
    ...DEFAULT_TABLE_PROPS,
    positionGlobalFilter: "left",
    enableTopToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableSorting: true,
    initialState: {
      showGlobalFilter: true,
      expanded: true,
      density: "xs",
    },
    mantineTableBodyCellProps: {
      style: {
        whiteSpace: "unset",
      },
    },
  });

  return (
    <StepFooter
      index={4}
      total={8}
      disabled={!valid}
      onContinue={onSubmit}
      onSaveProgress={() => {}}
    >
      <Stack gap="lg">
        <Title order={2}>Benefit Mapping</Title>
        <Text>
          We've matched benefit information from the insurance carriers with{" "}
          {platformName}. Benefits that could not be matched automatically are
          listed below for your review. Once matched, we will automatically
          update payroll benefit deduction amounts to match the ones listed in
          the "Contribution Amount" column.
        </Text>
        <MantineReactTable table={table} />
      </Stack>
    </StepFooter>
  );
};
