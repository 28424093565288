import { getClaspApiUrl, snakeCaseKeys } from "@/helpers";
import { ClaspEmployeeConfig, ClaspEmployerConfig } from "@/types";
import {
  Connection,
  Dependent,
  Employer,
  EmployerDocument,
  EmployerReport,
  Enrollment,
  LineOfCoverage,
  Member,
  Paginated,
  PartnerConfig,
  PayrollBenefit,
  PayrollBenefitReconciliation,
  Plan,
  PlanQuote,
  QualifyingLifeEventDocument,
  WritableEmployer,
  WritableMember,
} from "@/types/api";
import axios, { AxiosInstance, AxiosResponse } from "axios";

const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000;

export class APIClient {
  client: AxiosInstance;
  accessToken: string;
  expiresAt: Date;
  config: ClaspEmployerConfig | ClaspEmployeeConfig;
  connectionData: Connection;
  connectionId: string;

  constructor(config: ClaspEmployerConfig | ClaspEmployeeConfig) {
    this.config = config;
    this.connectionData = undefined;

    const baseURL = getClaspApiUrl(config.apiClient);
    const clientConfig = {
      baseURL,
      withCredentials: !config.auth,
      headers: { "Clasp-Application-Id": "components" },
    };
    if (document.cookie) {
      clientConfig["headers"]["X-CSRFToken"] = document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrftoken="))
        ?.split("=")?.[1];
    }
    this.client = axios.create(clientConfig);

    // Convert camelCase object keys in request body to snake_case for
    // the api
    this.client.interceptors.request.use(async (request) => {
      if (request.data) {
        request.data = snakeCaseKeys(request.data);
      }
      if (request.params) {
        request.params = snakeCaseKeys(request.params);
      }
      if (config.auth) {
        const tokenHeader = await this.authenticate(config);
        request.headers.Authorization = tokenHeader;
      }

      return request;
    });
  }

  async authenticate(config: ClaspEmployerConfig | ClaspEmployeeConfig) {
    // Fetch new token when close to expiring
    if (!config.auth) {
      return;
    }

    if (
      this.accessToken === undefined ||
      this.expiresAt.getTime() < Date.now() + TEN_MINUTES_IN_MILLISECONDS
    ) {
      const authDetails = await config.auth();
      let expiresAt: string;
      if ("accessToken" in authDetails) {
        this.accessToken = authDetails.accessToken;
        expiresAt = authDetails.expiresAt;
      } else {
        this.accessToken = authDetails.access_token;
        expiresAt = authDetails.expires_at;
      }
      this.expiresAt = new Date(expiresAt);
    }

    return `Bearer ${this.accessToken}`;
  }

  async putConnection(employerId: string) {
    const connectionResponse = await this.client.put<Connection>(
      "/connections",
      {},
      {
        headers: {
          // 'x-employer-id': employer.id
          "x-employer-id": employerId,
        },
        params: {
          expand: ["employer"],
        },
      },
    );

    this.connectionData = connectionResponse.data;
    this.connectionId = connectionResponse.data.id;
    return connectionResponse.data;
  }

  async postApprovePayroll(): Promise<Connection> {
    const response = await this.client.post(
      `/connections/${this.connectionId}/connect_payroll`,
      {},
      { params: { expand: ["employer"] } },
    );
    this.connectionData = response.data;
    return response.data;
  }

  async postEmployer(body: WritableEmployer): Promise<Employer> {
    const response = await this.client.post("/employers", body, {
      headers: { "x-partner-id": "ext_id" },
    });
    return response.data;
  }
  async getEmployer(employerId: string): Promise<Employer> {
    const response = await this.client.get(`/employers/${employerId}`);
    return response.data;
  }

  async getConnection(): Promise<Connection> {
    const response = await this.client.get(
      `/connections/${this.connectionId}`,
      { params: { expand: ["employer"] } },
    );
    this.connectionData = response.data;
    return response.data;
  }

  async getPartnerConfig(): Promise<PartnerConfig> {
    const response = await this.client.get(`/partner_config`);
    return response.data;
  }

  async putMagicLink(): Promise<Connection> {
    const response = await this.client.post(
      `/connections/${this.connectionId}/broker_url`,
      {},
      { params: { expand: ["employer"] } },
    );
    this.connectionData = response.data;
    return response.data;
  }

  async postCarriersForm(body: any) {
    const response = await this.client.post(`/group_connections`, body);
    return response.data;
  }

  async getCarriers(params: { cursor?: string }) {
    const response = await this.client.get("/carriers", {
      params,
    });
    return response.data;
  }

  async getCarrierMembers(params: { cursor?: string; expand?: string[] }) {
    const response = await this.client.get(`/carrier_members`, {
      params: { connection: this.connectionId, ...params },
    });
    return response.data;
  }

  async getMembers(params: {
    cursor?: string;
    lineOfCoverage?: LineOfCoverage;
    expand?: string[];
    employer?: string;
    search?: string;
  }) {
    const response = await this.client.get(`/members`, {
      params: { connection: this.connectionId, ...params },
    });
    return response.data;
  }

  async getMember(
    memberId: string,
    params?: { expand?: string[] },
  ): Promise<Member> {
    const response = await this.client.get(`/members/${memberId}`, {
      params,
    });
    return response.data;
  }

  async getDependentSSN(dependentId: string): Promise<{ ssn: string }> {
    const response = await this.client.get(
      `/dependents/${dependentId}/reveal_ssn`,
    );
    return response.data as { ssn: string };
  }

  async getMemberSSN(memberId: string): Promise<{ ssn: string }> {
    const response = await this.client.get(`/members/${memberId}/reveal_ssn`);
    return response.data as { ssn: string };
  }

  async getMemberInfo(memberId: string, params?: { expand?: string[] }) {
    const response = await this.client.get(`/members/${memberId}`, {
      params,
    });
    return response.data;
  }

  async getDependents(params: {
    cursor?: string;
    expand?: string[];
    member?: string;
  }) {
    const response = await this.client.get(`/dependents`, {
      params: { connection: this.connectionId, ...params },
    });
    return response.data;
  }

  async postContributionStrategyForm(planId: string, body: any) {
    const response = await this.client.post(
      `/plans/${planId}/contribution_strategy`,
      body,
    );
    return response.data;
  }

  async getContributionStrategy(planId: string) {
    const response = await this.client.get(
      `/plans/${planId}/contribution_strategy`,
      { params: { expand: ["base_plan"] } },
    );
    return response.data;
  }

  async getPlans(params: {
    cursor?: string;
    lineOfCoverage?: LineOfCoverage;
    employer?: string;
    expand?: string[];
  }) {
    const response = await this.client.get(`/plans`, {
      params: { ...params, connection: this.connectionId },
    });
    return response.data;
  }

  async getPlan(planId: string, params: { expand?: string[] }) {
    const response = await this.client.get(`/plans/${planId}`, {
      params: { ...params },
    });
    return response.data;
  }

  async reconcileMembers(body: any) {
    const response = await this.client.put(`/carrier_members/reconcile`, body);
    return response.data;
  }

  async sandboxSimulateCarrierConnection() {
    /**
     * This should only be used in sandbox to simulate what would happen
     * when we receive responses from carriers
     */
    const response = await this.client.post(
      `/connections/${this.connectionId}/sandbox_simulate_carrier_connections`,
    );
    return response.data;
  }
  async submitConnectionReview(): Promise<any> {
    const response = await this.client.post(
      `/connections/${this.connectionId}/submit_review`,
      {},
      { params: { expand: ["employer"] } },
    );
    this.connectionData = response.data;
    return response.data;
  }
  async postQualifyingLifeEvent(
    memberId: string,
    data: any,
  ): Promise<Enrollment> {
    const response = await this.client.post(
      `/members/${memberId}/qualifying_life_event`,
      data,
      { params: { expand: ["member"] } },
    );
    return response.data;
  }

  async postQualifyingLifeEventDocument(
    body: FormData,
  ): Promise<Paginated<QualifyingLifeEventDocument>> {
    const response = await this.client.postForm(
      `/qualifying_life_event_documents`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  }

  async postBeginOpenEnrollment(
    memberId: string,
    data: any,
  ): Promise<Enrollment> {
    const response = await this.client.post(
      `/members/${memberId}/begin_open_enrollment`,
    );
    return response.data;
  }

  async postNewHireEnrollment(
    memberId: string,
    data: any,
  ): Promise<Enrollment> {
    const response = await this.client.post(
      `/members/${memberId}/new_hire_enrollment`,
    );
    return response.data;
  }

  async getEnrollmentEligiblePlans(
    enrollmentId: string,
    body: any,
    params: { expand: string[] },
  ): Promise<PlanQuote> {
    const response = await this.client.post(
      `/enrollments/${enrollmentId}/eligible_plans`,
      body,
      { params },
    );
    return response.data;
  }
  async updateDependent(dependentId: string, body: any): Promise<Dependent> {
    /**
     * PATCH /dependents/{dependentId}
     */
    const response = await this.client.patch(
      `/dependents/${dependentId}`,
      body,
    );
    return response.data;
  }
  async createDependent(body: any): Promise<Dependent> {
    /**
     * POST /dependents
     */
    const response = await this.client.post(`/dependents`, body);
    return response.data;
  }
  async createEmployee(body: WritableMember): Promise<Member> {
    /**
     * POST /member
     */
    const response = await this.client.post(`/members`, body);
    return response.data;
  }
  async updateMember(
    memberId: string,
    body: Partial<WritableMember>,
  ): Promise<Member> {
    /**
     * PATCH /member
     */
    const response = await this.client.patch(`/members/${memberId}`, body);
    return response.data;
  }
  async saveAndSubmitEnrollment(
    enrollmentId: string,
    body: any,
  ): Promise<Enrollment> {
    /**
     * POST /dependents
     */
    const response = await this.client.post(
      `/enrollments/${enrollmentId}/save_and_submit`,
      body,
    );
    return response.data;
  }

  async generate1095CPreviews(): Promise<any> {
    /**
     * GET /connections/{connectionId}/preview_1095_c
     */
    const response = await this.client.get(
      `/connections/${this.connectionId}/preview_1095_c`,
      {
        responseType: "blob",
      },
    );
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(response.data);
    link.download = "1095-C_previews.zip";
    document.body.appendChild(link);
    link.click();
    return response.data;
  }
  async updatePlan(planId: string, body: Partial<Plan>): Promise<Plan> {
    /**
     * PATCH /plans/{planId}
     */
    const response = await this.client.patch(`/plans/${planId}`, body);
    return response.data;
  }

  async getPayrollBenefits(params: {
    cursor?: string;
  }): Promise<Paginated<PayrollBenefit>> {
    /**
     * GET /payroll_benefits
     */
    const response = await this.client.get(`/payroll_benefits`, {
      params: { ...params, connection: this.connectionId },
    });
    return response.data;
  }

  async reconcilePayrollBenefits(body: PayrollBenefitReconciliation[]) {
    const response = await this.client.put(`/payroll_benefits/reconcile`, body);
    return response.data;
  }

  async postEmployerDocument(
    body: FormData,
  ): Promise<Paginated<EmployerDocument>> {
    const response = await this.client.postForm("/employer_documents", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  async listEmployerDocuments(params: {
    cursor?: string;
  }): Promise<Paginated<EmployerDocument>> {
    const response = await this.client.get(`/employer_documents`, {
      params: { ...params },
    });
    return response.data;
  }

  async getEnrollment(
    enrollmentId: string,
    params?: { expand?: string[] },
  ): Promise<Enrollment> {
    /**
     * GET /enrollments/{enrollment_id}
     */
    const response = await this.client.get(`/enrollments/${enrollmentId}`, {
      params,
    });
    return response.data;
  }
  async reopenEnrollment(enrollmentId: string): Promise<Enrollment> {
    /**
     * POST /enrollments/{enrollment_id}/reopen
     */
    const response = await this.client.post(
      `/enrollments/${enrollmentId}/reopen`,
    );
    return response.data;
  }
  async listEnrollments(params: {
    cursor?: string;
    status: string[];
    expand?: string[];
    planId?: string;
  }): Promise<Paginated<Enrollment>> {
    /**
     * GET /enrollments
     */
    const response = await this.client.get("/enrollments", { params });
    return response.data;
  }
  async approveEnrollment(enrollmentId: string): Promise<Enrollment> {
    /**
     * POST /enrollments/{enrollmentId}/approve
     */
    const response = await this.client.post(
      `/enrollments/${enrollmentId}/approve`,
    );
    return response.data;
  }
  async deleteEnrollment(enrollmentId: string): Promise<Enrollment> {
    /**
     * DELETE /enrollments/{enrollmentId}
     */
    const response = await this.client.delete(`/enrollments/${enrollmentId}`);
    return response.data;
  }

  async fetchReport(
    employerId: string,
    reportType: EmployerReport,
  ): Promise<AxiosResponse> {
    /**
     * GET /employers/{employerId}/reports/{reportType}
     */
    const response = await this.client.get(
      `/employers/${employerId}/reports/${reportType}`,
      { responseType: "blob" },
    );
    return response;
  }
}
