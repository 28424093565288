import { useExternalNavigate } from "@/stores";
import successGif from "@/assets/gifs/success.gif";
import {
  Button,
  Center,
  Container,
  Group,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core";

export const ClaspFinalPage = () => {
  const navigate = useExternalNavigate();
  // const config = useAtomValue(configAtom)
  const onReturnHome = () => {
    navigate("home");
  };
  return (
    <Container size={704}>
      <Stack gap="lg">
        <Title order={2}>We'll take it from here 🎉</Title>
        <Text size="sm">
          We now have all the information we need and will work with your
          carrier to transfer your group details to Neptune. Once everything's
          in place, you'll get an email heads-up to review the data.{" "}
        </Text>
        <Center>
          <Image src={successGif} alt="success..." h={300} w="auto" />
        </Center>
        {/* </div> */}
        <Text size="sm">
          This process typically takes 2-4 days; please check back to see when
          your status transitions from 'processing' to 'complete'.
        </Text>
        <Group justify="flex-end">
          <Button onClick={onReturnHome}>Return Home</Button>
        </Group>
      </Stack>
    </Container>
  );
};
