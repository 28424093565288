import { useMantineTheme, Stack, Group, Tooltip, Title } from "@mantine/core";
import { IconHelpCircle } from "@tabler/icons-react";

export const PlanDetailItem = ({
  header,
  tooltip,
  children,
}: {
  header: React.ReactNode;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const theme = useMantineTheme();
  return (
    <Stack>
      <Group
        gap="xs"
        align="center"
        justify="center"
        preventGrowOverflow={false}
        wrap="nowrap"
      >
        <Title order={6}>{header}</Title>
        {tooltip && (
          <Tooltip position="top" label={tooltip} withArrow>
            <IconHelpCircle
              stroke={1.5}
              size={24}
              color={theme.colors[theme.primaryColor][5]}
            />
          </Tooltip>
        )}
      </Group>
      <Stack align="center" justify="center">
        {children}
      </Stack>
    </Stack>
  );
};
