import { useApiClient } from "@/helpers/hooks";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

export const useRevealSSN = (): UseMutationResult<{ ssn: string }> => {
  const client = useApiClient();

  return useMutation({
    mutationFn: ({
      entityId,
      entityType,
    }: {
      entityId: string;
      entityType: "dependent" | "member";
    }) =>
      entityType === "dependent"
        ? client.getDependentSSN(entityId)
        : client.getMemberSSN(entityId),
  });
};
