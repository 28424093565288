import {
  AppShell,
  DEFAULT_THEME,
  Tooltip,
  createTheme,
  mergeMantineTheme,
} from "@mantine/core";

const override = createTheme({
  components: {
    AppShell: AppShell.extend({
      styles: () => ({
        navbar: {
          background: "linear-gradient(90deg, #FFFFFF 72.68%, #E4E6E6 215.36%)",
        },
      }),
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        multiline: true,
        maw: "24rem",
      },
    }),
  },
});
export const theme = mergeMantineTheme(DEFAULT_THEME, override);
