import { authAtom } from "@/App";
import { APIClient } from "@/stores/APIClient";
import { Paginated, PartnerConfig } from "@/types/api";
import {
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ClaspEmployerConfig } from "..";
import { ClaspEventDataWithError } from "@/wrapper/eventData";

export const fetchAllPages = <T>(
  infiniteQueryAtom: InfiniteData<Paginated<T>>,
  statusQueryAtom: InfiniteQueryObserverResult<Paginated<T>, unknown>,
) => {
  if (
    !statusQueryAtom.isLoading &&
    statusQueryAtom.hasNextPage &&
    !statusQueryAtom.error &&
    !statusQueryAtom.isFetchingNextPage
  ) {
    statusQueryAtom.fetchNextPage();
  }
  return infiniteQueryAtom.pages.flatMap((page) => page.results);
};

export const useApiClient = () => {
  const employerId = localStorage.getItem("employer_id");
  const environment = localStorage.getItem(
    "environment",
  ) as ClaspEmployerConfig["apiClient"];
  const partnerConfig = useOutletContext() as PartnerConfig;
  const auth = useAtomValue(authAtom);
  const navigate = useNavigate();

  const [apiClient] = useState(() => {
    const config: ClaspEmployerConfig = {
      auth: auth["access_token"] ? () => auth : false,
      apiClient: environment,
      navigation: "url",
      employerId,
      links: partnerConfig.links,
      onNavigate: (to) => {
        const newState = to.split("claspState=")[1];
        const currentState = new URLSearchParams(window.location.search).get(
          "claspState",
        );
        /**
         * Hacky workaround to routing. Routing updates are triggered
         * several times per page navigation causing the browser history
         * to get messed up. This aims to deduplicate majority of the
         * navigation alerts.
         * TODO: Revist navigation holistically to ensure we are only
         * firing events as needed.
         */
        if (newState != currentState) {
          navigate(to);
        }
      },
    };

    return new APIClient(config);
  });

  return apiClient;
};

export const usePostMessageToWrapper = () => {
  return useCallback((eventData: ClaspEventDataWithError) => {
    window.parent?.postMessage?.(eventData, "*");
  }, []);
};
