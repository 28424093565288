import { z } from "zod";

export const SsnSchema = z
  .string()
  .transform((value) => value.replace(/-/g, ""))
  .pipe(
    z.union([
      z
        .string()
        .regex(/^\d+$/, "SSN must contain only digits and dashes")
        .length(9),
      z
        .string()
        .regex(/^\*{5}\d+$/, "String contains invalid characters")
        .length(9),
    ]),
  );
