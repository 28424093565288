import { UsCurrencyFormatter } from "@/helpers/string";
import { Plan, PlanQuote } from "@/types/api";
import { Stack, Text, Title } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { getEligiblePlansAtom, getEnrollmentAtom } from "../../API";
import { useVoluntarySupplementalBenefitCardFormContext } from "./form";

export const PaymentDetails = ({ plan }: { plan: Plan }) => {
  const form = useVoluntarySupplementalBenefitCardFormContext();
  const [perPayPeriodCost, setPerPayPeriodCost] = useState(0);
  const elections = form.values[plan.line_of_coverage];
  const enrolleeCount = elections.filter(
    ({ volume }) => Number(volume) > 0,
  ).length;
  const dependents = elections
    .filter((election) => election.memberRelationship)
    .map(({ entityId }) => entityId);

  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const enrollment = useAtomValue(getEnrollmentAtom[0]);

  useEffect(() => {
    fetchEligiblePlans([
      {
        body: {
          dependents,
          volume_elections: elections.map((election) => ({
            plan: plan.id,
            amount: election.volume,
            dependent: election.entityId.startsWith("dep_")
              ? election.entityId
              : undefined,
          })),
        },
        enrollmentId: enrollment.id,
      },
    ]).then((quotes: PlanQuote[]) =>
      setPerPayPeriodCost(
        Number(
          quotes.find((quote) => quote.plan.id === plan.id)
            .per_pay_period_member_contribution,
        ),
      ),
    );
  }, [elections, plan, enrollment]);

  return (
    <Stack align="flex-end" gap={0}>
      <Title c="gray.7" fw={600} order={1}>
        {enrolleeCount === 0
          ? "-"
          : UsCurrencyFormatter.format(perPayPeriodCost)}
      </Title>
      <Text c="gray.7" fw={600} size="sm">
        Per pay period ({enrolleeCount} enrollee{enrolleeCount > 1 && "s"})
      </Text>
    </Stack>
  );
};
