import backIcon from "@/assets/icons/back.svg";
import EnrolledPlanCard from "@/components/EnrolledPlanCard";
import { selectedEnrollmentAtom, useNavigate } from "@/stores";
import { EnrollmentPlan } from "@/types/api";
import {
  ActionIcon,
  Button,
  Group,
  Image,
  Popover,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { reopenEnrollmentAtom } from "./API";

export const EnrollmentDetails = () => {
  const [confirmationOpened, setConfirmationOpened] = useState(false);
  const navigate = useNavigate();
  const enrollment = useAtomValue(selectedEnrollmentAtom);
  enrollment.enrolled_plans = enrollment.enrolled_plans as EnrollmentPlan[];
  const [reopenEnrollmentStatus, reopenEnrollment] =
    useAtom(reopenEnrollmentAtom);
  const onMakeChanges = async () => {
    await reopenEnrollment([enrollment.id]);
    navigate("enroll");
  };
  const today = new Date();

  return (
    <Stack gap="lg">
      <Group justify="space-between">
        <Group gap="xs">
          <ActionIcon
            variant="subtle"
            size="lg"
            onClick={() => {
              navigate("employee_home");
            }}
          >
            <Image src={backIcon} />
          </ActionIcon>
          <Title size="h2">Your Pending Benefits</Title>
        </Group>
        <Popover
          withArrow
          shadow="xs"
          opened={confirmationOpened}
          onClose={() => setConfirmationOpened(false)}
        >
          <Popover.Target>
            <Tooltip
              label="Changes have already started processing. Please reach out if you need assistance."
              disabled={enrollment.status !== "approved"}
              withArrow
            >
              <Button
                disabled={enrollment.status === "approved"}
                onClick={() => setConfirmationOpened(true)}
              >
                Make Changes
              </Button>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown maw={400}>
            <Stack>
              <Text size="sm">
                Making changes will unsubmit this enrollment. You will need to
                resubmit your enrollment before the deadline.
              </Text>
              <Group justify="end">
                <Button
                  variant="transparent"
                  onClick={() => setConfirmationOpened(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onMakeChanges}
                  loading={reopenEnrollmentStatus.isPending}
                >
                  Continue
                </Button>
              </Group>
            </Stack>
          </Popover.Dropdown>
        </Popover>
      </Group>

      <Text>
        These changes are still being processed and are not currently active. We
        will let you know once these benefits are in effect.
      </Text>

      <Title size="h3">Benefits</Title>
      <SimpleGrid cols={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
        {enrollment.enrolled_plans.map(
          (enrollmentPlan) =>
            enrollmentPlan.effective_start >= enrollment.change_date && (
              <EnrolledPlanCard
                enrollmentPlan={enrollmentPlan}
                key={enrollmentPlan.id}
              />
            ),
        )}
      </SimpleGrid>
    </Stack>
  );
};
