import { CarrierImage } from "@/components/CarrierImage";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { configAtom } from "@/stores";
import { Plan } from "@/types/api";
import { Button, Center, Modal, Stack, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo } from "react";
import { ContributionStrategyTable } from "./ContributionStrategyTable";
import {
  GlobalStrategyFormValues,
  PerPlanFormValues,
  initialPlanContributionStrategy,
} from "./FormContext";

type FormModalProps = {
  formPath: string;
  form:
    | UseFormReturnType<GlobalStrategyFormValues>
    | UseFormReturnType<PerPlanFormValues>;
  planName: string;
};
const FormModal = ({ formPath, form, planName }: FormModalProps) => {
  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const config = useAtomValue(configAtom);
  return (
    <>
      <Button onClick={() => openModal()}>Set contribution</Button>
      <Modal.Root opened={opened} onClose={closeModal} size="auto">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Title size="h2">Contribution Details</Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Stack>
              <Text>
                Configure your benefits contribution strategy for{" "}
                <b>{planName}</b>
              </Text>

              <ContributionStrategyTable formPath={formPath} form={form} />
              <Button
                onClick={() => closeModal()}
                style={{ alignSelf: "flex-end" }}
              >
                Save contribution strategy
              </Button>
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

type PerPlanContributionStrategyFormProps = {
  form:
    | UseFormReturnType<GlobalStrategyFormValues>
    | UseFormReturnType<PerPlanFormValues>;
  plans: Plan[];
};
export const PerPlanContributionStrategyForm = ({
  form,
  plans,
}: PerPlanContributionStrategyFormProps) => {
  const medicalPlans = useMemo(
    () => plans.filter((plan) => plan.line_of_coverage === "medical"),
    [plans],
  );
  const dentalPlans = useMemo(
    () => plans.filter((plan) => plan.line_of_coverage === "dental"),
    [plans],
  );
  const visionPlans = useMemo(
    () => plans.filter((plan) => plan.line_of_coverage === "vision"),
    [plans],
  );
  const planIds = useMemo(() => plans.map((plan) => plan.id), [plans]);

  useEffect(() => {
    const initialValues = plans.map((plan) => {
      return {
        planId: plan.id,
        contributionStrategy: initialPlanContributionStrategy,
      };
    });
    form.setValues({ planContributionStrategies: initialValues });
    form.resetDirty({ planContributionStrategies: initialValues });
  }, []);
  const columns = [
    {
      header: "",
      accessorKey: "group.carrier.id",
      Cell: ({ renderedCellValue }) => (
        <Center>
          <CarrierImage carrierId={renderedCellValue} />
        </Center>
      ),
    },
    {
      header: "plan type",
      accessorKey: "group.carrier.trade_name",
    },
    {
      header: "plan name",
      accessorKey: "plan_name",
    },
    {
      header: "enrollees",
      accessorKey: "enrollees",
      Cell: ({ row }) => (
        <>
          {row.original.member_count} employees
          <br />
          {row.original.dependent_count} dependents
        </>
      ),
    },
    {
      header: "",
      accessorKey: "formModal",
      Cell: ({ row }) => {
        return (
          <FormModal
            key={row.original.id}
            formPath={`planContributionStrategies.${planIds.indexOf(
              row.original.id,
            )}.contributionStrategy`}
            form={form}
            planName={row.original.plan_name}
          />
        );
      },
    },
  ];

  const medicalPlansTable = useMantineReactTable({
    columns,
    data: medicalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const visionPlansTable = useMantineReactTable({
    columns,
    data: visionPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const dentalPlansTable = useMantineReactTable({
    columns,
    data: dentalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  return (
    <>
      {!!medicalPlans.length && (
        <>
          <Title size="h4">Medical</Title>
          <MantineReactTable table={medicalPlansTable} />
        </>
      )}
      {!!dentalPlans.length && (
        <>
          <Title size="h4">Dental</Title>
          <MantineReactTable table={dentalPlansTable} />
        </>
      )}
      {!!visionPlans.length && (
        <>
          <Title size="h4">Vision</Title>
          <MantineReactTable table={visionPlansTable} />
        </>
      )}
    </>
  );
};
