import { authAtom, themeAtom } from "@/App";
import { getClaspApiUrl, isEmbedded } from "@/helpers";
import { PartnerConfig } from "@/types/api";
import { datadogRum } from "@datadog/browser-rum";
import {
  AppShell,
  AppShellMain,
  Box,
  Burger,
  Group,
  mergeMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { queryClient } from "../ClaspWrapper";
import ErrorBoundary from "../ErrorBoundary";
import { Sidebar } from "./Sidebar";
import { useIsWebView } from "@/helpers/useIsWebView";

export const AppWrapper = () => {
  const environment = localStorage.getItem("environment");
  environment === "production" &&
    datadogRum.init({
      applicationId: "ad11076b-f3a5-4b40-8b01-8f85c63bfc98",
      clientToken: "pub7206dd613ccd45f70ab5a1c3c2bea2a7",
      site: "datadoghq.com",
      service: "clasp-components",
      env: environment,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      usePartitionedCrossSiteSessionCookie: true,
      allowFallbackToLocalStorage: true,
    });

  const auth = useAtomValue(authAtom);
  const baseUrl = getClaspApiUrl(environment as any);
  const [partnerConfig, setPartnerConfig] = useState<PartnerConfig>();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [theme, setTheme] = useAtom(themeAtom);
  const [opened, { toggle }] = useDisclosure();
  const isEmployerView = useMatch("/employer/*");
  const isWebView = useIsWebView();

  useEffect(() => {
    axios
      .get(`${baseUrl}/partner_config`, {
        withCredentials: true,
        headers: auth["access_token"] && {
          Authorization: `Bearer ${auth["access_token"]}`,
        },
      })
      .then((resp) => {
        setPartnerConfig(resp.data);
        setTheme(mergeMantineTheme(theme, resp.data.theme));
      })
      .catch(() => {
        setError(true);
        if (!isEmbedded()) {
          navigate("/app/login");
        }
      });
  }, []);

  if (error) return <ErrorBoundary />;
  if (!partnerConfig) return;

  // Load font so that we can guarantee the browser has access
  if (partnerConfig.theme?.fontFamily) {
    const fontName = partnerConfig.theme?.fontFamily.split(",")[0];
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `https://fonts.googleapis.com/css?family=${fontName}`;
    document.head.appendChild(link);
  }

  if (isEmbedded() || isWebView) {
    return (
      <div style={{ height: "100dvh" }}>
        <QueryClientProvider client={queryClient}>
          <Outlet context={partnerConfig} />
        </QueryClientProvider>
      </div>
    );
  }

  return (
    <AppShell
      header={{
        height: { base: "60px", sm: "0px" },
      }}
      navbar={{
        width: 280,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      layout="alt"
    >
      <AppShell.Header>
        <Group align="center" h="100%" justify="space-between" px="xl">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar withBorder={false}>
        <Sidebar
          environment={environment}
          partnerConfig={partnerConfig}
          setError={setError}
          opened={opened}
          toggle={toggle}
          key={isEmployerView?.pathname}
        />
      </AppShell.Navbar>

      <AppShellMain mx="lg" pt={{ sm: "xl" }} h="100dvh">
        <Notifications autoClose={4000} transitionDuration={750} />
        <QueryClientProvider client={queryClient}>
          <Box pb="xl">
            <Outlet context={partnerConfig} />
          </Box>
        </QueryClientProvider>
      </AppShellMain>
    </AppShell>
  );
};
