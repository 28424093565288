import { CarrierImage } from "@/components/CarrierImage";
import { Carrier } from "@/types/api";
import styles from "./styles.module.scss";

interface Props {
  carrier: Carrier;
  selectedCarrier?: Carrier;
  onSelect: (name: Carrier) => void;
}

export const CarrierButton = ({
  onSelect,
  carrier,
  selectedCarrier,
}: Props) => {
  const selected = carrier.id === selectedCarrier?.id;
  return (
    <button
      className={`${selected ? styles.selected : styles.button}`}
      onClick={() => onSelect(carrier)}
    >
      <CarrierImage carrierId={carrier.id} h={95} w={95} />
    </button>
  );
};
