import { useApiClient } from "@/helpers/hooks";
import { Member } from "@/types/api";
import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";

export const useGetMember = (
  memberId: string,
  options?: Omit<UseQueryOptions<Member>, "queryKey">,
): UseQueryResult<Member> => {
  const client = useApiClient();

  return useQuery({
    ...options,
    queryKey: ["members", memberId],
    queryFn: () =>
      client.getMember(memberId, {
        expand: [
          "active_enrollment.enrolled_plans.plan.group.carrier",
          "active_enrollment.enrolled_plans.enrolled_dependents.dependent",
          "enrollments.enrolled_plans.plan.group.carrier",
          "enrollments.enrolled_plans.enrolled_dependents.dependent",
          "employer",
        ],
      }),
  });
};
