import { Alert, Anchor, Center, Stack, Text } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const Signup = () => {
  const [, setSearchParams] = useSearchParams();
  useEffect(
    () => setSearchParams(new URLSearchParams(), { replace: true }),
    [],
  );
  return (
    <Center h="100dvh">
      <Alert
        color="gray"
        title="Sorry, we've encountered an error"
        icon={<IconExclamationCircle />}
        maw={650}
      >
        <Stack>
          <Text>
            We were not able to log you in. Please double check the email you
            used or reach out to your employer to ensure you have the correct
            email address or to request an account.
          </Text>
          <Anchor href="/app/login">Click here to try again</Anchor>
        </Stack>
      </Alert>
    </Center>
  );
};
