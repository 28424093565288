import { EnrollmentStatus } from "@/types/api";
import { Badge } from "@mantine/core";

const statusMap: Record<EnrollmentStatus, string> = {
  not_started: "Not Started",
  pending: "Pending",
  approved: "Pending (Approved)",
  synced: "Synced",
  failed: "Failed",
};

export const EnrollmentStatusBadge = ({
  status,
}: {
  status: EnrollmentStatus;
}) => {
  const title = statusMap[status];
  switch (status) {
    case EnrollmentStatus.Synced:
      return (
        <Badge c="green.5" color="green.1" size="xs" radius="xl">
          {title}
        </Badge>
      );
    case EnrollmentStatus.Failed:
      return (
        <Badge c="red.5" color="red.1" size="xs" radius="xl">
          {title}
        </Badge>
      );
    default:
      return (
        <Badge c="yellow.9" color="yellow.1" size="xs" radius="xl">
          {title}
        </Badge>
      );
  }
};
