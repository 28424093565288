import { authAtom } from "@/App";
import { ClaspEmployer } from "@/ClaspEmployer";
import { entityIdAtom } from "@/stores";
import { PartnerConfig } from "@/types/api";
import { useAtomValue } from "jotai";
import { useNavigate, useOutletContext } from "react-router-dom";

export const HostedClaspEmployer = () => {
  const employerId = useAtomValue(entityIdAtom);
  const environment = localStorage.getItem("environment");
  const claspState = localStorage.getItem("claspState");
  const navigate = useNavigate();
  const auth = useAtomValue(authAtom);
  const partnerConfig = useOutletContext() as PartnerConfig;
  const theme =
    JSON.parse(localStorage.getItem("theme")) || partnerConfig.theme;
  return (
    <ClaspEmployer
      config={{
        auth: auth["access_token"] ? () => auth : false,
        // @ts-ignore
        apiClient: environment,
        onNavigate: (to) => {
          const newState = to.split("claspState=")[1];
          const currentState = new URLSearchParams(window.location.search).get(
            "claspState",
          );
          /**
           * Hacky workaround to routing. Routing updates are triggered
           * several times per page navigation causing the browser history
           * to get messed up. This aims to deduplicate majority of the
           * navigation alerts.
           * TODO: Revist navigation holistically to ensure we are only
           * firing events as needed.
           */
          if (newState != currentState) {
            navigate(to);
          }
        },
        navigation: "url",
        employerId: employerId,
        links: partnerConfig.links,
      }}
      theme={theme}
      claspState={claspState}
    />
  );
};
