import { Button, Divider, Flex, Grid, Stack, Title } from "@mantine/core";
import React, { PropsWithChildren } from "react";
import { SectionField } from "./SectionField";
import { IconEdit } from "@tabler/icons-react";

type SectionProps =
  | { title: React.ReactNode }
  | {
      title: React.ReactNode;
      withEditButton: boolean;
      onClickEdit: () => void;
    };

export const Section = (props: PropsWithChildren<SectionProps>) => {
  const { title, children } = props;
  return (
    <Stack p={20} gap={15}>
      <Flex justify="space-between">
        <Title c="gray.8" order={5}>
          {title}
        </Title>
        {"withEditButton" in props && (
          <Button
            variant="transparent"
            px={0}
            leftSection={<IconEdit size={16} />}
            onClick={props.onClickEdit}
          >
            Edit
          </Button>
        )}
      </Flex>
      <Divider />
      <Grid columns={60} gutter={24}>
        {children}
      </Grid>
    </Stack>
  );
};

Section.Field = SectionField;
