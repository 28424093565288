import { UsDateFormatter } from "@/helpers/string";
import { useClaspTable } from "@/helpers/useClaspTable";
import { QualifyingLifeEventDocument } from "@/types/api";
import { Anchor } from "@mantine/core";
import { IconInbox } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";

export const EnrollmentDocumentsTable = ({
  enrollmentId,
  documents,
}: {
  enrollmentId: string;
  documents: QualifyingLifeEventDocument[];
}) => {
  const [page, setPage] = useState<string | undefined>();
  const query = useQuery({
    queryKey: ["enrollment", enrollmentId, "qualifying_life_event_documents"],
    queryFn: () =>
      Promise.resolve({
        next: "",
        previous: "",
        results: documents.slice((page ? Number(page) : 0) * 25, 25),
      }),
  });

  const columns = useMemo<MRT_ColumnDef<QualifyingLifeEventDocument>[]>(
    () => [
      {
        header: "Document",
        accessorKey: "name",
      },
      {
        header: "Uploaded On",
        accessorKey: "created_at",
        size: 20,
        Cell: ({ cell }) =>
          UsDateFormatter.format(new Date(cell.getValue<string>())),
      },
      {
        header: "",
        id: "file",
        enableSorting: false,
        size: 20,
        accessorFn: (originalRow) => (
          <Anchor href={originalRow.file} target="_blank" download>
            <IconInbox />
          </Anchor>
        ),
      },
    ],
    [],
  );

  const table = useClaspTable({
    columns,
    query,
    page,
    setPage,
  });

  return <MantineReactTable table={table} />;
};
