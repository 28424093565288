import { UsCurrencyFormatter } from "@/helpers/string";
import { Box, Group, Stack, Text, Title, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

export const CoverageCard = ({
  title,
  member,
  child,
  spouse,
  tooltip,
}: {
  title: string;
  member?: string;
  child?: string;
  spouse?: string;
  tooltip?: string;
}) => {
  return (
    <Stack gap={8}>
      <Group gap={6}>
        <Title fw={600} c="gray.7" order={6}>
          {title}
        </Title>
        <Tooltip label={tooltip} withArrow>
          <IconInfoCircle size={14} />
        </Tooltip>
      </Group>

      <Box>
        <Text lh="17px" c="gray.7" fw={400} size="sm">
          You: {member ? UsCurrencyFormatter.format(Number(member)) : "$ -"}
        </Text>
        <Text lh="17px" c="gray.7" fw={400} size="sm">
          Spouse: {spouse ? UsCurrencyFormatter.format(Number(spouse)) : "$ -"}
        </Text>
        <Text lh="17px" c="gray.7" fw={400} size="sm">
          Child: {child ? UsCurrencyFormatter.format(Number(child)) : "$ -"}
        </Text>
      </Box>
    </Stack>
  );
};
