import { clientAtom, useNavigate } from "@/stores";
import { Employer } from "@/types/api";
import {
  Badge,
  Button,
  Container,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useAtomValue } from "jotai";

const statusMap = {
  processing: {
    status: "Processing",
    color: "blue",
    text: `We're actively working with your carrier to update your details. Once everything is lined up, we'll notify you to review and address any discrepancies.`,
    navigateText: "Edit Data",
  },
  error: {
    status: "Error",
    color: "red",
    text: `We've run into an error when connecting your carrier. Rest assured, we will handle it and notify you via email when resolved.`,
    navigateText: "Edit Data",
  },
  needs_attention: {
    status: "Needs Attention",
    color: "yellow",
    text: `We've retrieved your latest information from your carrier. Please take a moment to review your details and correct any discrepancies.`,
    navigateText: "Review information",
  },
};

type Props = {
  status: "processing" | "error" | "needs_attention";
  forwardPage?: string;
};

export const Status = ({ status, forwardPage }: Props) => {
  const client = useAtomValue(clientAtom);
  const employer = client.connectionData.employer as Employer;
  const employerName = employer.trade_name || employer.legal_name;
  const navigate = useNavigate();
  const { status: statusText, color, text, navigateText } = statusMap[status];

  return (
    <Container size={704}>
      <Stack gap="lg">
        <Group justify="space-between">
          <Title order={2}>{employerName} Benefits</Title>
          <Badge variant="light" size="lg" color={color}>
            {statusText}
          </Badge>
        </Group>
        <Text>{text}</Text>
        {forwardPage && (
          <Group justify="flex-end">
            <Button onClick={() => navigate(forwardPage)}>
              {navigateText}
            </Button>
          </Group>
        )}
      </Stack>
    </Container>
  );
};
