import { Group, Paper, Stack, Text, useMantineTheme } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAtomValue } from "jotai";
import { memberInfoAtom } from "../API";

export const BenefitIneligibleBanner = () => {
  const member = useAtomValue(memberInfoAtom[0]);
  const theme = useMantineTheme();

  return (
    <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "48rem" }}>
      <Stack gap={12}>
        <Group justify="space-between">
          <Group gap={8}>
            <IconAlertCircle stroke={1.5} size={25} color="red" />
            <Text fw={600}>You are not currently eligible for benefits</Text>
          </Group>
        </Group>
        <Text>
          You're not eligible to enroll in company benefits right now. Please
          contact your HR admin if you have any questions.
        </Text>
      </Stack>
    </Paper>
  );
};
