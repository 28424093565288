import { Dependent, Member, Plan } from "@/types/api";
import {
  Anchor,
  Checkbox,
  Divider,
  Group,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useMemo } from "react";
import { AdditionalInfoForm } from "../form";
import { snakeCaseToTitleCase } from "@/helpers/string";
import { useAtomValue } from "jotai";
import { memberDependentsAtom, memberInfoAtom } from "../API";
import { PlanQuoteWithDependents, useEnrollContext } from "../EnrollProvider";

interface Props {
  form: UseFormReturnType<AdditionalInfoForm>;
}

export const AdditionalInfo = ({ form }: Props) => {
  const waiverCards = [];
  const primaryCareCards = [];
  const dependents = useAtomValue(memberDependentsAtom);
  const memberInfo = useAtomValue(memberInfoAtom[0]);

  const {
    state: { medical, dental, vision },
  } = useEnrollContext();

  const dependentMap = new Map(
    dependents.map((dependent) => [dependent.id, dependent]),
  );

  for (const [planQuoteWithDependents, lineOfCoverage] of [
    [medical, "medical"],
    [dental, "dental"],
    [vision, "vision"],
  ]) {
    const { quote: planQuote, dependents: selectedDependents } =
      planQuoteWithDependents as PlanQuoteWithDependents;
    if (planQuote === null) {
      waiverCards.push(
        <WaiveReasonSelection
          lineOfCoverage={lineOfCoverage as string}
          form={form}
          index={waiverCards.length}
        />,
      );
    }
    if (dependents.length !== selectedDependents.length && planQuote) {
      const waivedDependents = [...dependentMap.values()].filter(
        (dependent) => !selectedDependents.includes(dependent.id),
      );
      waiverCards.push(
        <WaiveReasonSelection
          lineOfCoverage={lineOfCoverage as string}
          waivedDependents={waivedDependents}
          form={form}
          index={waiverCards.length}
        />,
      );
    }
    if (planQuote?.plan?.requires_primary_care_provider) {
      primaryCareCards.push(
        <PrimaryCareProviderSelection
          plan={planQuote.plan}
          person={memberInfo}
          form={form}
          index={primaryCareCards.length}
        />,
      );
      selectedDependents.forEach((dependentId) => {
        const dependent = dependentMap.get(dependentId);
        primaryCareCards.push(
          <PrimaryCareProviderSelection
            plan={planQuote.plan}
            person={dependent}
            form={form}
            index={primaryCareCards.length}
          />,
        );
      });
    }
  }

  useMemo(() => {
    form.reset();
    waiverCards.forEach((card) => {
      form.insertListItem("coverageWaivers", {
        lineOfCoverage: card.props.lineOfCoverage,
        member: card.props.waivedDependents ? undefined : memberInfo.id,
        dependents:
          card.props.waivedDependents &&
          card.props.waivedDependents.map((dep) => dep.id),
      });
    });
    primaryCareCards.forEach((card) => {
      const person = card.props.person;
      const personType = person["member"] ? "dependent" : "member";
      form.insertListItem("primaryCareSelections", {
        plan: card.props.plan.id,
        [personType]: card.props.person.id,
      });
    });
  }, [waiverCards.length, primaryCareCards.length]);

  return (
    <Stack gap={36}>
      {primaryCareCards.concat(waiverCards).map((card) => {
        return (
          <>
            {card}
            <Divider
              size={1}
              style={{ borderTopColor: "#f0f0f0", marginRight: "7.5%" }}
            />
          </>
        );
      })}
    </Stack>
  );
};

const PrimaryCareProviderSelection = ({
  plan,
  person,
  form,
  index,
}: {
  plan: Plan;
  person: Dependent | Member;
  form: UseFormReturnType<AdditionalInfoForm>;
  index: number;
}) => {
  return (
    <Stack>
      <Title order={5}>
        Primary Care Provider Selection - {plan.plan_name} ({person.first_name}{" "}
        {person.last_name})
      </Title>
      <Radio.Group
        {...form.getInputProps(
          `primaryCareSelections.${index}.assigned_by_carrier`,
        )}
      >
        <Stack gap="xs">
          <Radio
            value="manual"
            label="I want to manually select my primary care provider"
          />
          <Radio
            value="auto"
            label="I want the carrier to auto assign a primary care provider"
          />
        </Stack>
      </Radio.Group>
      {form.values.primaryCareSelections[index]?.assigned_by_carrier ===
        "manual" && (
        <Stack gap="xs">
          <Title order={6}>Provide Primary Care Provider Details</Title>
          <Group grow>
            <TextInput
              label="Provider Name"
              description="Physician's full name"
              maw={300}
              {...form.getInputProps(
                `primaryCareSelections.${index}.primary_care_provider_name`,
              )}
            />
            <TextInput
              label="Provider Identifier"
              {...form.getInputProps(
                `primaryCareSelections.${index}.primary_care_provider_id`,
              )}
              description={
                <Text size="xs">
                  The{" "}
                  <Anchor
                    href="https://npiregistry.cms.hhs.gov/search"
                    size="xs"
                    target="_blank"
                  >
                    NPI Registry
                  </Anchor>{" "}
                  can help get the identifier number.
                </Text>
              }
              maw={300}
            />
          </Group>
          <Checkbox
            {...form.getInputProps(
              `primaryCareSelections.${index}.is_existing_patient`,
              { type: "checkbox" },
            )}
            label="Is this your current physician?"
          />
        </Stack>
      )}
    </Stack>
  );
};

const WaiveReasonSelection = ({
  lineOfCoverage,
  waivedDependents,
  form,
  index,
}: {
  lineOfCoverage: string;
  waivedDependents?: Dependent[];
  form: UseFormReturnType<AdditionalInfoForm>;
  index: number;
}) => {
  let label = "Please select the reason why coverage is being waived";
  if (waivedDependents) {
    const dependentNames = waivedDependents
      .map((dependent) => `${dependent.first_name} ${dependent.last_name}`)
      .join(", ");
    label = `${label} for ${dependentNames}`;
  }
  return (
    <Stack>
      <Title order={5}>
        Waive Reason - {snakeCaseToTitleCase(lineOfCoverage)}
      </Title>
      <Select
        label={`${label}.`}
        data={[
          {
            label: "Covered as a dependent on another plan",
            value: "covered_as_dependent",
          },
          {
            label: "Coverage via another employer",
            value: "different_employer_coverage",
          },
          { label: "Enrolled in Medicare", value: "medicare_coverage" },
          { label: "Enrolled in Medicaid", value: "medicaid_coverage" },
          {
            label: "Covered via an individual policy",
            value: "individual_policy_coverage",
          },
          { label: "Covered via the military", value: "military_coverage" },
          {
            label: "Covered via a state-funded plan",
            value: "state_funded_plan_coverage",
          },
          { label: "No coverage", value: "no_coverage" },
        ]}
        maw={600}
        {...form.getInputProps(`coverageWaivers.${index}.reason`)}
      />
    </Stack>
  );
};
