import { LineOfCoverageToggle } from "@/components/LineOfCoverageToggle";
import { Stack } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useState } from "react";
import { ContributionStrategyTable } from "./ContributionStrategyTable";
import { GlobalStrategyFormValues, PerPlanFormValues } from "./FormContext";

type GlobalContributionStrategyFormProps = {
  form:
    | UseFormReturnType<GlobalStrategyFormValues>
    | UseFormReturnType<PerPlanFormValues>;
};
export const GlobalContributionStrategyForm = ({
  form,
}: GlobalContributionStrategyFormProps) => {
  const [selectedLineOfCoverage, setSelectedLineOfCoverage] =
    useState("medical");

  return (
    <Stack>
      <LineOfCoverageToggle
        value={selectedLineOfCoverage}
        onChange={setSelectedLineOfCoverage}
      />

      <ContributionStrategyTable
        formPath={selectedLineOfCoverage}
        key={`${selectedLineOfCoverage}ContributionStrategy`}
        form={form}
      />
    </Stack>
  );
};
