import { ENROLLMENT_REASONS } from "@/constants";
import { selectedEnrollmentAtom, useNavigate } from "@/stores";
import {
  Button,
  Flex,
  Group,
  Pill,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
import { useAtom, useSetAtom } from "jotai";
import { useState } from "react";
import {
  postQualifyingLifeEventAtom,
  postQualifyingLifeEventDocumentAtom,
} from "./API";

interface IOption {
  label: string;
  value: string;
  subOptions?: IOption[];
}
export const options: IOption[] = [
  {
    label: "Add Dependents to Health Plan",
    value: "child",
    subOptions: [
      {
        label: ENROLLMENT_REASONS["new_child"],
        value: "new_child",
      },
      {
        label: ENROLLMENT_REASONS["dependent_court_order"],
        value: "dependent_court_order",
      },
      {
        label: ENROLLMENT_REASONS["divorce"],
        value: "divorce",
      },
      {
        label: ENROLLMENT_REASONS["domestic_partnership"],
        value: "domestic_partnership",
      },
      {
        label: ENROLLMENT_REASONS["marriage"],
        value: "marriage",
      },
    ],
  },
  {
    label: "Change Health Plan",
    value: "change_health_plan",
    subOptions: [
      {
        label: ENROLLMENT_REASONS["relocated"],
        value: "relocated",
      },
    ],
  },
  {
    label: "Enroll in Health Insurance",
    value: "enroll",
    subOptions: [
      {
        label: ENROLLMENT_REASONS["dependent_lost_coverage"],
        value: "dependent_lost_coverage",
      },
      {
        label: ENROLLMENT_REASONS["lost_coverage"],
        value: "lost_coverage",
      },
    ],
  },
  {
    label: "Remove Dependents from Health Plan",
    value: "remove",
    subOptions: [
      {
        label: ENROLLMENT_REASONS["death"],
        value: "death",
      },
      {
        label: ENROLLMENT_REASONS["divorce"],
        value: "divorce",
      },
      {
        label: ENROLLMENT_REASONS["domestic_partnership"],
        value: "domestic_partnership",
      },
      {
        label: ENROLLMENT_REASONS["marriage"],
        value: "marriage",
      },
    ],
  },
];

export const EmployeeQLE = () => {
  const navigate = useNavigate();
  const [first, setFirst] = useState<IOption>();
  const [second, setSecond] = useState<IOption>();
  const [date, setDate] = useState<Date | null>(null);
  const [, postQualifyingLifeEvent] = useAtom(postQualifyingLifeEventAtom);
  const [, postQualifyingLifeEventDocument] = useAtom(
    postQualifyingLifeEventDocumentAtom,
  );
  const setSelectedEnrollment = useSetAtom(selectedEnrollmentAtom);
  const [uploadedFiles, setUploadedFiles] = useState(new Array<FileWithPath>());
  const onChangeFirst = (value) => {
    const option = options.filter((v) => v.value === value)[0];
    setFirst(option);
  };
  const onChangeSecond = (value) => {
    const option = first?.subOptions.filter((v) => v.value === value)[0];
    setSecond(option);
  };

  const onChangeDate = (value) => {
    setDate(value);
  };

  const onSubmit = async () => {
    const enrollment = await postQualifyingLifeEvent([
      {
        reason: second.value,
        effective_date: date.toISOString().substring(0, 10),
      },
    ]);

    for (const file of uploadedFiles) {
      const formData = new FormData();
      formData.set("file", file);
      formData.set("name", file.name);
      formData.set("enrollment", enrollment.id);
      formData.set("employer", enrollment.member.employer);
      await postQualifyingLifeEventDocument([formData]);
    }

    setSelectedEnrollment(enrollment);
    navigate("enroll");
  };
  const allFieldsPopulated = first && second && date;

  return (
    <Flex direction="column" gap={24}>
      <Title size="h2">Change Your Benefits</Title>

      <Text>
        Benefit changes are allowed only for qualifying life events. Please
        ensure your situation fits these criteria, otherwise please wait for
        your annual open enrollment
      </Text>

      <Title size="h3">Provide more information about your changes</Title>

      <Select
        onChange={onChangeFirst}
        label="What changes do you want to make to your benefits?"
        data={options}
      />

      <Select
        onChange={onChangeSecond}
        label="Please provide more information"
        data={first?.subOptions}
        disabled={first?.subOptions === undefined}
      />

      <DatePickerInput
        label="Select the effective date"
        placeholder="Effective date"
        value={date}
        onChange={onChangeDate}
      />

      <Title size="h3">Please upload proof of your qualifying life event</Title>

      <Title order={6}>
        Examples of valid documentation are marriage certificates, birth
        certificates, or your spouse's offer letter showing a change in
        employment. It’s important to upload correct documents, with clear names
        and dates, to ensure that your enrollment is processed correctly and in
        a timely manner.
      </Title>

      <Text>Only .pdf files smaller than 5 MB are accepted</Text>

      <Group gap="sm">
        {uploadedFiles.map((file) => (
          <Pill
            size="lg"
            withRemoveButton
            onRemove={() =>
              setUploadedFiles(uploadedFiles.filter((f) => f !== file))
            }
          >
            {file.name}
          </Pill>
        ))}
      </Group>

      <Dropzone
        onDrop={(files) => setUploadedFiles([...uploadedFiles, ...files])}
        maxFiles={5}
        maxSize={5 * 1024 * 1024} // 5mb max file size
        accept={[MIME_TYPES.pdf]}
        multiple
      >
        <Stack align="center" justify="center" mih={100}>
          <IconUpload style={{ width: 30, height: 30 }} stroke={1} />
          <Text size="lg" inline>
            Drag files here or click to select files
          </Text>
        </Stack>
      </Dropzone>

      <Flex justify="flex-end" gap={12}>
        <Button variant="outline" onClick={() => navigate("employee_home")}>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={!allFieldsPopulated}>
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};
