import { states } from "@/constants";
import { Grid, Select, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconMapPin } from "@tabler/icons-react";

export const AddressFieldGroup = <T,>({
  form,
  basePath,
}: {
  form: UseFormReturnType<T>;
  basePath: Extract<keyof T, string>;
}) => {
  return (
    <>
      <TextInput
        label="Address Line 1"
        placeholder="Address Line 1"
        leftSection={<IconMapPin size={16} />}
        {...form.getInputProps(`${basePath}.line1`)}
      />
      <TextInput
        label="Address Line 2"
        placeholder="Address Line 2"
        {...form.getInputProps(`${basePath}.line2`)}
      />
      <Grid>
        <Grid.Col span={4}>
          <TextInput
            label="City"
            placeholder="City"
            {...form.getInputProps(`${basePath}.city`)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            label="State"
            data={Array.from(states)}
            searchable
            placeholder="State"
            {...form.getInputProps(`${basePath}.state`)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            label="ZIP Code"
            placeholder="ZIP Code"
            {...form.getInputProps(`${basePath}.zip_code`)}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
