import { Status } from "@/ClaspEmployer/Pages/Status";
import { clientAtom, configAtom } from "@/stores";
import { Button, Container, Group, Stack } from "@mantine/core";
import { useAtomValue } from "jotai";

export const ProcessingStatus = () => {
  const config = useAtomValue(configAtom);
  const client = useAtomValue(clientAtom);
  const onMockCarrierConnections = async () => {
    await client.sandboxSimulateCarrierConnection();
    // Refresh page to reset all state
    window.location = window.location.pathname as any;
  };

  return (
    <Container size={704}>
      <Stack gap="lg">
        <Status status="processing" />
        {config.apiClient !== "production" && (
          <Group justify="flex-end">
            <Button onClick={onMockCarrierConnections}>
              Mock Carrier Connection
            </Button>
          </Group>
        )}
      </Stack>
    </Container>
  );
};
