import { Button, Flex, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DependentForm } from "../../DependentForm";
import { PersonInfo } from "../PersonInfo";
import { Dependent } from "../../../../../types/api";
import { MemberRelationshipOptions } from "@/constants";
import { snakeCaseToTitleCase } from "@/helpers/string";

interface Props {
  dependent: Dependent;
}

export const DependentCard = ({ dependent }: Props) => {
  const { first_name, last_name, dob, biological_sex, member_relationship } =
    dependent;
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  return (
    <>
      <Paper p="lg" shadow="xs" withBorder style={{ height: "100%" }}>
        <Flex
          gap={8}
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Stack gap={5}>
            <Text fw={600}>
              {first_name} {last_name}
            </Text>
            <PersonInfo
              dob={dob}
              sex={snakeCaseToTitleCase(biological_sex)}
              address={dependent.address}
              ssn={`***-**-${dependent.ssn_last_four}`}
              memberRelationship={
                MemberRelationshipOptions[member_relationship]
              }
            />
          </Stack>
          <Button
            variant="outline"
            style={{ width: "fit-content" }}
            onClick={() => openEditModal()}
          >
            Edit
          </Button>
        </Flex>
      </Paper>
      <DependentForm
        opened={editModalOpened}
        close={closeEditModal}
        dependent={dependent}
        key={dependent.id}
      />
    </>
  );
};
