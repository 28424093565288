import { EmployerReport } from "@/types/api";
import { Grid, Stack, Title } from "@mantine/core";
import { ReportDownloadCard } from "./ReportDownloadCard";

export const ViewReports = () => {
  return (
    <Stack>
      {" "}
      <Title order={2} mb="lg">
        Reports
      </Title>
      <Grid>
        <ReportDownloadCard
          reportType={EmployerReport.EnrollmentCensus}
          reportName="Enrollment Census"
          reportDescription="Download a census of the plan selections for employees and their dependents"
        />
        <ReportDownloadCard
          reportType={EmployerReport.MemberCensus}
          reportName="Member Census"
          reportDescription="Download a census of employee demographic information"
        />
        <ReportDownloadCard
          reportType={EmployerReport.PayrollDeduction}
          reportName="Payroll Deductions"
          reportDescription="Download a snapshot of the current payroll deductions for employees"
        />
      </Grid>
    </Stack>
  );
};
