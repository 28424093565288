import { clientAtom } from "@/stores";
import { mutationAtomType } from "@/types/jotai";
import { atomsWithMutation } from "jotai-tanstack-query";

export const [, putMagicLinkAtom]: [any, mutationAtomType] = atomsWithMutation(
  (get) => ({
    mutationKey: ["putMagicLink"],
    mutationFn: async () => {
      const client = await get(clientAtom);
      return await client.putMagicLink();
    },
  }),
) as [any, mutationAtomType];
