import { formatWaitingPeriod, snakeCaseToTitleCase } from "@/helpers/string";
import { WaitingPeriod } from "@/types/api";
import { Group, Stack, Text, Title } from "@mantine/core";
import dayjs from "dayjs";

interface Props {
  terminationPolicy: string;
  waitingPeriod: WaitingPeriod;
  effectiveStart: string;
  effectiveEnd: string;
}

export const AdditionalDetails = ({
  waitingPeriod,
  terminationPolicy,
  effectiveStart,
  effectiveEnd,
}: Props) => {
  const formattedEffectiveStart = dayjs(effectiveStart).format("MMMM D, YYYY");
  const formattedEffectiveEnd = dayjs(effectiveEnd).format("MMMM D, YYYY");

  return (
    <Stack>
      <Title order={2}>Additional Details</Title>
      <Group gap="xs">
        <Text fw={700}>New hire waiting period:</Text>
        <Text>{formatWaitingPeriod(waitingPeriod)}</Text>
      </Group>
      <Group gap="xs">
        <Text fw={700}>Effective dates:</Text>
        <Text>
          {formattedEffectiveStart} to {formattedEffectiveEnd}
        </Text>
      </Group>
      <Group gap="xs">
        <Text fw={700}>Termination policy:</Text>
        <Text>{snakeCaseToTitleCase(terminationPolicy)}</Text>
      </Group>
    </Stack>
  );
};
