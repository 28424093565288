import { HTMLProps } from "react";

interface Props extends HTMLProps<SVGSVGElement> {}
export const AddIcon = ({ ...rest }: Props) => {
  return (
    <svg
      {...rest}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M8.49992 15.1666C12.1818 15.1666 15.1666 12.1818 15.1666 8.49992C15.1666 4.81802 12.1818 1.83325 8.49992 1.83325C4.81802 1.83325 1.83325 4.81802 1.83325 8.49992C1.83325 12.1818 4.81802 15.1666 8.49992 15.1666Z"
          stroke="var(--mantine-primary-color-filled)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.5 5.83325V11.1666"
          stroke="var(--mantine-primary-color-filled)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M5.83325 8.5H11.1666"
          stroke="var(--mantine-primary-color-filled)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
