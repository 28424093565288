import { carriersAtom, clientAtom, configAtom, useNavigate } from "@/stores";
import { Employer, GroupConnection } from "@/types/api";
import {
  Button,
  CloseButton,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { postEmployerDocumentAtom } from "./API";

interface GroupConnectionFilesProps {
  groupConnection: GroupConnection;
  addFiles: (groupConnectionId: string, files: FileWithPath[]) => void;
  removeFile: (groupConnectionId: string, file: string) => void;
  uploadedFiles: Map<string, FileWithPath[]>;
}
const GroupConnectionFiles = ({
  groupConnection,
  addFiles,
  removeFile,
  uploadedFiles,
}: GroupConnectionFilesProps) => {
  const carrier = useAtomValue(carriersAtom).get(groupConnection.carrier);

  return (
    <>
      <Title order={3}>
        {carrier.legal_name} ({groupConnection.group_id}) - Documents
      </Title>
      <Dropzone
        onDrop={(files) => addFiles(groupConnection.id, files)}
        maxFiles={5}
        maxSize={5 * 1024 * 1024} // 5mb max file size
        accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf]}
        multiple
      >
        <Stack align="center" justify="center" mih={100}>
          <IconUpload style={{ width: 30, height: 30 }} stroke={1} />
          <Text size="lg" inline>
            Drag files here or click to select files
          </Text>
        </Stack>
      </Dropzone>
      <Group>
        {(uploadedFiles.get(groupConnection.id) || []).map((file) => (
          <Paper withBorder w={300} p="xs">
            <Group justify="space-between" wrap="nowrap" gap="xs">
              <Text style={{ overflow: "scroll" }}>{file.name}</Text>
              <CloseButton
                onClick={() => removeFile(groupConnection.id, file.path)}
              />
            </Group>
          </Paper>
        ))}
      </Group>
    </>
  );
};

export const PlanSummaryUpload = () => {
  const navigate = useNavigate();
  const client = useAtomValue(clientAtom);
  const config = useAtomValue(configAtom);
  const [connection, setConnection] = useState(client.connectionData);
  useEffect(() => {
    client.getConnection().then((c) => {
      setConnection(c);
    });
  }, []);
  const [data, postEmployerDocument] = useAtom(postEmployerDocumentAtom);
  const [uploadedFiles, setFiles] = useState<Map<string, FileWithPath[]>>(
    new Map(),
  );
  const onSubmit = async () => {
    const allFiles = Array.from(uploadedFiles).flatMap(([, files]) => files);
    for (const file of allFiles) {
      const formData = new FormData();
      formData.set("file", file);
      formData.set("name", file.name);
      formData.set("document_type", "summary_of_benefits_and_coverage");
      formData.set("employer", (connection.employer as Employer).id);
      await postEmployerDocument([formData]);
    }
    navigate("final");
  };
  const addFiles = (groupConnectionId: string, files: FileWithPath[]) => {
    const existingFiles = uploadedFiles.get(groupConnectionId) || [];

    setFiles(
      new Map(
        uploadedFiles.set(groupConnectionId, [...existingFiles, ...files]),
      ),
    );
  };
  const removeFile = (groupConnectionId: string, filePath: string) => {
    const existingFiles = uploadedFiles.get(groupConnectionId);

    setFiles(
      new Map(
        uploadedFiles.set(
          groupConnectionId,
          existingFiles.filter((file) => file.path !== filePath),
        ),
      ),
    );
  };

  const missingFileUploads = connection.group_connections.some(
    (group_connection) => !uploadedFiles.get(group_connection.id)?.length,
  );

  return (
    <Container w={700}>
      <Stack gap="lg">
        <Title order={2}>
          Upload plan documents
          <Text c="dimmed">
            Attach up to 5 files per group. Each file should not exceed 5mb
          </Text>
        </Title>

        {connection.group_connections.map((groupConnection) => (
          <GroupConnectionFiles
            groupConnection={groupConnection}
            addFiles={addFiles}
            removeFile={removeFile}
            uploadedFiles={uploadedFiles}
          />
        ))}
        <Group justify="flex-end">
          <Button
            onClick={onSubmit}
            disabled={missingFileUploads && config.apiClient == "production"}
            loading={data?.isPending}
          >
            Submit files
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};
