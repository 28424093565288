import { HTMLProps } from "react";

interface Props extends HTMLProps<SVGSVGElement> {}
export const InfoIcon = ({ ...rest }: Props) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1061_714)">
        <path
          d="M10 18.3337C14.6024 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 5.39795 14.6024 1.66699 10 1.66699C5.39765 1.66699 1.66669 5.39795 1.66669 10.0003C1.66669 14.6027 5.39765 18.3337 10 18.3337Z"
          stroke="var(--mantine-primary-color-filled)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.3333V10"
          stroke="var(--mantine-primary-color-filled)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66699H10.0083"
          stroke="var(--mantine-primary-color-filled)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1061_714">
          <rect width="20" height="20" fill="var(--mantine-color-gray-0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
