import { clientAtom } from "@/stores";
import { mutationAtomType } from "@/types/jotai";
import { atomsWithMutation } from "jotai-tanstack-query";

export const [, approvePayrollAtom]: [any, mutationAtomType] =
  atomsWithMutation((get) => ({
    mutationKey: ["approvePayroll"],
    mutationFn: async () => {
      const client = await get(clientAtom);
      return await client.postApprovePayroll();
    },
  })) as [any, mutationAtomType];
