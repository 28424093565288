import { getCursor } from "@/helpers";
import { Paginated } from "@/types/api";
import { Button, Group } from "@mantine/core";
import { UseQueryResult } from "@tanstack/react-query";

const TableBottomToolbar = ({
  data,
  setPage,
}: {
  data: UseQueryResult<Paginated<unknown>>;
  setPage: (cursor: string) => void;
}) => {
  return (
    <Group justify="flex-end" py="xs" gap="xs" px="md">
      {data.data?.previous && (
        <Button
          onClick={() => setPage(getCursor(data.data.previous))}
          disabled={data.isFetching}
          variant="outline"
        >
          Previous
        </Button>
      )}
      {data.data?.next && (
        <Button
          onClick={() => setPage(getCursor(data.data.next))}
          disabled={data.isFetching}
          variant="outline"
        >
          Next
        </Button>
      )}
    </Group>
  );
};
export default TableBottomToolbar;
