import { StepFooter } from "@/components/StepFooter";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { configAtom, partnerConfigAtom, useNavigate } from "@/stores";
import { IOption } from "@/types";
import { CarrierMember, Member } from "@/types/api";
import {
  Group,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import {
  carrierMembersAtom,
  membersAtom,
  reconcileMembersMutationAtom,
} from "./API";
import { MatchStateIcon } from "./MatchStateIcon";

const OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Medical",
    value: "medical",
  },
  {
    label: "Dental",
    value: "dental",
  },
  {
    label: "Vision",
    value: "vision",
  },
];

const STATE_ORDER = ["unmatched", "needs_attention", "matched"];

type ReconciledState = "matched" | "needs_attention" | "unmatched";

interface ExtendedMember extends CarrierMember {
  state: ReconciledState;
  reconciled_id: string;
}

interface IMapping {
  [key: string]: {
    reconciled_id: string;
    state: ReconciledState;
  };
}

export const ClaspMemberMappingPage = () => {
  const navigate = useNavigate();
  const config = useAtomValue(configAtom);
  const carrierMembers = useAtomValue(carrierMembersAtom);
  const members = useAtomValue(membersAtom);
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const [selectedFilter, setSelectedFilter] = useState(OPTIONS[0].value);
  const [mapping, setMapping] = useState<IMapping>(
    carrierMembers.reduce((acc, row) => {
      if (!row.member) return acc;
      return {
        ...acc,
        [row.id]: {
          reconciled_id: row.member,
          state: "matched",
        },
      };
    }, {}),
  );
  const [, reconcileMembersMutation] = useAtom(reconcileMembersMutationAtom);

  const onSubmit = async () => {
    const data = Object.entries(mapping).map(
      ([carrierMember, { reconciled_id: member }]) => ({
        member,
        carrierMember,
      }),
    );
    await reconcileMembersMutation([data]);
    navigate("dependent_mapping");
  };

  const onSaveProgress = () => {};

  const reconciledMembers: ExtendedMember[] = useMemo(() => {
    return carrierMembers.map((carrierMember) => {
      const match = mapping[carrierMember.id];

      if (match) {
        return {
          ...carrierMember,
          state: "matched",
          reconciled_id: match.reconciled_id,
        };
      } else {
        return {
          ...carrierMember,
          state: "unmatched",
          reconciled_id: undefined,
        };
      }
    });
  }, [mapping, carrierMembers]);

  const filteredMembers = useMemo(() => {
    let _filteredMembers = reconciledMembers;
    if (selectedFilter !== "all") {
      _filteredMembers = _filteredMembers.filter((m) =>
        m.enrolled_plans.some(
          (enrolled_plan) =>
            !(typeof enrolled_plan.plan === "string") &&
            enrolled_plan.plan.line_of_coverage === selectedFilter,
        ),
      );
    }

    return _filteredMembers;
  }, [reconciledMembers, selectedFilter]);

  const columns = useMemo(() => {
    const accounts: IOption[] = members.map((m) => ({
      value: m.id,
      label: `${m.first_name} ${m.last_name}`,
    }));

    const onMap = (row: Member, account: string) => {
      setMapping({
        ...mapping,
        [row.id]: { reconciled_id: account, state: "matched" },
      });
    };
    return [
      {
        id: "matched",
        accessorFn: (row) => mapping[row.id]?.reconciled_id || "",
        header: `${platformName} Employee`,
        size: 300,
        Cell: ({ cell, row }) => {
          return (
            <Group>
              <Select
                data={accounts}
                value={cell.getValue()}
                onChange={(option) => onMap(row.original, option)}
              />
              <MatchStateIcon state={row.original.state} />
            </Group>
          );
        },
        sortingFn: (rowA, rowB) =>
          STATE_ORDER.indexOf(rowA.original.state) -
          STATE_ORDER.indexOf(rowB.original.state),
      },
      {
        header: "Carrier Member Name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        header: "Date Of Birth",
        accessorKey: "dob",
      },
      {
        header: "SSN",
        accessorKey: "ssn_last_four",
        Cell: ({ row }) => `***-**-${row.original.ssn_last_four}`,
      },
      {
        header: "Lines of Coverage",
        accessorFn: (row) =>
          row.enrolled_plans
            .map((enrolledPlan) => enrolledPlan.plan.line_of_coverage)
            .join(", "),
        size: 150,
        Cell: ({ cell }) => {
          return (
            <Text tt="capitalize" w={200} truncate>
              {cell.getValue()}
            </Text>
          );
        },
      },
    ];
  }, [config, members, mapping]);

  const valid = useMemo(() => {
    const mappingValues = Object.values(mapping);
    if (
      mappingValues.length === carrierMembers.length &&
      mappingValues.every((m) => m.state === "matched")
    )
      return true;
    return false;
  }, [carrierMembers, mapping]);

  const table = useMantineReactTable({
    columns,
    data: filteredMembers,
    ...DEFAULT_TABLE_PROPS,
    positionGlobalFilter: "left",
    enableTopToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableSorting: true,
    initialState: {
      showGlobalFilter: true,
      sorting: [
        {
          id: "matched",
          desc: false,
        },
      ],
    },
  });

  return (
    <StepFooter
      index={1}
      total={8}
      disabled={!valid}
      onContinue={onSubmit}
      onSaveProgress={onSaveProgress}
    >
      <Stack gap="lg">
        <Title order={2}>Member Mapping</Title>
        <Text>
          We've matched member information between your carriers and{" "}
          {platformName} to the best of our capabilities. Please ensure that the
          names listed under <strong>'{platformName} Employee'</strong> match
          those in the <strong>'Carrier Member Name'</strong> column.
        </Text>
        <Group>
          <SegmentedControl
            size="md"
            radius="xl"
            data={OPTIONS}
            onChange={(value) => setSelectedFilter(value)}
          />
        </Group>
        <MantineReactTable table={table} />
      </Stack>
    </StepFooter>
  );
};
