import { isEmbedded } from "@/helpers";
import { useApiClient } from "@/helpers/hooks";
import { UsDateFormatter, snakeCaseToTitleCase } from "@/helpers/string";
import { useClaspTable } from "@/helpers/useClaspTable";
import { Enrollment, EnrollmentStatus, Member } from "@/types/api";
import { Box, Title } from "@mantine/core";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListEnrollments } from "./hooks";
import { EnrollmentStatusBadge } from "../EnrollmentStatusBadge";

export const ViewPendingEnrollments = () => {
  const client = useApiClient();
  const navigate = useNavigate();

  const [page, setPage] = useState<string | undefined>();

  const enrollmentQuery = useListEnrollments({
    client,
    cursor: page,
    status: [EnrollmentStatus.Pending, EnrollmentStatus.Approved],
    expand: ["member"],
  });

  const columns = useMemo<MRT_ColumnDef<Enrollment>[]>(
    () => [
      {
        header: "Member Name",
        accessorFn: (originalRow) =>
          `${(originalRow.member as Member).first_name} ${
            (originalRow.member as Member).last_name
          }`,
      },
      {
        header: "Reason",
        accessorFn: (originalRow) => snakeCaseToTitleCase(originalRow.reason),
      },
      {
        header: "Effective Start",
        accessorKey: "change_date",
        Cell: ({ cell }) =>
          UsDateFormatter.format(new Date(cell.getValue<string>())),
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell }) => (
          <EnrollmentStatusBadge status={cell.getValue<EnrollmentStatus>()} />
        ),
      },
    ],
    [],
  );

  const table = useClaspTable({
    columns,
    query: enrollmentQuery,
    setPage,
    onClickRow: (row) => {
      navigate(`/employer/view-enrollments/${row.original.id}`);
    },
  });

  return (
    <Box>
      {!isEmbedded() && (
        <Title order={2} mb="lg">
          Pending Enrollments
        </Title>
      )}

      <MantineReactTable table={table} />
    </Box>
  );
};
