import { PartnerRelationshipTypes } from "@/constants";
import { VoluntaryCoverageOptions } from "@/types/api";
import { VoluntarySupplementalBenefitElection } from "./form";

export const isAboveGuaranteedIssue = (
  election: VoluntarySupplementalBenefitElection,
  coverageOptions: VoluntaryCoverageOptions,
) => {
  if (Number(election.volume) == 0) return false;
  let relationshipType: keyof VoluntaryCoverageOptions;
  if (!election.memberRelationship) {
    relationshipType = "member";
  } else if (PartnerRelationshipTypes.includes(election.memberRelationship)) {
    relationshipType = "spouse";
  } else relationshipType = "child";

  const guaranteedIssue = Number(
    coverageOptions[relationshipType].guaranteed_issue,
  );

  return Number(election.volume) > guaranteedIssue;
};
