import { useApiClient } from "@/helpers/hooks";
import { UsDateFormatter, toISODateString } from "@/helpers/string";
import { useClaspTable } from "@/helpers/useClaspTable";
import { ClaspEmployerConfig } from "@/types";
import { Enrollment, EnrollmentPlan, Member, Plan } from "@/types/api";
import { Box, Button, Group, Loader, TextInput, Title } from "@mantine/core";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateEmployeeModal } from "../EmployeeModal";
import { MemberStatusBadge } from "./MemberStatusBadge";
import { useGetPartnerConfig, useListMembers } from "./hooks";
export const ViewEmployees = () => {
  const client = useApiClient();
  const navigate = useNavigate();
  const { employerId } = client.config as ClaspEmployerConfig;
  const [opened, { open, close }] = useDisclosure(false);
  const [page, setPage] = useState<string | undefined>();
  const [search, setSearch] = useDebouncedState("", 200);

  const columns = useMemo<MRT_ColumnDef<Member>[]>(
    () => [
      {
        header: "Full Name",
        accessorFn: (originalRow) =>
          `${originalRow.first_name} ${originalRow.last_name}`,
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Dependents",
        accessorFn: (originalRow) => originalRow.dependents.length,
      },
      {
        header: "Enrollments",
        accessorFn: ({ enrollments }) => {
          // Find active enrollment
          const today = toISODateString(new Date());

          const activeEnrollment = (enrollments as Enrollment[]).find(
            ({ effective_end, effective_start }) =>
              effective_start <= today && effective_end >= today,
          );

          if (!activeEnrollment) return "-";

          // Filter only unique lines of coverage
          const lineOfCoverages = new Set(
            (activeEnrollment.enrolled_plans as EnrollmentPlan[]).map(
              ({ plan }) => (plan as Plan).line_of_coverage,
            ),
          );

          return lineOfCoverages.size;
        },
      },
      {
        header: "Hire Date",
        accessorKey: "hire_date",
        Cell: ({ cell }) =>
          UsDateFormatter.format(new Date(cell.getValue<string>())),
      },
      {
        header: "Status",
        accessorFn: (originalRow) => originalRow.termination_date === null,
        Cell: ({ cell }) => (
          <MemberStatusBadge
            status={cell.getValue<boolean>() ? "active" : "inactive"}
          />
        ),
      },
    ],
    [],
  );
  const memberQuery = useListMembers({
    client,
    cursor: page,
    employerId,
    search,
    expand: [
      "enrollments",
      "enrollments.enrolled_plans",
      "enrollments.enrolled_plans.plan",
    ],
  });

  const table = useClaspTable({
    columns,
    query: memberQuery,
    page,
    setPage,
    onClickRow(row) {
      navigate(row.original.id);
    },
  });

  const partnerConfig = useGetPartnerConfig({ client });

  return (
    <Box>
      <Group justify="space-between">
        <Title order={2} mb="lg">
          Employees
        </Title>
        {partnerConfig.data?.flags?.enable_employee_management && (
          <Button
            radius="md"
            leftSection={<IconPlus size={16} />}
            onClick={open}
          >
            Add Employee
          </Button>
        )}
      </Group>
      {!employerId && (
        <Group justify="space-between" pb="md">
          <TextInput
            placeholder="Search employees"
            defaultValue={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            rightSection={
              <Loader
                size={"xs"}
                display={memberQuery.isFetching ? "inherit" : "none"}
              />
            }
          />
        </Group>
      )}
      <MantineReactTable table={table} />
      <CreateEmployeeModal opened={opened} onClose={close} />
    </Box>
  );
};
