// Split out Mantine's styles to omit the global CSS so that
// we are able to scope styles down to only affect Clasp's components
import styles from "./styles.module.css";
import "../../global.css";
import "@mantine/core/styles/UnstyledButton.css";
import "@mantine/core/styles/Text.css";
import "@mantine/core/styles/Accordion.css";
import "@mantine/core/styles/ActionIcon.css";
import "@mantine/core/styles/Affix.css";
import "@mantine/core/styles/Alert.css";
import "@mantine/core/styles/Anchor.css";
import "@mantine/core/styles/AppShell.css";
import "@mantine/core/styles/AspectRatio.css";
import "@mantine/core/styles/Avatar.css";
import "@mantine/core/styles/BackgroundImage.css";
import "@mantine/core/styles/Badge.css";
import "@mantine/core/styles/Blockquote.css";
import "@mantine/core/styles/Breadcrumbs.css";
import "@mantine/core/styles/Button.css";
import "@mantine/core/styles/Burger.css";
import "@mantine/core/styles/Center.css";
import "@mantine/core/styles/Card.css";
import "@mantine/core/styles/Checkbox.css";
import "@mantine/core/styles/Chip.css";
import "@mantine/core/styles/CloseButton.css";
import "@mantine/core/styles/Code.css";
import "@mantine/core/styles/ColorInput.css";
import "@mantine/core/styles/ColorPicker.css";
import "@mantine/core/styles/ColorSwatch.css";
import "@mantine/core/styles/Popover.css";
import "@mantine/core/styles/Combobox.css";
import "@mantine/core/styles/Container.css";
import "@mantine/core/styles/Dialog.css";
import "@mantine/core/styles/Divider.css";
import "@mantine/core/styles/Drawer.css";
import "@mantine/core/styles/Fieldset.css";
import "@mantine/core/styles/Flex.css";
import "@mantine/core/styles/Grid.css";
import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Image.css";
import "@mantine/core/styles/Indicator.css";
import "@mantine/core/styles/InlineInput.css";
import "@mantine/core/styles/Input.css";
import "@mantine/core/styles/Kbd.css";
import "@mantine/core/styles/List.css";
import "@mantine/core/styles/Loader.css";
import "@mantine/core/styles/LoadingOverlay.css";
import "@mantine/core/styles/Mark.css";
import "@mantine/core/styles/Menu.css";
import "@mantine/core/styles/Modal.css";
import "@mantine/core/styles/ModalBase.css";
import "@mantine/core/styles/NavLink.css";
import "@mantine/core/styles/Notification.css";
import "@mantine/core/styles/NumberInput.css";
import "@mantine/core/styles/Overlay.css";
import "@mantine/core/styles/Pagination.css";
import "@mantine/core/styles/Paper.css";
import "@mantine/core/styles/PasswordInput.css";
import "@mantine/core/styles/PillsInput.css";
import "@mantine/core/styles/Pill.css";
import "@mantine/core/styles/PinInput.css";
import "@mantine/core/styles/Progress.css";
import "@mantine/core/styles/Radio.css";
import "@mantine/core/styles/Rating.css";
import "@mantine/core/styles/RingProgress.css";
import "@mantine/core/styles/ScrollArea.css";
import "@mantine/core/styles/SegmentedControl.css";
import "@mantine/core/styles/SimpleGrid.css";
import "@mantine/core/styles/Skeleton.css";
import "@mantine/core/styles/Slider.css";
import "@mantine/core/styles/Spoiler.css";
import "@mantine/core/styles/Stack.css";
import "@mantine/core/styles/Stepper.css";
import "@mantine/core/styles/Switch.css";
import "@mantine/core/styles/Table.css";
import "@mantine/core/styles/Tabs.css";
import "@mantine/core/styles/ThemeIcon.css";
import "@mantine/core/styles/Timeline.css";
import "@mantine/core/styles/Title.css";
import "@mantine/core/styles/Tooltip.css";
import "@mantine/core/styles/TypographyStylesProvider.css";
import "@mantine/core/styles/VisuallyHidden.css";

import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css";

import { useClasp } from "@/stores";
import { ClaspEmployeeConfig, ClaspEmployerConfig, ClaspTheme } from "@/types";
import {
  Center,
  Loader,
  MantineProvider,
  Modal,
  Select,
  Tooltip,
  createTheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { PropsWithChildren, Suspense, useMemo } from "react";
interface Props extends PropsWithChildren {
  theme?: ClaspTheme;
  config?: ClaspEmployerConfig | ClaspEmployeeConfig;
}

const compositeTheme = (customThemeConfig) =>
  createTheme({
    fontFamily: "Montserrat, sans-serif",
    defaultRadius: "md",
    primaryColor: "teal",
    defaultColorScheme: "light",
    trHoverBg: "#F5F5F5",
    spacing: {
      lg: "24px",
    },
    components: {
      DatePickerInput: DatePickerInput.extend({
        defaultProps: {
          firstDayOfWeek: 0,
          popoverProps: { withinPortal: false },
        },
      }),
      DateInput: DateInput.extend({
        defaultProps: {
          firstDayOfWeek: 0,
          popoverProps: { withinPortal: false },
        },
      }),
      Select: Select.extend({
        defaultProps: {
          comboboxProps: { classNames: { dropdown: styles.wrapper } },
        },
      }),
      Modal: Modal.extend({
        classNames: { root: styles.wrapper },
      }),
      Tooltip: Tooltip.extend({
        defaultProps: {
          withinPortal: false,
          events: { hover: true, focus: true, touch: true },
        },
      }),
    },
    colors: {
      gray: [
        "#FFFFFF",
        "#F1F3F5",
        "#E9ECEF",
        "#DEE2E6",
        "#CED4DA",
        "#ADB5BD",
        "#868E96",
        "#495057",
        "#343A40",
        "#212529",
      ],
    },
    shadows: {
      md: "0px 9px 18px 0px rgba(0, 0, 0, 0.15)",
    },
    fontSizes: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "19px",
      xl: "24px",
    },
    ...customThemeConfig,
  });

export const ClaspWrapper = ({ children, theme, config }: Props) => {
  useClasp(config);
  const mantineTheme = useMemo(() => {
    return compositeTheme(theme);
  }, [theme]);

  return (
    <div className={styles.wrapper} style={{ height: "100%" }}>
      <MantineProvider theme={mantineTheme} classNamesPrefix="clasp">
        <Notifications autoClose={4000} transitionDuration={750} />
        <Suspense
          fallback={
            <Center h={"80dvh"}>
              <Loader />
            </Center>
          }
        >
          {children}
        </Suspense>
      </MantineProvider>
    </div>
  );
};
