import { APIClient } from "@/stores/APIClient";
import { Member, Paginated, PartnerConfig } from "@/types/api";
import {
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";

type ListMemberParams = {
  search?: string;
  cursor?: string;
  employerId?: string;
  expand?: string[];
  client: APIClient;
};
export const useListMembers = ({
  client,
  search,
  cursor,
  employerId,
  expand,
}: ListMemberParams): UseQueryResult<Paginated<Member>> =>
  useQuery({
    queryKey: ["members", cursor, employerId, search],
    queryFn: () =>
      client.getMembers({ cursor, employer: employerId, search, expand }),
    placeholderData: keepPreviousData,
  });

export const useGetPartnerConfig = ({
  client,
}: {
  client: APIClient;
}): UseQueryResult<PartnerConfig> =>
  useQuery({
    queryKey: ["partner_config"],
    queryFn: () => client.getPartnerConfig(),
    staleTime: 15 * 60 * 1000, // Cache for 15 minutes
  });
