import {
  ClaspRouter,
  ClaspWrapper,
  StateManagementWrapper,
} from "@/components";
import type { ClaspViewProps } from "@/types";
import {
  AdminPage,
  AdminPlanDetails,
  ClaspBenefitMappingPage,
  ClaspConnectChoicePage,
  ClaspContributionStrategyPage,
  ClaspDependentMappingPage,
  ClaspEmployerForm,
  ClaspFinalPage,
  ClaspMemberMappingPage,
  ClaspPayrollPage,
  PlanSummaryUpload,
  DentalReviewPage,
  FinalReviewPage,
  MedicalReviewPage,
  ProcessingStatus,
  Status,
  VisionReviewPage,
} from "./Pages";

import React from "react";
import "./clasp-overrides.modules.css";

interface Props extends ClaspViewProps {}

export const ClaspEmployer = ({
  theme,
  config,
  onChangeState,
  claspState,
}: Props) => {
  return (
    <React.StrictMode>
      <StateManagementWrapper>
        <ClaspWrapper theme={theme} config={config}>
          <ClaspRouter
            onChangeState={onChangeState}
            claspState={claspState}
            routes={{
              payroll: <ClaspPayrollPage />,
              connect: <ClaspConnectChoicePage />,
              magic: <ClaspConnectChoicePage magicDialog={true} />,
              form: <ClaspEmployerForm />,
              plan_summary_upload: <PlanSummaryUpload />,
              final: <ClaspFinalPage />,
              processing: <ProcessingStatus />,
              error: <Status status="error" />,
              needs_attention: (
                <Status status="needs_attention" forwardPage="member_mapping" />
              ),
              member_mapping: <ClaspMemberMappingPage />,
              dependent_mapping: <ClaspDependentMappingPage />,
              contribution_strategy: <ClaspContributionStrategyPage />,
              benefit_mapping: <ClaspBenefitMappingPage />,
              medical_review: <MedicalReviewPage />,
              vision_review: <VisionReviewPage />,
              dental_review: <DentalReviewPage />,
              final_review: <FinalReviewPage />,
              admin: <AdminPage />,
              admin_plan_details: <AdminPlanDetails />,
            }}
          />
        </ClaspWrapper>
      </StateManagementWrapper>
    </React.StrictMode>
  );
};
