import {
  Carrier,
  CoverageWaiver,
  Dependent,
  Enrollment,
  EnrollmentCategoryMapping,
  EnrollmentPlan,
  EnrollmentStatus,
  Group,
  Member,
  Plan,
  PrimaryCareProvider,
} from "@/types/api";

import {
  parseDate,
  snakeCaseToTitleCase,
  toISODateString,
} from "@/helpers/string";
import {
  Accordion,
  Card,
  Chip,
  Divider,
  Image,
  Loader,
  Group as MantineGroup,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useParams } from "react-router-dom";
import { EnrollmentDocumentsTable } from "./EnrollmentDocumentsTable";
import { useGetEnrollment } from "./hooks";

const PrimaryCareProviderSection = ({
  primaryCareProvider,
}: {
  primaryCareProvider: PrimaryCareProvider;
}) => {
  return (
    <Stack>
      <Text size="sm">
        <b>Primary Care Provider Details</b>
      </Text>
      {!primaryCareProvider ? (
        <>
          <Text size="sm">
            Opted for carrier to designate a primary care provider
          </Text>
        </>
      ) : (
        <SimpleGrid cols={3}>
          <TextInput
            label="Primary Care Provider Name"
            value={primaryCareProvider.full_name}
            disabled
          />
          <TextInput
            label="Provider ID Number"
            value={primaryCareProvider.provider_id_number}
            disabled
          />
          <TextInput
            label="Existing Patient"
            value={primaryCareProvider.is_existing_patient ? "Yes" : "No"}
            disabled
          />
        </SimpleGrid>
      )}
    </Stack>
  );
};

type EnrollmentPlanChangeCardProps = {
  enrollmentPlan: EnrollmentPlan;
  enrollment: Enrollment;
};
export const EnrollmentPlanChangeCard = ({
  enrollmentPlan,
  enrollment,
}: EnrollmentPlanChangeCardProps) => {
  const plan = enrollmentPlan.plan as Plan;
  enrollment.member = enrollment.member as Member;
  const lineOfCoverage = plan.line_of_coverage;
  const changeDate = new Date(enrollment.change_date);
  const { data: currentEnrollment } = useGetEnrollment(
    enrollment.member.active_enrollment as string | null,
  );

  let previousPlan;
  if (enrollment.status !== EnrollmentStatus.Pending) {
    // Approved enrollments have the entire coverage timeline
    // stored.
    const priorEffectiveEnd = new Date(enrollment.change_date);
    priorEffectiveEnd.setDate(changeDate.getDate() - 1);
    previousPlan = enrollment?.enrolled_plans.find(
      (enrollmentPlan: EnrollmentPlan) =>
        (enrollmentPlan.plan as Plan).line_of_coverage === lineOfCoverage &&
        enrollmentPlan.effective_end === toISODateString(priorEffectiveEnd),
    );
  } else if (currentEnrollment) {
    // Unapproved enrollments will need to be compared against
    // currently active enrollment
    previousPlan = (currentEnrollment.enrolled_plans as EnrollmentPlan[])
      .filter(
        (enrollmentPlan) =>
          (enrollmentPlan.plan as Plan).line_of_coverage === lineOfCoverage,
      )
      .sort(
        (a, b) =>
          new Date(b.effective_end).getTime() -
          new Date(a.effective_end).getTime(),
      )[0];
  }

  const waivedDependents = enrollment.coverage_waivers.filter(
    (waiver) => waiver.line_of_coverage === lineOfCoverage && waiver.dependent,
  );
  return (
    <>
      <Text>{snakeCaseToTitleCase(plan.line_of_coverage)}</Text>
      <Card withBorder key={enrollmentPlan.id}>
        <SimpleGrid
          cols={enrollment.status === "approved" ? 5 : 4}
          style={{ alignItems: "center" }}
        >
          <Image
            src={((plan.group as Group).carrier as Carrier).logo_url}
            h={75}
            w={75}
            fit="contain"
          />
          <Stack gap="xs">
            <Text size="xs">Changed From:</Text>
            <Text>{(previousPlan?.plan as Plan)?.plan_name}</Text>
          </Stack>
          <Stack gap="xs">
            <Text size="xs">Changed To:</Text>
            <Text>{plan.plan_name}</Text>
          </Stack>
          <Stack gap="xs">
            <Text size="xs">Effective from:</Text>
            <Text>{enrollmentPlan.effective_start}</Text>
          </Stack>
          {enrollment.status === "approved" && (
            <Chip
              checked={true}
              styles={{ iconWrapper: { display: "none" } }}
              color={"yellow"}
              variant="light"
            >
              Pending (Approved)
            </Chip>
          )}
        </SimpleGrid>
        <Text>Enrollees</Text>
        <Accordion multiple variant="separated">
          <Accordion.Item value={enrollment.member.id}>
            <Accordion.Control
              chevron={
                plan.requires_primary_care_provider ? undefined : <div />
              }
            >
              <MantineGroup justify="space-between">
                <div>
                  Member - {enrollment.member.first_name}{" "}
                  {enrollment.member.last_name} (Birthday:{" "}
                  {enrollment.member.dob})
                </div>
                <Text
                  style={(theme) => ({
                    color: theme.colors.green[9],
                  })}
                  pr={10}
                >
                  Enrolled
                </Text>
              </MantineGroup>
            </Accordion.Control>
            <Accordion.Panel>
              {plan.requires_primary_care_provider && (
                <PrimaryCareProviderSection
                  primaryCareProvider={
                    enrollmentPlan.primary_care_provider as PrimaryCareProvider
                  }
                />
              )}
            </Accordion.Panel>
          </Accordion.Item>
          {enrollmentPlan.enrolled_dependents.map((enrolledDependent) => (
            <Accordion.Item value={enrolledDependent.dependent.id}>
              <Accordion.Control
                chevron={
                  plan.requires_primary_care_provider ? undefined : <div />
                }
              >
                <MantineGroup justify="space-between">
                  <div>
                    {snakeCaseToTitleCase(
                      enrolledDependent.dependent.member_relationship,
                    )}{" "}
                    - {enrolledDependent.dependent.first_name}{" "}
                    {enrolledDependent.dependent.last_name} (Birthday:{" "}
                    {enrolledDependent.dependent.dob})
                  </div>
                  <Text
                    style={(theme) => ({
                      color: theme.colors.green[9],
                    })}
                    pr={10}
                  >
                    Enrolled
                  </Text>
                </MantineGroup>
              </Accordion.Control>
              <Accordion.Panel>
                {plan.requires_primary_care_provider && (
                  <PrimaryCareProviderSection
                    primaryCareProvider={
                      enrolledDependent.primary_care_provider as PrimaryCareProvider
                    }
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
          {waivedDependents?.map((waiver) => {
            waiver.dependent = waiver.dependent as Dependent;
            return (
              <Accordion.Item value={waiver.dependent.id!}>
                <Accordion.Control>
                  <MantineGroup justify="space-between">
                    <div>
                      {snakeCaseToTitleCase(
                        waiver.dependent.member_relationship,
                      )}{" "}
                      - {waiver.dependent.first_name}{" "}
                      {waiver.dependent.last_name} (Birthday:{" "}
                      {waiver.dependent.dob})
                    </div>
                    <Text
                      style={(theme) => ({
                        color: theme.colors.red[4],
                      })}
                      pr={10}
                    >
                      Waived
                    </Text>
                  </MantineGroup>
                </Accordion.Control>
                <Accordion.Panel>
                  Reason: {snakeCaseToTitleCase(waiver.reason)}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Card>
    </>
  );
};

const CoverageWaiverCard = ({
  coverageWaiver,
}: {
  coverageWaiver: CoverageWaiver;
}) => {
  return (
    <>
      <Text>{snakeCaseToTitleCase(coverageWaiver.line_of_coverage)}</Text>
      <Card withBorder key={coverageWaiver.line_of_coverage}>
        <Text>
          <b>Waived coverage</b>
        </Text>
        <Text size="sm">
          Reason: {snakeCaseToTitleCase(coverageWaiver.reason)}
        </Text>
      </Card>
    </>
  );
};

const EnrollmentOverview = () => {
  const { enrollmentId } = useParams() as { enrollmentId: string };

  const { data } = useGetEnrollment(enrollmentId);

  const formattedCategory = data?.category
    ? EnrollmentCategoryMapping[data.category]
    : "";
  const formattedReason = snakeCaseToTitleCase(data?.reason);

  if (!data) {
    return <Loader />;
  }
  return (
    <Stack>
      <div>
        <Text size="lg">Details</Text>
        <SimpleGrid cols={2} maw={800}>
          <TextInput label="Category" disabled value={formattedCategory} />
          <TextInput label="Reason" disabled value={formattedReason} />
          <DateInput
            label="Effective Start"
            disabled
            value={parseDate(data.change_date)}
          />
          <DateInput
            label="Effective End"
            disabled
            value={
              data.effective_end === "9999-12-31"
                ? undefined
                : parseDate(data.effective_end)
            }
          />
        </SimpleGrid>
      </div>
      <Divider />
      {!["open_enrollment"].includes(data?.category ?? "") && (
        <>
          <Stack>
            <Text size="lg">Enrollment Change Documentation</Text>
            <EnrollmentDocumentsTable
              enrollmentId={enrollmentId}
              documents={data.qualifying_life_event_documents!}
            />
          </Stack>
          <Divider />
        </>
      )}
      <div>
        <Stack>
          <Text size="lg">Line of Coverage Changes</Text>
          {(data.enrolled_plans as EnrollmentPlan[]).map(
            (enrollment_plan) =>
              enrollment_plan.effective_start >= data.change_date && (
                <EnrollmentPlanChangeCard
                  enrollmentPlan={enrollment_plan}
                  enrollment={data}
                  key={enrollment_plan.id}
                />
              ),
          )}
          {data.coverage_waivers.map(
            (coverageWaiver) =>
              coverageWaiver.member && (
                <CoverageWaiverCard coverageWaiver={coverageWaiver} />
              ),
          )}
        </Stack>
      </div>
    </Stack>
  );
};
export default EnrollmentOverview;
