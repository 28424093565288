import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom } from "@/stores";
import { Paginated, Dependent } from "@/types/api";
import { atom } from "jotai";
import { atomsWithInfiniteQuery } from "jotai-tanstack-query";

export const dependentsInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<Dependent>>
> = atomsWithInfiniteQuery<Paginated<Dependent>>((get) => ({
  queryKey: ["dependents"],
  queryFn: async ({ pageParam }) => {
    const client = await get(clientAtom);
    return client.getDependents({
      cursor: pageParam as string | undefined,
      expand: ["enrolled_plans.plan", "member"],
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const dependentsAtom = atom(async (get) => {
  return fetchAllPages(
    await get(dependentsInfiniteQueryAtom[0]),
    get(dependentsInfiniteQueryAtom[1]),
  );
});
