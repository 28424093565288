import { clientAtom } from "@/stores";
import { mutationAtomType } from "@/types/jotai";
import { atomsWithMutation } from "jotai-tanstack-query";

export const [, postContributionStrategyFormAtom] = atomsWithMutation(
  (get) => ({
    mutationKey: ["postContributionStrategyForm"],
    mutationFn: async ({ planId, body }) => {
      const client = await get(clientAtom);
      return await client.postContributionStrategyForm(planId, body);
    },
  }),
) as [any, mutationAtomType];
