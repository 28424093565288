import { useNavigate } from "@/stores";
import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Checkbox,
  Input,
  LoadingOverlay,
  Text,
  Title,
  Button,
  Collapse,
  Group,
  Stack,
} from "@mantine/core";

interface Props {
  data: any;
  opened: boolean;
}

export const ClaspBrokerMagicLinkDialog = ({ data, opened }: Props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const magicLink = data?.data?.broker_url;
  useEffect(() => {
    ref.current?.showModal();
  }, []);
  const onCancel = () => {
    navigate("connect");
  };
  const onCopyLink = () => {
    navigator.clipboard.writeText(magicLink);
  };

  return (
    <Modal
      onClose={() => navigate("connect")}
      opened={opened}
      size="lg"
      padding="xl"
      withCloseButton={false}
      centered
    >
      <LoadingOverlay visible={data?.isPending} />
      <Stack gap="xl">
        <Stack>
          <Title order={2}>Broker Magic Link</Title>
          <Text size="sm">
            Send the onboarding link to your broker to complete onboarding for
            you.
          </Text>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setChecked(e.currentTarget.checked);
            }}
            label="I authorize the recipient of this link to onboard on my behalf."
          />
          <Collapse in={checked}>
            <Input disabled value={magicLink} />
          </Collapse>
        </Stack>

        <Group justify="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onCopyLink} disabled={!checked}>
            Copy Magic Link
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
