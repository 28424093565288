import { useApiClient } from "@/helpers/hooks";
import { Enrollment } from "@/types/api";
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export const useGetEnrollment = (
  enrollmentId: string,
): UseQueryResult<Enrollment> => {
  const client = useApiClient();

  return useQuery({
    queryKey: ["enrollments", enrollmentId],
    queryFn: () =>
      client.getEnrollment(enrollmentId, {
        expand: [
          "enrolled_plans.plan.group.carrier",
          "member.employer",
          "enrolled_plans.enrolled_dependents.dependent",
          "coverage_waivers.dependent",
          "qualifying_life_event_documents",
        ],
      }),
  });
};

export const useApproveEnrollment = (): UseMutationResult<Enrollment> => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ enrollmentId }: { enrollmentId: string }) =>
      client.approveEnrollment(enrollmentId),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["enrollments"] });
    },
  });
};
export const useDeleteEnrollment = (): UseMutationResult<Enrollment> => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ enrollmentId }: { enrollmentId: string }) =>
      client.deleteEnrollment(enrollmentId),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["enrollments"] });
    },
  });
};
