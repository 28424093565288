import {
  Flex,
  Group,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { PlanQuote } from "@/types/api";
import { SelectButton } from "../SelectButton";

interface Props {
  selected?: PlanQuote;
  onSelect?: () => void;
  canBeSelected?: boolean;
}
export const WaiveCard = ({ selected, canBeSelected, onSelect }: Props) => {
  const theme = useMantineTheme();
  const isSelected = selected === null;

  return (
    <Paper
      p="lg"
      shadow="xs"
      withBorder
      style={
        isSelected && {
          border: `3px solid ${
            theme.colors[theme.primaryColor][5] || theme.primaryColor
          }`,
        }
      }
    >
      <Flex justify="space-between">
        <Text fw={600} style={{ maxWidth: "80%" }}>
          Waive coverage
          <br />
          <br />
          Choosing to waive coverage means you are opting out of enrolling in
          the above insurance plan offered by your employer.
        </Text>
        <Title size="h2">$0.00</Title>
      </Flex>
      {onSelect && (
        <Group justify="flex-end">
          <SelectButton
            onClick={onSelect}
            selected={isSelected}
            selectedText="Waive coverage"
            unselectedText="Waive coverage"
            canBeSelected={canBeSelected}
          />
        </Group>
      )}
    </Paper>
  );
};
