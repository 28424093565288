import { UsMediumDateFormatter, snakeCaseToTitleCase } from "@/helpers/string";
import { Enrollment, EnrollmentPlan, Plan, RoleMapping } from "@/types/api";
import { Anchor, Breadcrumbs, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EditEmployeeAdditionalInformationModal,
  EditEmployeeDetailsModal,
} from "../EmployeeModal";
import SSNField from "../SSNField";
import { Section } from "../Section";
import { CoverageTable } from "./CoverageTable";
import { useGetMember } from "./hooks";

export const SUPPLEMENTAL_LOCS = ["life", "accidental_death"];

export const ViewEmployeeDetail = () => {
  const { employeeId } = useParams();
  const { data, isLoading } = useGetMember(employeeId!);
  const navigate = useNavigate();
  const [detailsOpened, { open: openDetails, close: closeDetails }] =
    useDisclosure(false);
  const [
    additionalInformationOpened,
    { open: openAdditionalInformation, close: closeAdditionalInformation },
  ] = useDisclosure(false);

  const enrollment = data?.active_enrollment as Enrollment | undefined;
  const enrollmentPlans = (enrollment?.enrolled_plans ||
    []) as EnrollmentPlan[];

  const medicalPlans = enrollmentPlans.filter(
    (enrolledPlan) =>
      (enrolledPlan.plan as Plan).line_of_coverage === "medical",
  );
  const visionPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "vision",
  );
  const dentalPlans = enrollmentPlans.filter(
    (enrolledPlan) => (enrolledPlan.plan as Plan).line_of_coverage === "dental",
  );
  const supplementalPlans = enrollmentPlans.filter((enrolledPlan) =>
    SUPPLEMENTAL_LOCS.includes((enrolledPlan.plan as Plan).line_of_coverage),
  );

  const handleReturnToList = () => {
    navigate("/employer/view-employees");
  };

  return (
    <Stack gap="xl">
      <Breadcrumbs separator={<IconChevronRight size={17} />}>
        <Anchor onClick={handleReturnToList}>Employees</Anchor>
        <Anchor c="gray.8" underline="never">
          {data?.first_name} {data?.last_name}
        </Anchor>
      </Breadcrumbs>
      <Section title="Details" withEditButton onClickEdit={openDetails}>
        <Section.Field title="First Name" isLoading={isLoading}>
          {data?.first_name}
        </Section.Field>
        <Section.Field title="Last Name" isLoading={isLoading}>
          {data?.last_name}
        </Section.Field>

        <Section.Field title="Phone Number" isLoading={isLoading}>
          {data?.phone_number ?? "-"}
        </Section.Field>
        <Section.Field title="Email" isLoading={isLoading}>
          {data?.email ?? "-"}
        </Section.Field>
        <Section.Field title="Role" isLoading={isLoading}>
          {data?.role ? RoleMapping[data.role] : "-"}
        </Section.Field>
        <SSNField
          isLoading={isLoading}
          entityId={employeeId}
          entityType="member"
          ssnLastFour={data?.ssn_last_four}
        />
        <Section.Field title="Date of Birth" isLoading={isLoading}>
          {data?.dob ? UsMediumDateFormatter.format(new Date(data.dob)) : "-"}
        </Section.Field>
        <Section.Field title="Hire Date" isLoading={isLoading}>
          {data?.hire_date
            ? UsMediumDateFormatter.format(new Date(data.hire_date))
            : "-"}
        </Section.Field>
        <Section.Field title="Termination Date" isLoading={isLoading}>
          {data?.termination_date
            ? UsMediumDateFormatter.format(new Date(data.termination_date))
            : "-"}
        </Section.Field>
        <Section.Field title="Gender" isLoading={isLoading}>
          {snakeCaseToTitleCase(data?.biological_sex) ?? "-"}
        </Section.Field>
        <Section.Field title="Tobacco Usage" isLoading={isLoading}>
          {data?.tobacco_usage === null
            ? "-"
            : data?.tobacco_usage
            ? "Yes"
            : "No"}
        </Section.Field>
      </Section>

      <Section
        title="Additional Information"
        withEditButton
        onClickEdit={openAdditionalInformation}
      >
        <Section.Field title="Home Address" isLoading={isLoading}>
          {data?.address?.line1 ?? "-"} <br />
          {data?.address?.line2 ? (
            <>
              {data?.address?.line2}
              <br />
            </>
          ) : (
            ""
          )}
          {data?.address?.city}, {data?.address?.state}
          <br />
          {data?.address?.zip_code ?? "-"}
        </Section.Field>
        <Section.Field title="Hours Worked" isLoading={isLoading}>
          {data?.hours_worked} / Week
        </Section.Field>
        <Section.Field title="Pay Frequency" isLoading={isLoading}>
          {snakeCaseToTitleCase(data?.pay_frequency)}
        </Section.Field>
      </Section>
      {enrollment && data && (
        <div>
          <Stack gap="lg">
            <Text size="lg">Current Enrollment</Text>

            {medicalPlans.length > 0 && (
              <Text size="md">
                Medical
                <CoverageTable
                  enrollmentPlans={medicalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={data}
                />
              </Text>
            )}
            {visionPlans.length > 0 && (
              <Text size="md">
                Vision
                <CoverageTable
                  enrollmentPlans={visionPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={data}
                />
              </Text>
            )}
            {dentalPlans.length > 0 && (
              <Text size="md">
                Dental
                <CoverageTable
                  enrollmentPlans={dentalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={data}
                />
              </Text>
            )}
            {supplementalPlans.length > 0 && (
              <Text size="md">
                Supplemental Benefits
                <CoverageTable
                  enrollmentPlans={supplementalPlans as EnrollmentPlan[]}
                  enrollment={enrollment}
                  member={data}
                />
              </Text>
            )}
          </Stack>{" "}
        </div>
      )}
      <EditEmployeeDetailsModal
        employeeId={employeeId}
        opened={detailsOpened}
        onClose={closeDetails}
      />
      <EditEmployeeAdditionalInformationModal
        employeeId={employeeId}
        opened={additionalInformationOpened}
        onClose={closeAdditionalInformation}
      />
    </Stack>
  );
};
