import { Avatar, Flex, Group, Text, useMantineTheme } from "@mantine/core";
import { useStytchUser } from "@stytch/react";

export const UserProfile = () => {
  const { user } = useStytchUser();
  const theme = useMantineTheme();
  return user ? (
    <Group align="center" justify="space-between">
      <Text fw={500} c={"gray.8"}>
        {user.name.first_name} {user.name.last_name}
      </Text>
      <Avatar size={32} radius="xl" color={theme.colors[theme.primaryColor][5]}>
        {user.name.first_name[0].toUpperCase()}
        {user.name.last_name[0].toUpperCase()}
      </Avatar>
    </Group>
  ) : (
    <></>
  );
};
