import { DeepPartial } from "@/types/utils";
import { z } from "zod";

export const ContributionType = {
  member_cost: {
    label: "Member Cost ($)",
    unit: "$",
  },
  employer_cost: {
    label: "Employer Cost ($)",
    unit: "$",
  },
  employer_percentage: {
    label: "Employer Contribution (%)",
    unit: "%",
  },
};
const [firstContributionType, ...rest] = Object.keys(
  ContributionType,
) as (keyof typeof ContributionType)[];

const DependentStatusContributionDefinitionSchema = z
  .object({
    contributionType: z.enum([firstContributionType, ...rest]),
    contribution: z.number().min(0),
    monthlyMinThreshold: z.coerce.number().min(0).optional(),
    monthlyMaxThreshold: z.coerce.number().min(0).optional(),
  })
  .refine(
    (definition) => {
      if (ContributionType[definition.contributionType].unit === "%") {
        return definition.contribution <= 100;
      }
      return true;
    },
    { message: "Cannot contribute over 100%", path: ["contribution"] },
  );

const ContributionStrategySchema = z.object({
  member: DependentStatusContributionDefinitionSchema,
  memberSpouse: DependentStatusContributionDefinitionSchema,
  memberChild: DependentStatusContributionDefinitionSchema,
  memberChildren: DependentStatusContributionDefinitionSchema,
  memberFamily: DependentStatusContributionDefinitionSchema,
});

export const GlobalStrategyFormValuesSchema = z.object({
  medical: ContributionStrategySchema,
  dental: ContributionStrategySchema,
  vision: ContributionStrategySchema,
});
export type GlobalStrategyFormValues = DeepPartial<
  z.infer<typeof GlobalStrategyFormValuesSchema>
>;

export const PerPlanFormValuesSchema = z.object({
  planContributionStrategies: z
    .array(
      z.object({
        planId: z.string(),
        contributionStrategy: ContributionStrategySchema,
      }),
    )
    .min(1),
});
export type PerPlanFormValues = DeepPartial<
  z.infer<typeof PerPlanFormValuesSchema>
>;

const initialContributionStrategy = {
  contribution: undefined,
  contributionType: undefined,
  monthlyMinThreshold: undefined,
  monthlyMaxThreshold: undefined,
};
export const initialPlanContributionStrategy = {
  member: initialContributionStrategy,
  memberSpouse: initialContributionStrategy,
  memberChild: initialContributionStrategy,
  memberChildren: initialContributionStrategy,
  memberFamily: initialContributionStrategy,
};
