import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom } from "@/stores";
import { CarrierMember, Member, Paginated } from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
} from "jotai-tanstack-query";

const carrierMembersInfiniteQueryAtom = atomsWithInfiniteQuery<
  Paginated<CarrierMember>
>((get) => ({
  queryKey: ["carrierMembers"],
  queryFn: async ({ pageParam }) => {
    const client = await get(clientAtom);
    return client.getCarrierMembers({
      cursor: pageParam as string | undefined,
      expand: ["enrolled_plans.plan"],
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const carrierMembersAtom = atom<Promise<CarrierMember[]>>(
  async (get) => {
    return fetchAllPages(
      await get(carrierMembersInfiniteQueryAtom[0]),
      get(carrierMembersInfiniteQueryAtom[1]),
    );
  },
);

const membersInfiniteQueryAtom = atomsWithInfiniteQuery<Paginated<Member>>(
  (get) => ({
    queryKey: ["members"],
    queryFn: async ({ pageParam }) => {
      const client = await get(clientAtom);
      return client.getMembers({
        cursor: pageParam as string | undefined,
        expand: ["enrollments.enrolled_plans.plan"],
      });
    },
    getNextPageParam: (lastPage) => getCursor(lastPage.next),
    initialPageParam: undefined,
  }),
);

export const membersAtom = atom<Promise<Member[]>>(async (get) => {
  return fetchAllPages(
    await get(membersInfiniteQueryAtom[0]),
    get(membersInfiniteQueryAtom[1]),
  );
});

export const [, reconcileMembersMutationAtom] = atomsWithMutation((get) => ({
  mutationKey: ["postReconcileMembers"],
  mutationFn: async (body: any) => {
    const client = await get(clientAtom);
    return await client.reconcileMembers(body);
  },
})) as [any, mutationAtomType];
