import { useQueryParams } from "@/helpers";
import { claspStateAtom, clientAtom, configAtom, useNavigate } from "@/stores";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";

interface Props {
  routes: {
    [routeId: string]: React.ReactNode;
  };
  claspState?: string;
  onChangeState?: (state: string) => void;
}

export const ClaspRouter = ({ routes, claspState, onChangeState }: Props) => {
  const navigate = useNavigate();
  const params = useQueryParams();
  const config = useAtomValue(configAtom);
  const _claspState = useAtomValue(claspStateAtom);
  const client = useAtomValue(clientAtom);

  useEffect(() => {
    // when the URL params are updated
    setTimeout(() => {
      // escapes the event loop so that the params are checked after the claspStateAtom has updated
      const newState = new URLSearchParams(window.location.search).get(
        "claspState",
      );
      if (newState && newState !== _claspState) {
        navigate(newState);
      }
      if (claspState) navigate(claspState);
    }, 1);
  }, [params, claspState, navigate]);

  useEffect(() => {
    if (_claspState && onChangeState) onChangeState(_claspState);
  }, [_claspState, navigate]);

  useEffect(() => {
    if (_claspState !== "") return;
    if ("employerId" in client.config) {
      if (!client.connectionData) return;
      if (client.connectionData.status === "not_started") navigate("payroll");
      if (client.connectionData.status === "pending") navigate("form");
      if (client.connectionData.status === "processing") navigate("processing");
      if (client.connectionData.status === "needs_attention") navigate("error");
      if (client.connectionData.status === "review")
        navigate("needs_attention");
      if (client.connectionData.status === "completed") navigate("admin");
    } else {
      navigate("employee_home");
    }
  }, [client.connectionData]);

  const view = useMemo(() => {
    return routes[_claspState];
  }, [_claspState, routes]);
  if (config === undefined) return null;
  return view;
};
