import { CarrierImage } from "@/components/CarrierImage";
import { DEFAULT_TABLE_PROPS, SUPPLEMENTAL_LOCS } from "@/constants";
import { isEmbedded } from "@/helpers";
import { normalizeDate, parseDate } from "@/helpers/string";
import { InfoIcon } from "@/icons/Info";
import {
  clientAtom,
  configAtom,
  partnerConfigAtom,
  selectedPlanAtom,
  useNavigate,
} from "@/stores";
import {
  Alert,
  Box,
  Button,
  Center,
  Group,
  Stack,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useAtomValue, useSetAtom } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { allPlansAtom } from "../Review/API";

export const AdminPage = () => {
  const config = useAtomValue(configAtom);
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const navigate = useNavigate();
  const setSelectedPlan = useSetAtom(selectedPlanAtom);
  const plans = useAtomValue(allPlansAtom);
  const activePlans = useMemo(
    () =>
      plans.filter(
        (plan) =>
          parseDate(plan.effective_start) <= normalizeDate(new Date()) &&
          parseDate(plan.effective_end) >= normalizeDate(new Date()),
      ),
    [plans],
  );
  const medicalPlans = useMemo(
    () => activePlans.filter((plan) => plan.line_of_coverage === "medical"),
    [activePlans],
  );
  const visionPlans = useMemo(
    () => activePlans.filter((plan) => plan.line_of_coverage === "vision"),
    [activePlans],
  );
  const dentalPlans = useMemo(
    () => activePlans.filter((plan) => plan.line_of_coverage === "dental"),
    [activePlans],
  );
  const supplementalPlans = useMemo(
    () =>
      activePlans.filter((plan) =>
        SUPPLEMENTAL_LOCS.includes(plan.line_of_coverage),
      ),
    [activePlans],
  );

  const client = useAtomValue(clientAtom);
  const generate1095CPreviews = async () => {
    const fileData = await client.generate1095CPreviews();
  };
  const enable1095CPreview = medicalPlans.every(
    (plan) =>
      plan.is_low_cost !== null &&
      plan.provides_essential_coverage !== null &&
      plan.provides_minimum_value !== null,
  );
  const columns = useMemo(() => {
    return [
      {
        header: "",
        accessorKey: "group.carrier.id",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Center>
            <CarrierImage carrierId={renderedCellValue} />
          </Center>
        ),
      },
      {
        header: "plan type",
        accessorKey: "group.carrier.trade_name",
        size: 120,
      },
      {
        header: "plan name",
        accessorKey: "plan_name",
        size: 140,
      },
      {
        header: "group number",
        accessorKey: "group.group_number",
        size: 80,
      },
      {
        header: "enrollees",
        accessorKey: "enrollees",
        size: 130,
        Cell: ({ row }) => (
          <>
            {row.original.member_count} employees
            <br />
            {row.original.dependent_count} dependents
          </>
        ),
      },
      {
        header: "",
        accessorKey: "id",
        size: 80,
        Cell: ({ row }) => {
          const onClick = () => {
            setSelectedPlan(row.original.id);
            navigate("admin_plan_details");
          };
          return (
            <>
              <Button size="sm" onClick={onClick}>
                See details
              </Button>
            </>
          );
        },
      },
    ];
  }, [navigate, setSelectedPlan]);

  const medicalTable = useMantineReactTable({
    columns,
    data: medicalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const visionTable = useMantineReactTable({
    columns,
    data: visionPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const dentalTable = useMantineReactTable({
    columns,
    data: dentalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const supplementalTable = useMantineReactTable({
    columns,
    data: supplementalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  return (
    <Stack gap="lg">
      {!isEmbedded() && <Title order={2}>Your Benefits Summary</Title>}
      <Alert
        icon={<InfoIcon />}
        title={`${platformName} does not handle premium payments for you. Please
          ensure to pay your invoices on time, making payments directly to the
          insurance carrier.`}
      />
      <Group>
        <Title order={2}>Medical</Title>
        {client.config.apiClient !== "production" && (
          <Tooltip
            label="Every medical plan must have ACA details populated
          before generating the 1095-C previews"
            disabled={enable1095CPreview}
          >
            <Button
              onClick={generate1095CPreviews}
              variant="outline"
              disabled={!enable1095CPreview}
            >
              <IconDownload />
              Generate 1095-C Previews
            </Button>
          </Tooltip>
        )}
      </Group>
      <Box>
        <MantineReactTable table={medicalTable} />
      </Box>
      <Title order={2}>Vision</Title>
      <MantineReactTable table={visionTable} />
      <Title order={2}>Dental</Title>
      <MantineReactTable table={dentalTable} />
      <Title order={2}>Supplemental</Title>
      <MantineReactTable table={supplementalTable} />
    </Stack>
  );
};
