import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom, selectedPlanAtom } from "@/stores";
import {
  ContributionStrategy,
  Enrollment,
  EnrollmentStatus,
  Paginated,
  Plan,
} from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
  atomsWithQuery,
} from "jotai-tanstack-query";

export const planInfoAtom: ReturnType<typeof atomsWithQuery<Plan>> =
  atomsWithQuery<Plan>((get) => {
    const plan = get(selectedPlanAtom);
    return {
      queryKey: ["plans", plan],
      queryFn: async ({ queryKey: [_, id] }) => {
        const client = await get(clientAtom);
        return await client.getPlan(id as string, {
          expand: ["group.carrier"],
        });
      },
      enabled: !!plan,
    };
  });

export const [, updatePlanAtom] = atomsWithMutation((get) => ({
  mutationKey: ["updatePlan"],
  mutationFn: async ({ planId, body }) => {
    const client = await get(clientAtom);
    return await client.updatePlan(planId, body);
  },
})) as [any, mutationAtomType];

export const contributionStrategyAtom: ReturnType<
  typeof atomsWithQuery<ContributionStrategy>
> = atomsWithQuery<ContributionStrategy>((get) => ({
  queryKey: ["plans", get(selectedPlanAtom), "contributionStrategy"],
  queryFn: async ({ queryKey: [_, id] }) => {
    const client = await get(clientAtom);
    return await client.getContributionStrategy(id as string);
  },
}));

export const enrollmentsInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<Enrollment>>
> = atomsWithInfiniteQuery<Paginated<Enrollment>>((get) => ({
  queryKey: ["enrollments", get(selectedPlanAtom)],
  queryFn: async ({ pageParam, queryKey: [_, planId] }) => {
    const client = await get(clientAtom);
    return await client.listEnrollments({
      planId: planId as string,
      status: [EnrollmentStatus.Synced],
      expand: ["member", "enrolled_plans"],
      cursor: pageParam as string,
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const enrollmentsAtom = atom(async (get) => {
  return fetchAllPages(
    await get(enrollmentsInfiniteQueryAtom[0]),
    get(enrollmentsInfiniteQueryAtom[1]),
  );
});
