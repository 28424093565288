import { snakeCaseToTitleCase } from "@/helpers/string";
import {
  Dependent,
  EnrollmentPlan,
  EnrollmentPlanDependent,
  Group as GroupType,
  Plan,
} from "@/types/api";
import {
  Anchor,
  Card,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import { CarrierImage } from "..";

export const EnrolledPlanCard = ({
  enrollmentPlan,
}: {
  enrollmentPlan: EnrollmentPlan;
}) => {
  const plan = enrollmentPlan.plan as Plan;
  const theme = useMantineTheme();
  const dependents = (
    enrollmentPlan.enrolled_dependents as EnrollmentPlanDependent[]
  ).map((enrolledDependent) => {
    const dependent = enrolledDependent.dependent as Dependent;
    return `${dependent.first_name} ${dependent.last_name}`;
  });
  return (
    <Card shadow="xs" padding="lg" pt="lg" radius="md" withBorder>
      <Group justify="space-between" align="center" mb="md">
        <CarrierImage
          carrierId={(plan.group as GroupType).carrier as string}
          h={50}
          w={100}
        />

        <Tooltip
          label={
            dependents.length === 0
              ? "No dependents enrolled"
              : dependents.join("; ")
          }
          position="top"
        >
          <Group gap={2}>
            <IconUser color={theme.colors.gray[7]} stroke={1.5} />
            <Text size="sm" fw={400} c={theme.colors.gray[7]}>
              {dependents.length}
            </Text>
          </Group>
        </Tooltip>
      </Group>
      <Stack gap="xs">
        <Text fw={700} size="sm" h="2.5rem">
          [{snakeCaseToTitleCase(plan.line_of_coverage)}] {plan.plan_name}
        </Text>

        <Text size="sm">
          <b>Effective from:</b> {enrollmentPlan.effective_start}
        </Text>
        <Text size="sm">
          <b>Cost:</b> ${enrollmentPlan.member_contribution}
        </Text>
        <Anchor href={plan.plan_summary_url} target="_blank" size="sm">
          <b>View full plan details</b>
        </Anchor>
      </Stack>
    </Card>
  );
};
