import { authAtom } from "@/App";
import { getClaspApiUrl } from "@/helpers";
import { entityIdAtom } from "@/stores";
import { PartnerConfig } from "@/types/api";
import { Box, Burger, Group, Image, Stack } from "@mantine/core";
import { useStytch, useStytchUser } from "@stytch/react";
import {
  Icon,
  IconBuilding,
  IconClipboardHeart,
  IconFileAnalytics,
  IconFileDescription,
  IconLogout,
  IconSwitchHorizontal,
  IconUser,
} from "@tabler/icons-react";
import axios from "axios";
import { useSetAtom } from "jotai";
import React, { SetStateAction, useCallback } from "react";
import { NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import { UserProfile } from "../UserProfile";
import classes from "./Sidebar.module.css";

const SidebarItem = ({
  icon,
  title,
  ...rest
}: {
  icon: Icon;
  title: string;
} & React.ComponentProps<typeof NavLink>) => (
  <NavLink
    className={({ isActive }) =>
      [classes.navLink, isActive && classes.activeNavLink].join(" ")
    }
    {...rest}
  >
    <Group gap="sm" align="center">
      {React.createElement(icon, {
        size: 20,
        stroke: 1.5,
        style: { marginTop: 1 }, // This prevents layout from jumping between opened and closed sidebar
      })}
      <Box display="block">{title}</Box>
    </Group>
  </NavLink>
);

export const Sidebar = ({
  environment,
  setError,
  partnerConfig,
  opened,
  toggle,
}: {
  environment: string;
  setError: React.Dispatch<SetStateAction<boolean>>;
  partnerConfig: PartnerConfig;
  opened: boolean;
  toggle: () => void;
}) => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const sessionTokens = stytch.session.getTokens();
  const setEntityId = useSetAtom(entityIdAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const setAuth = useSetAtom(authAtom);
  const baseUrl = getClaspApiUrl(
    environment as "development" | "sandbox" | "production",
  );
  const otherComponent = location.pathname.startsWith("/employer")
    ? "member"
    : "employer";
  const logout: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      event.preventDefault();
      stytch.session.revoke();
      navigate("/app/login");
    },
    [stytch],
  );

  const switchView: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    axios
      .post(
        `${baseUrl}/access/authenticate_stytch_session`,
        { component: otherComponent },
        {
          withCredentials: true,
          headers: { "Stytch-Session-ID": sessionTokens?.session_token },
        },
      )
      .then((resp) => {
        localStorage.setItem("component", "member");
        if (otherComponent === "employer") {
          setEntityId(user.trusted_metadata.employer_id as string);
          localStorage.setItem(
            "employer_id",
            user.trusted_metadata.employer_id as string,
          );
          localStorage.removeItem("member_id");
        } else {
          setEntityId(user.trusted_metadata.member_id as string);
          localStorage.setItem(
            "member_id",
            user.trusted_metadata.member_id as string,
          );
          localStorage.removeItem("employer_id");
        }
        setAuth(resp.data);
        navigate(`/${otherComponent}`);
      })
      .catch(() => setError(true));
  };

  const isEmployerView = useMatch("/employer/*");
  const sidebarItems = isEmployerView
    ? [
        {
          link: "/employer/view-plans",
          label: "View Plans",
          icon: IconFileAnalytics,
        },
        {
          link: "/employer/view-employees",
          label: "Manage Employees",
          icon: IconUser,
        },
        {
          link: "/employer/view-enrollments",
          label: "Manage Enrollments",
          icon: IconClipboardHeart,
        },
        {
          link: "/employer/reports",
          label: "Reports",
          icon: IconFileDescription,
        },
      ]
    : [
        {
          link: "/member/view-benefits",
          label: "View Benefits",
          icon: IconBuilding,
        },
        {
          link: "/member/update-benefits",
          label: "Update Benefits",
          icon: IconUser,
        },
      ];

  return (
    <Stack gap={0} h="100%" justify="space-between">
      <Stack gap={0}>
        <Group justify="space-between" pl="lg">
          <Image
            mt={"xl"}
            mb={10}
            h={32}
            w={176}
            fit="contain"
            src={partnerConfig.logo_url}
          />
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        </Group>
        <Stack gap={0} py="md">
          {sidebarItems.map((item) => (
            <SidebarItem
              to={item.link}
              key={item.link}
              title={item.label}
              icon={item.icon}
            />
          ))}
        </Stack>
      </Stack>
      <Stack gap={0} py="md">
        <Box px={24} pb={12}>
          <UserProfile />
        </Box>

        {user?.trusted_metadata?.role === "admin" && (
          <SidebarItem
            to=""
            icon={IconSwitchHorizontal}
            title={`Switch to ${otherComponent} view`}
            onClick={switchView}
          />
        )}

        <SidebarItem to="" title="Logout" icon={IconLogout} onClick={logout} />
      </Stack>
    </Stack>
  );
};
