import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom, configAtom, selectedEnrollmentAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import {
  Dependent,
  Employer,
  Enrollment,
  Member,
  Paginated,
  Plan,
} from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { Atom, atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
  atomsWithQuery,
  queryClientAtom,
} from "jotai-tanstack-query";

export const memberDependentsInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<Dependent>>
> = atomsWithInfiniteQuery<Paginated<Dependent>>((get) => ({
  queryKey: [
    "dependents",
    get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
  ],
  queryFn: async ({ pageParam, queryKey }) => {
    const client = await get(clientAtom);
    return client.getDependents({
      cursor: pageParam as string | undefined,
      member: queryKey[1] as string,
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const memberDependentsAtom = atom(async (get) => {
  return fetchAllPages(
    await get(memberDependentsInfiniteQueryAtom[0]),
    get(memberDependentsInfiniteQueryAtom[1]),
  );
});

export const memberInfoAtom: ReturnType<typeof atomsWithQuery<Member>> =
  atomsWithQuery<Member>((get) => ({
    queryKey: [
      "members",
      get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
      ["enrollments"],
    ],
    queryFn: async ({ queryKey: [_, id] }) => {
      const client = await get(clientAtom);
      return await client.getMemberInfo(id as string, {
        expand: ["enrollments", "employer"],
      });
    },
  }));

export const getEnrollmentAtom: ReturnType<typeof atomsWithQuery<Enrollment>> =
  atomsWithQuery<Enrollment>((get) => ({
    queryKey: ["enrollments", get(selectedEnrollmentAtom).id],
    queryFn: async ({ queryKey: [_, id] }) => {
      const client = await get(clientAtom);
      return await client.getEnrollment(id as string, {
        expand: [
          "enrolled_plans.plan.group",
          "enrolled_plans.plan.bundle.plan",
          "enrolled_plans.enrolled_dependents.dependent",
        ],
      });
    },
  }));

export const [, getEligiblePlansAtom] = atomsWithMutation((get) => ({
  mutationKey: ["eligiblePlans"],
  mutationFn: async ({ enrollmentId, body }) => {
    const client = await get(clientAtom);

    return await client.getEnrollmentEligiblePlans(enrollmentId, body, {
      expand: ["plan.group", "plan.bundle.plans"],
    });
  },
})) as [any, mutationAtomType];

export const [, createDependentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["createDependent"],
  mutationFn: async (body) => {
    const client = await get(clientAtom);
    return await client.createDependent(body);
  },
})) as [any, mutationAtomType];
export const [, updateDependentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["updateDependent"],
  mutationFn: async ({ dependentId, body }) => {
    const client = await get(clientAtom);
    return await client.updateDependent(dependentId, body);
  },
})) as [any, mutationAtomType];

export const [, saveAndSubmitEnrollmentAtom] = atomsWithMutation((get) => ({
  mutationKey: ["saveAndSubmitEnrollment"],
  mutationFn: async ({ enrollmentId, body }) => {
    const client = await get(clientAtom);
    return await client.saveAndSubmitEnrollment(enrollmentId, body);
  },
  onSuccess: async () => {
    const queryClient = get(queryClientAtom);
    await queryClient.refetchQueries({ queryKey: ["members"] });
  },
})) as [any, mutationAtomType];

const plansInfiniteQueryAtom = atomsWithInfiniteQuery<Paginated<Plan>>(
  (get) => {
    return {
      queryKey: ["plans"],
      queryFn: async ({ pageParam }) => {
        const client = await get(clientAtom);
        const { employer } = await get(memberInfoAtom[0]);
        return client.getPlans({
          cursor: pageParam as string | undefined,
          employer: (employer as Employer).id,
        });
      },
      getNextPageParam: (lastPage) => getCursor(lastPage.next),
      initialPageParam: undefined,
    };
  },
);

export const employerPlansAtom = atom<Promise<Plan[]>>(async (get) => {
  return fetchAllPages(
    await get(plansInfiniteQueryAtom[0]),
    get(plansInfiniteQueryAtom[1]),
  );
});
