import { PLAN_TYPE } from "@/constants";
import { UsCurrencyFormatter, UsDateFormatter } from "@/helpers/string";
import { EnrollmentPlan, PayFrequency, Plan } from "@/types/api";
import {
  Grid,
  GridColProps,
  GridProps,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import { memberInfoAtom } from "../API";
import { PlanInfoItem } from "./PlanInfoItem";
import { useMediaQuery } from "@mantine/hooks";

const PayFrequencyMapping = new Map<PayFrequency, string>([
  ["weekly", "Per week"],
  ["biweekly", "Every two weeks"],
  ["semimonthly", "Twice a month"],
  ["monthly", "Per month"],
]);

const PayFrequencyMappingMobile = new Map<PayFrequency, string>([
  ["weekly", "Weekly Cost"],
  ["biweekly", "Biweekly Cost"],
  ["semimonthly", "Semimonthly Cost"],
  ["monthly", "Monthly Cost"],
]);

export const PlanInformationHeader = ({
  enrollmentPlan,
  ...rest
}: {
  enrollmentPlan: EnrollmentPlan;
} & GridProps) => {
  const theme = useMantineTheme();
  const memberContributionQuery = useMediaQuery(
    `(max-width: ${theme.breakpoints.md})`,
  );

  const plan = enrollmentPlan.plan as Plan;
  const member = useAtomValue(memberInfoAtom[0]);
  const gridColProps: GridColProps = {
    span: {
      base: 6,
      md: 3,
    },
  };

  const memberContribution = UsCurrencyFormatter.format(
    Number(enrollmentPlan.per_pay_period_member_contribution),
  );

  return (
    <Grid {...rest}>
      <PlanInfoItem
        header="Plan Type"
        value={PLAN_TYPE[plan.plan_type]?.label ?? "-"}
        {...gridColProps}
      />
      <PlanInfoItem
        header="Effective Start"
        value={UsDateFormatter.format(dayjs(plan.effective_start).toDate())}
        {...gridColProps}
      />
      <PlanInfoItem
        header="Next Renewal"
        value={UsDateFormatter.format(
          dayjs(plan.effective_end).add(1, "day").toDate(),
        )}
        {...gridColProps}
      />
      <PlanInfoItem
        hiddenFrom="md"
        header={PayFrequencyMappingMobile.get(
          member.pay_frequency ?? "monthly",
        )}
        {...gridColProps}
        value={memberContribution}
      />
      <Grid.Col {...gridColProps} visibleFrom="md" pr="xl">
        <Stack
          pl={memberContributionQuery ? undefined : "lg"}
          align={memberContributionQuery ? undefined : "center"}
        >
          <Title order={3}>{memberContribution}</Title>
          <Text size="xs" style={{ whiteSpace: "nowrap" }}>
            {PayFrequencyMapping.get(member.pay_frequency ?? "monthly")}
          </Text>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
