import { CarrierImage } from "@/components";
import { EnrollmentStatusBadge } from "@/components/EnrollmentStatusBadge";
import { snakeCaseToTitleCase } from "@/helpers/string";
import {
  EnrollmentPlan,
  Plan,
  Group as GroupType,
  Enrollment,
} from "@/types/api";
import {
  Accordion,
  Box,
  Anchor,
  Card,
  Divider,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PlanDetailItem } from "./PlanDetailItem";
import { DependentsTable } from "./DependentsTable";
import { IconChevronDown } from "@tabler/icons-react";
import { PlanInformationHeader } from "./PlanInformationHeader";

export const EnrollmentPlanCard = ({
  enrollmentPlan,
  enrollment,
}: {
  enrollmentPlan: EnrollmentPlan;
  enrollment: Enrollment;
}) => {
  const plan = enrollmentPlan.plan as Plan;
  const group = plan.group as GroupType;

  const planName = (
    <Stack style={{ flex: 1 }}>
      <Text fw="bold">{plan.plan_name}</Text>
      {plan.plan_summary_url && (
        <Anchor
          size="sm"
          href={plan.plan_summary_url}
          onClick={(e) => {
            // Prevent expanding the accordion
            e.stopPropagation();
          }}
          target="_blank"
          fw="bold"
        >
          See Plan Details
        </Anchor>
      )}
      <EnrollmentStatusBadge status={enrollment.status} />
    </Stack>
  );

  return (
    <Card shadow="xs" padding="lg" pt="lg" radius="md" withBorder>
      <Stack hiddenFrom="lg">
        <Group>
          <CarrierImage carrierId={group.carrier as string} />
          {planName}
        </Group>
        <Divider />
        <PlanInformationHeader
          enrollmentPlan={enrollmentPlan}
          gutter="sm"
          grow
        />
      </Stack>
      <Accordion
        visibleFrom="lg"
        chevronPosition="right"
        chevronSize={24}
        chevron={<IconChevronDown stroke={1.5} size={24} />}
      >
        <Accordion.Item
          value={plan.id}
          styles={{
            item: {
              borderBottomWidth: 0,
            },
          }}
        >
          <Accordion.Control>
            <Group pr="lg">
              <CarrierImage carrierId={group.carrier as string} />
              {planName}
              <Divider orientation="vertical" />
              <PlanInformationHeader
                enrollmentPlan={enrollmentPlan}
                gutter="xl"
              />
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Paper bg="gray.1" px="lg" py="md" mb="md" shadow="none">
              <Title order={5} pb="md">
                Coverage Details
              </Title>

              <SimpleGrid
                cols={Object.keys(plan.plan_details).length}
                bg="white"
                p="sm"
                styles={{
                  root: {
                    borderRadius: 8,
                  },
                }}
              >
                {Object.entries(plan.plan_details).map(([key, value]) => {
                  const { info_lines, label, tooltip } =
                    typeof value === "object"
                      ? value
                      : {
                          label: undefined,
                          tooltip: undefined,
                          info_lines: undefined,
                        };

                  return (
                    <PlanDetailItem
                      key={key}
                      header={label ?? snakeCaseToTitleCase(key)}
                      tooltip={tooltip}
                    >
                      {typeof value === "string" ? (
                        <Text size="sm">{value}</Text>
                      ) : (
                        info_lines.map((line, index) => (
                          <Text size="sm" key={index}>
                            {line}
                          </Text>
                        ))
                      )}
                    </PlanDetailItem>
                  );
                })}
              </SimpleGrid>
            </Paper>
            <Paper bg="gray.1" px="lg" py="md" mb="md" shadow="none">
              <Title order={5}>
                Dependents Enrolled ({enrollmentPlan.enrolled_dependents.length}
                )
              </Title>

              {enrollmentPlan.enrolled_dependents.length > 0 && (
                <Box mt="md">
                  <DependentsTable enrollmentPlan={enrollmentPlan} />
                </Box>
              )}
            </Paper>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Card>
  );
};
