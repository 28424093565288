import { ActionIcon, Group, Loader } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { ComponentProps, useEffect, useState } from "react";
import { SectionField } from "../Section";
import { useRevealSSN } from "./hooks";
import { formatSSN } from "@/helpers/string";

type Props = Omit<ComponentProps<typeof SectionField>, "title"> & {
  entityType: "dependent" | "member";
  entityId: string;
  ssnLastFour?: string;
};

export const SSNField = ({
  entityType,
  entityId,
  ssnLastFour,
  isLoading,
  ...rest
}: Props) => {
  const [value, setValue] = useState("");

  const [revealed, setRevealed] = useState(false);
  const revealSSN = useRevealSSN();

  const onRevealToggle = async () => {
    const shouldReveal = !revealed;
    if (shouldReveal) {
      revealSSN.mutate(
        { entityId, entityType },
        {
          onSuccess: ({ ssn }) => {
            setValue(formatSSN(ssn));
          },
        },
      );
    } else {
      setValue(`***-**-${ssnLastFour}`);
    }
    setRevealed(shouldReveal);
  };

  useEffect(() => {
    setValue(ssnLastFour ? `***-**-${ssnLastFour}` : "-");
  }, [ssnLastFour]);
  return (
    <SectionField title={"SSN"} isLoading={isLoading} {...rest}>
      <Group gap="xs">
        {value}
        {ssnLastFour && (
          <ActionIcon
            variant="subtle"
            onClick={onRevealToggle}
            disabled={revealSSN.isPending}
          >
            {revealSSN.isPending ? <Loader size="xs" /> : <IconEye />}
          </ActionIcon>
        )}
      </Group>
    </SectionField>
  );
};
