import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { clientAtom } from "@/stores";
import {
  Member,
  Paginated,
  PayrollBenefit,
  PayrollBenefitReconciliation,
} from "@/types/api";
import { mutationAtomType } from "@/types/jotai";
import { atom } from "jotai";
import {
  atomsWithInfiniteQuery,
  atomsWithMutation,
} from "jotai-tanstack-query";

const payrollBenefitsInfiniteQueryAtom = atomsWithInfiniteQuery<
  Paginated<PayrollBenefit>
>((get) => ({
  queryKey: ["payrollBenefits"],
  queryFn: async ({ pageParam }) => {
    const client = await get(clientAtom);
    return client.getPayrollBenefits({
      cursor: pageParam as string | undefined,
    });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
}));

export const payrollBenefitsAtom = atom<Promise<PayrollBenefit[]>>(
  async (get) => {
    return fetchAllPages(
      await get(payrollBenefitsInfiniteQueryAtom[0]),
      get(payrollBenefitsInfiniteQueryAtom[1]),
    );
  },
);

const membersInfiniteQueryAtom = atomsWithInfiniteQuery<Paginated<Member>>(
  (get) => ({
    queryKey: ["members"],
    queryFn: async ({ pageParam }) => {
      const client = await get(clientAtom);
      return client.getMembers({
        cursor: pageParam as string | undefined,
        expand: ["enrollments.enrolled_plans.plan"],
      });
    },
    getNextPageParam: (lastPage) => getCursor(lastPage.next),
    initialPageParam: undefined,
  }),
);

export const membersAtom = atom<Promise<Member[]>>(async (get) => {
  return fetchAllPages(
    await get(membersInfiniteQueryAtom[0]),
    get(membersInfiniteQueryAtom[1]),
  );
});

export const [, reconcilePayrollBenefitsMutationAtom] = atomsWithMutation(
  (get) => ({
    mutationKey: ["putReconcilePayrollBenefits"],
    mutationFn: async (body: PayrollBenefitReconciliation[]) => {
      const client = await get(clientAtom);
      return await client.reconcilePayrollBenefits(body);
    },
  }),
) as [any, mutationAtomType];
