// TODO: delete this file after refactor
import { SegmentedControl, useMantineTheme } from "@mantine/core";

const OPTIONS = [
  {
    value: "medical",
    label: "Health",
  },
  {
    value: "dental",
    label: "Dental",
  },
  {
    value: "vision",
    label: "Vision",
  },
];

export const LineOfCoverageToggle = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const theme = useMantineTheme();
  return (
    <SegmentedControl
      data={OPTIONS}
      style={{ maxWidth: "15rem" }}
      radius="xl"
      value={value}
      onChange={onChange}
      color={theme.colors[theme.primaryColor][5]}
    />
  );
};
