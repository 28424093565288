import { Grid, Skeleton, Stack, Text } from "@mantine/core";
import { ComponentProps } from "react";

export const SectionField = ({
  title,
  children,
  isLoading,
  ...rest
}: ComponentProps<typeof Grid.Col> & { title: string; isLoading: boolean }) => (
  <Grid.Col span={{ base: 24, lg: 12 }} {...rest}>
    <Stack gap={0}>
      <Text size="sm" c="gray.7" fw="normal" lh="md">
        {title}
      </Text>
      {isLoading ? (
        <Skeleton height={24} />
      ) : (
        <Text size="sm" c="gray.8" fw="600" lh="md">
          {children}
        </Text>
      )}
    </Stack>
  </Grid.Col>
);
