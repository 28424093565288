import { StepFooter } from "@/components/StepFooter";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { configAtom, partnerConfigAtom, useNavigate } from "@/stores";
import { Dependent, EnrollmentPlanDependent, Member, Plan } from "@/types/api";
import {
  Anchor,
  Group,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { dependentsAtom } from "./API";

const OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Medical",
    value: "medical",
  },
  {
    label: "Dental",
    value: "dental",
  },
  {
    label: "Vision",
    value: "vision",
  },
];

const columns = [
  {
    header: "dependent",
    accessorFn: (row: Dependent) => `${row.first_name} ${row.last_name}`,
  },
  {
    header: "policy holder",
    accessorFn: (row: Dependent) => {
      row.member = row.member as Member;
      return `${row.member.first_name} ${row.member.last_name}`;
    },
  },
  {
    header: "relationship",
    accessorKey: "member_relationship",
  },
  {
    header: "plan name",
    accessorFn: (row: Dependent) =>
      row.enrolled_plans
        .map((enrolled_plan) => enrolled_plan.plan.plan_name)
        .join(", "),
  },
];

export const ClaspDependentMappingPage = () => {
  const config = useAtomValue(configAtom);
  const [selectedAgg, setSelectedAgg] = useState(OPTIONS[0].value);
  const dependents = useAtomValue(dependentsAtom);
  const navigate = useNavigate();
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const onSubmit = () => {
    navigate("contribution_strategy");
  };

  const filteredDependents = useMemo(
    () =>
      selectedAgg === "all"
        ? dependents
        : dependents.filter((dependent) => {
            dependent.enrolled_plans =
              dependent.enrolled_plans as EnrollmentPlanDependent[];
            return dependent.enrolled_plans.some(
              (enrolled_plan) =>
                (enrolled_plan.plan as Plan).line_of_coverage === selectedAgg,
            );
          }),
    [selectedAgg],
  );
  const table = useMantineReactTable({
    columns,
    data: filteredDependents,
    ...DEFAULT_TABLE_PROPS,
    positionGlobalFilter: "left",
    enableTopToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
  });

  return (
    <StepFooter index={2} total={8} onContinue={onSubmit}>
      <Stack gap="lg">
        <Title order={2}>Review Dependents</Title>
        <Text>
          We've matched enrollment information about your employee’s dependents
          between your carrier and {platformName}. Please review and confirm to
          ensure accuracy.{" "}
        </Text>
        <Group>
          <SegmentedControl
            size="md"
            radius="xl"
            data={OPTIONS}
            onChange={(value) => setSelectedAgg(value)}
            value={selectedAgg}
          />
        </Group>
        <Anchor target="_blank" href="https://withclasp.com" fs="italic">
          See errors in this section? Report an issue.
        </Anchor>
        <MantineReactTable table={table} />
      </Stack>
    </StepFooter>
  );
};
