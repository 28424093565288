import { PayFrequencyOptions } from "@/constants";
import { useApiClient } from "@/helpers/hooks";
import { WritableMember } from "@/types/api";
import { DeepPartial } from "@/types/utils";
import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  NumberInput,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import {
  EditEmployeeAdditionalInformation,
  EmployeeAdditionalInformationSchema,
  PayFrequency,
} from "./form";
import { useUpdateEmployeeMutation } from "./hooks";
import classes from "./styles.module.css";
import { useGetMember } from "../ViewEmployeeDetail/hooks";
import { AddressFieldGroup } from "../Form/AddressFieldGroup";

type Props = Pick<ModalProps, "opened" | "onClose"> & {
  employeeId: string;
};

export const EditEmployeeAdditionalInformationModal = ({
  opened,
  onClose,
  employeeId,
}: Props) => {
  const employeeMutation = useUpdateEmployeeMutation();
  const client = useApiClient();
  const form = useForm<DeepPartial<EditEmployeeAdditionalInformation>>({
    initialValues: {
      address: {},
    },
    validate: zodResolver(EmployeeAdditionalInformationSchema),
  });
  const { data, isLoading } = useGetMember(employeeId!, {
    refetchOnWindowFocus: false,
  });
  const { setValues } = form;

  useEffect(() => {
    if (!isLoading) {
      setValues({
        ...data,
        hours_worked: Number(data.hours_worked),
        pay_frequency: data.pay_frequency as PayFrequency,
      });
    }
  }, [data, isLoading, setValues]);

  const onSubmit = form.onSubmit(async (values) => {
    const { address, hours_worked, pay_frequency } = values;
    return employeeMutation.mutate(
      {
        client,
        memberId: employeeId,
        data: {
          address,
          hours_worked: hours_worked?.toString(),
          pay_frequency,
        } as Partial<WritableMember>,
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Employee Updated",
            message: `Employee ${data.first_name} ${data.last_name} has successfully been updated.`,
          });
          form.reset();
          onClose();
        },
      },
    );
  });

  return (
    <Modal.Root opened={opened} onClose={onClose} centered size="540">
      <Modal.Overlay />
      <Modal.Content px="md" pt="md" radius="md">
        <Modal.Body p={"md"}>
          <Stack gap={"xs"} mb={32}>
            <Modal.Title classNames={{ title: classes.modalTitle }}>
              Employee Additional Information
            </Modal.Title>
            <Text fw={400} c="gray.7">
              Please contact your benefits administrator to change other fields.
            </Text>
          </Stack>
          <form onSubmit={onSubmit}>
            <Stack pos="relative">
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: "sm", blur: 2 }}
              />
              <AddressFieldGroup form={form} basePath="address" />
              <Select
                label="Frequency"
                data={PayFrequencyOptions}
                {...form.getInputProps("pay_frequency")}
              />
              <NumberInput
                label="Hours Worked"
                placeholder="Hours Worked"
                hideControls
                suffix=" hrs"
                {...form.getInputProps("hours_worked")}
              />

              <Divider my={4} />
              <Group justify="flex-end">
                <Button
                  variant="primary"
                  type="submit"
                  loading={employeeMutation.isPending}
                >
                  Update
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
