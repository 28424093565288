import { clientAtom, configAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import { mutationAtomType } from "@/types/jotai";
import { Atom } from "jotai";
import { atomsWithMutation } from "jotai-tanstack-query";

export const [, postQualifyingLifeEventAtom] = atomsWithMutation((get) => ({
  mutationKey: ["postQualifyingLifeEvent"],
  mutationFn: async (body: any) => {
    const client = await get(clientAtom);
    return await client.postQualifyingLifeEvent(
      get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
      body,
    );
  },
})) as [any, mutationAtomType];

export const [, postQualifyingLifeEventDocumentAtom] = atomsWithMutation(
  (get) => ({
    mutationKey: ["postQualifyingLifeEventDocument"],
    mutationFn: async (body: any) => {
      const client = await get(clientAtom);
      return await client.postQualifyingLifeEventDocument(body);
    },
  }),
) as [any, mutationAtomType];
