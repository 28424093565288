import { authAtom } from "@/App";
import { getClaspApiUrl, isEmbedded } from "@/helpers";
import { entityIdAtom } from "@/stores";
import { Center, Loader } from "@mantine/core";
import axios, { AxiosResponse } from "axios";
import { useSetAtom } from "jotai";
import { Suspense } from "react";
import { Await, Navigate, defer, useLoaderData } from "react-router-dom";

export const LoginLoader = ({ request }) => {
  const searchParams = new URL(request.url).searchParams;
  const token = searchParams.get("token");
  const environment = searchParams.get("environment");
  let entityId;
  searchParams.delete("token");
  localStorage.clear();
  for (const [param, value] of searchParams.entries()) {
    localStorage.setItem(param, value);
    if (["employer_id", "member_id"].includes(param)) entityId = value;
  }
  const baseUrl = getClaspApiUrl(environment as any);
  const response = axios.post(
    `${baseUrl}/access/authenticate_otp`,
    { token },
    { withCredentials: true },
  );

  return defer({ response, entityId });
};

export const Login = () => {
  const { response, entityId } = useLoaderData() as {
    response: Promise<AxiosResponse>;
    entityId: string;
  };
  const setEntityId = useSetAtom(entityIdAtom);
  const setAuth = useSetAtom(authAtom);
  response.then((resp) => {
    setAuth(resp.data);
  });
  setEntityId(entityId);
  return (
    <Suspense
      fallback={
        !isEmbedded && (
          <Center h={"80dvh"}>
            <Loader color="gray" />
          </Center>
        )
      }
    >
      <Await resolve={response}>
        <Navigate to={`/${localStorage.getItem("component")}`} />
      </Await>
    </Suspense>
  );
};
