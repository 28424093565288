import { z } from "zod";

export const CoverageWaiverSchema = z.object({
  lineOfCoverage: z.string(),
  dependents: z.array(z.string()).optional(),
  member: z.string().optional(),
  reason: z.string(),
});
export const PrimaryCareSelectionSchema = z
  .object({
    plan: z.string().optional(),
    dependent: z.string().optional(),
    member: z.string().optional(),
    assigned_by_carrier: z.enum(["manual", "auto"]),
    primary_care_provider_name: z.string().optional(),
    primary_care_provider_id: z.string().optional(),
    is_existing_patient: z.boolean().default(false),
  })
  .refine((values) => {
    if (values.assigned_by_carrier === "manual") {
      return (
        values.primary_care_provider_name && values.primary_care_provider_id
      );
    }
    return !!values.assigned_by_carrier;
  });

export const AdditionalInfoFormSchema = z.object({
  primaryCareSelections: z.array(PrimaryCareSelectionSchema),
  coverageWaivers: z.array(CoverageWaiverSchema),
});

export type AdditionalInfoForm = z.infer<typeof AdditionalInfoFormSchema>;
