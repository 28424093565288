import { BiologicalSex, states } from "@/constants";
import { ClaspTheme, Links } from ".";

export interface ConnectionEvent {
  id: string;
  category: string;
  created_at: string;
}

export interface Connection {
  id: string;
  broker_url: string;
  employer: string | Employer;
  group_connections: GroupConnection[];
  connection_events: ConnectionEvent[];
  status: string;
}

export interface OpenEnrollmentDates {
  renewal_date: string;
  start_date: string;
  end_date: string;
}
export interface WritableEmployer {
  legal_name: string;
  trade_name: string;
  external_id: string;
}

export interface Employer extends WritableEmployer {
  id: string;
  upcoming_open_enrollment: OpenEnrollmentDates | null;
  enable_enrollment_approval: boolean;
  aca_tracking_enabled: boolean;
}

export interface Carrier {
  id: string;
  legal_name: string;
  trade_name: string;
  logo_url: string;
}

export interface Paginated<T> {
  results: T[];
  next: string;
  previous: string;
}
export interface CarrierMember {
  id: string;
  first_name: string;
  last_name: string;
  dob: string;
  ssn_last_four: string;
  email: string;
  enrolled_plans: EnrollmentPlan[];
  member?: string;
  reconciled_at: string;
  reconciled_by: string;
}

export type LineOfCoverage =
  | "all"
  | "medical"
  | "dental"
  | "vision"
  | "life"
  | "accidental_death"
  | "voluntary_life"
  | "voluntary_critical_illness"
  | "accident"
  | "short_term_disability"
  | "long_term_disability"
  | "hospital_indemnity";

export type PayFrequency = "weekly" | "biweekly" | "semimonthly" | "monthly";

export const Roles = ["standard", "admin"] as const;

export type Role = (typeof Roles)[number];

export const RoleMapping: Record<Role, string> = {
  standard: "Standard",
  admin: "Admin",
};

export interface WritableMember {
  first_name: string;
  last_name: string;
  phone_number: string;
  hire_date: string;
  email: string;
  dob: string;
  ssn: string;
  address: Address;
  biological_sex: BiologicalSex;
  termination_date?: string;
  hours_worked: string;
  pay_frequency?: PayFrequency;
  employer: Employer | string;
  role: Role;
  subclasses: string[];
  aca_digital_forms_enabled_at: string | null;
  tobacco_usage: boolean;
}
export interface Member extends Omit<WritableMember, "ssn"> {
  id: string;
  ssn_last_four: string;
  enrollments: string[] | Enrollment[];
  dependents: Dependent[] | string[];
  active_enrollment?: string | Enrollment;
}

export interface VoluntaryCoverageOption {
  guaranteed_issue: string;
  increment: string;
  maximum_coverage: string;
  minimum_coverage: string;
}

export interface VoluntaryCoverageOptions {
  child?: VoluntaryCoverageOption | null;
  member: VoluntaryCoverageOption | null;
  spouse?: VoluntaryCoverageOption | null;
}
export interface WaitingPeriod {
  days: number;
  policy: "first_of_month" | "end_of_waiting_period";
}
export interface Plan {
  id: string;
  termination_policy:
    | "termination_date"
    | "end_of_month"
    | "fifteenth_of_month";
  waiting_period: WaitingPeriod;
  plan_name: string;
  plan_type: string;
  plan_details: PlanDetails;
  plan_summary_url: string;
  line_of_coverage: LineOfCoverage;
  group: string | Group;
  effective_start: string;
  effective_end: string;
  member_count: number;
  dependent_count: number;
  is_low_cost: boolean | null;
  provides_minimum_value: boolean | null;
  provides_essential_coverage: boolean | null;
  dependent_coverage_excluded: boolean | null;
  spouse_coverage_excluded: boolean | null;
  bundle: PlanBundle | null;
  requires_primary_care_provider: boolean;
  voluntary_coverage_options: VoluntaryCoverageOptions | null;
  partner_dependents_eligible: boolean;
  child_dependents_eligible: boolean;
  has_tobacco_rates: boolean;
}

export interface PlanBundle {
  id: string;
  name: string;
  plans: Plan[];
}

export interface PlanDetails {
  [key: string]:
    | {
        label: string;
        info_lines: string[];
        tooltip: string;
      }
    | string;
}

export interface Group {
  id: string;
  connection: string | Connection;
  group_number: string | Connection;
  carrier: string | Carrier;
}

export interface EnrollmentPlan {
  id: string;
  plan: string | Plan;
  premium: string | null;
  employer_contribution: string | null;
  member_contribution: string | null;
  payroll_provider_external_id?: string | null;
  enrolled_dependents: EnrollmentPlanDependent[] | string[];
  effective_start: string;
  effective_end: string;
  primary_care_provider: PrimaryCareProvider | null;
  volume: string | null;
  per_pay_period_employer_contribution: string | null;
  per_pay_period_member_contribution: string | null;
}

const EnrollmentCategories = [
  "qle",
  "open_enrollment",
  "carrier_sync",
] as const;

export type EnrollmentCategory = (typeof EnrollmentCategories)[number];

export const EnrollmentCategoryMapping: Record<EnrollmentCategory, string> = {
  qle: "Qualifying Life Event",
  open_enrollment: "Open Enrollment",
  carrier_sync: "Carrier Sync",
};

export interface Enrollment {
  id: string;
  enrolled_plans: string[] | EnrollmentPlan[];
  member: string | Member;
  reason: string;
  category: EnrollmentCategory;
  effective_start: string;
  change_date: string;
  effective_end: string;
  status: EnrollmentStatus;
  created_at: string;
  coverage_waivers: CoverageWaiver[];
  qualifying_life_event_documents?: QualifyingLifeEventDocument[];
}

export interface EnrollmentPlanDependent {
  id: string;
  plan: string | Plan;
  dependent: string | Dependent;
  primary_care_provider: PrimaryCareProvider | null;
  volume: string | null;
}

export enum MemberRelationship {
  Spouse = "spouse",
  DomesticPartner = "domestic_partner",
  CivilUnion = "civil_union",
  ExSpouse = "ex_spouse",
  Child = "child",
  Grandchild = "grandchild",
  Stepchild = "step_child",
  Fosterchild = "foster_child",
  Adoptedchild = "adopted_child",
  LegalGuardianship = "legal_guardianship",
  CourtOrderedDependent = "court_ordered_dependent",
  Other = "other",
}

export interface Dependent {
  id: string;
  first_name: string;
  last_name: string;
  member: Member | string;
  member_relationship: MemberRelationship;
  ssn_last_four: string;
  dob: string;
  biological_sex: string;
  enrolled_plans: string[] | EnrollmentPlanDependent[];
  address: Address;
  tobacco_usage: boolean;
}

export type State = (typeof states)[number];
export interface Address {
  line1: string;
  line2?: string | null;
  city: string;
  state: State;
  zip_code: string;
}

export interface PayrollBenefit {
  id: string;
  member: string | Member;
  line_of_coverage: LineOfCoverage;
  description: string | null;
  member_contribution: string;
  employer_contribution: string;
  reconciled_at: string;
  reconciled_by: string;
}

export interface PayrollBenefitReconciliation {
  payroll_benefit?: string;
  create_new_benefit?: boolean;
  enrollment_plan: string;
}
export interface GroupConnection {
  id: string;
  carrier: string;
  status: string;
  broker_federal_ein: string;
  group_id: string;
  connection: string;
  initiated_at: string | null;
  completed_at: string | null;
}

export interface EmployerDocument {
  id: string;
  employer: string;
  file: string;
  name: string;
  document_type: "summary_of_benefits_and_coverage";
  created_at: string;
}

export interface QualifyingLifeEventDocument {
  id: string;
  enrollment: string;
  file: string;
  name: string;
  created_at: string;
}

interface PartnerFlags {
  enable_employee_management: boolean;
}
export interface PartnerConfig {
  id: string;
  name: string;
  logo_url: string;
  links: Links;
  theme: Partial<ClaspTheme> | null;
  flags: PartnerFlags;
}

interface ContributionDefinition {
  contribution_type: "member_cost" | "employer_cost" | "employer_percentage";
  contribution: string;
  monthly_min_threshold: string | null;
  monthly_max_threshold: string | null;
}

export type ContributionStrategy =
  | {
      member: ContributionDefinition;
      member_spouse: ContributionDefinition;
      member_child: ContributionDefinition;
      member_children: ContributionDefinition;
      member_family: ContributionDefinition;
      base_plan: null;
    }
  | {
      member: null;
      member_spouse: null;
      member_child: null;
      member_children: null;
      member_family: null;
      base_plan: string | Plan;
    };

export interface PlanQuote {
  plan: Plan;
  member_contribution: string;
  employer_contribution: string;
  per_pay_period_member_contribution: string;
  per_pay_period_employer_contribution: string;
}
export interface PrimaryCareProvider {
  id: string;
  full_name: string;
  is_existing_patient: boolean;
  provider_id_number: string;
}
export interface CoverageWaiver {
  line_of_coverage: string;
  reason: string;
  member: string | null | Member;
  dependent: string | null | Dependent;
}

export enum EmployerReport {
  MemberCensus = "member_census",
  EnrollmentCensus = "enrollment_census",
  PayrollDeduction = "payroll_deductions",
}

export enum EnrollmentStatus {
  NotStarted = "not_started",
  Pending = "pending",
  Approved = "approved",
  Synced = "synced",
  Failed = "failed",
}
