import { Dependent, Member } from "@/types/api";
import { Checkbox, CheckboxGroup, Group, Paper } from "@mantine/core";

interface Props {
  dependents: Dependent[];
  selectedDependents: string[];
  onSelectDependents?: (ids: string[]) => void;
  memberInfo: Member;
}
export const DependentsCard = ({
  memberInfo,
  dependents,
  selectedDependents,
  onSelectDependents,
}: Props) => {
  const { first_name, last_name } = memberInfo;
  return (
    <Paper p="lg" shadow="xs" withBorder>
      <Group gap={24}>
        <Checkbox
          checked={true}
          disabled
          radius={"xs"}
          label={`${first_name} ${last_name} (you)`}
        />
        <CheckboxGroup value={selectedDependents} onChange={onSelectDependents}>
          <Group gap={24}>
            {dependents.map((dep) => (
              <Checkbox
                radius={"xs"}
                value={dep.id}
                label={`${dep.first_name} ${dep.last_name}`}
              />
            ))}
          </Group>
        </CheckboxGroup>
      </Group>
    </Paper>
  );
};
