import { AddIcon } from "@/icons/Add";
import { carriersAtom, clientAtom, useNavigate } from "@/stores";
import { ICarrierForm } from "@/types";
import {
  Accordion,
  Anchor,
  Box,
  Button,
  Container,
  Group,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, useMemo, useState } from "react";
import { postCarriersFormAtom } from "./API";
import { CarrierButton } from "./CarrierButton";

import styles from "./styles.module.scss";

export const DEFAULT_CARRIER: ICarrierForm = {
  id: 1,
  group_id: "",
  broker_federal_ein: "",
  carrier: {
    id: "",
    legal_name: "",
    trade_name: "",
    logo_url: "",
  },
};

const FEIN_REGEX =
  /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;

export const validateFein = (fein: string): boolean => {
  return FEIN_REGEX.test(fein);
};

export const ClaspEmployerForm = () => {
  const navigate = useNavigate();
  // TODO: refactor this to use a form component instead
  const [carrierForms, setCarrierForms] = useState<ICarrierForm[]>([
    { ...DEFAULT_CARRIER },
  ]);

  const [employerFEIN, setEmployerFEIN] = useState("");
  const [openCarriers, setOpenCarriers] = useState([
    DEFAULT_CARRIER.id.toString(),
  ]);
  const [data, postCarriersForm] = useAtom(postCarriersFormAtom);
  const carriers = Array.from(useAtomValue(carriersAtom).values());

  const addCarrier = () => {
    const id = Math.random();
    setCarrierForms([
      ...carrierForms,
      {
        id,
        group_id: "",
        broker_federal_ein: "",
        carrier: DEFAULT_CARRIER.carrier,
      },
    ]);
    setOpenCarriers([...openCarriers, `${id}`]);
  };

  const removeCarrier = (id: number) => {
    setCarrierForms((prevCarriers) =>
      prevCarriers.filter((carrier) => carrier.id !== id),
    );
  };
  const editCarrier = (id: number, key: string, value: any) => {
    setCarrierForms((prevCarrierForms) => {
      return prevCarrierForms.map((carrierForm) =>
        carrierForm.id === id
          ? {
              ...carrierForm,
              [key]: value,
            }
          : carrierForm,
      );
    });
  };

  const onSave = (id: string) => {
    setOpenCarriers((prevCarriers) =>
      prevCarriers.filter((carrierId) => carrierId !== id),
    );
  };

  const connectionId = useAtomValue(clientAtom).connectionId;
  const onSubmit = async () => {
    await Promise.all(
      carrierForms.map((formSubmission) =>
        postCarriersForm([
          {
            carrier: formSubmission.carrier.id,
            groupId: formSubmission.group_id,
            brokerFederalEin: formSubmission.broker_federal_ein,
            connection: connectionId,
          },
        ]),
      ),
    );
    navigate("plan_summary_upload");
  };

  const valid = useMemo(() => {
    if (!validateFein(employerFEIN)) return false;
    for (const carrier of carrierForms) {
      if (!validateFein(carrier.broker_federal_ein)) return false;
      if (carrier.carrier.id === "") return false;
      if (carrier.group_id === "") return false;
    }
    return true;
  }, [carrierForms, employerFEIN]);

  return (
    // Without the min-width the container will be a smaller width when the accordian
    // is collapsed vs expanded when the SDK is embedded inside of a flex container.
    <Container size={600} style={{ minWidth: 600 }}>
      <Stack gap="lg">
        <Title order={2}>Confirm your details to get started</Title>
        <TextInput
          placeholder="Input FEIN here"
          value={employerFEIN}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmployerFEIN(e.target.value)
          }
          error={
            !employerFEIN || validateFein(employerFEIN)
              ? undefined
              : "Invalid FEIN"
          }
          description="Federal Employer ID Number (FEIN)"
          size="md"
        />
        <Title order={4}>Select your carriers</Title>
        <Text size="sm">
          Don't see your carrier?{" "}
          <Anchor target="_blank" href="https://withclasp.com/requestCarrier">
            Request a carrier connection
          </Anchor>
          .
        </Text>
        <Box className={styles.wrapper}>
          <Accordion
            multiple={true}
            value={openCarriers}
            onChange={setOpenCarriers}
            className={styles.accordion}
          >
            {carrierForms.map(({ id, carrier, broker_federal_ein }, index) => (
              <Accordion.Item key={id} value={id.toString()}>
                <Accordion.Control>
                  {carrier.legal_name || `Carrier ${index + 1}`}
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack>
                    <SimpleGrid cols={4} spacing="lg">
                      {carriers.map((c) => (
                        <CarrierButton
                          key={c.id}
                          carrier={c}
                          selectedCarrier={carrier}
                          onSelect={(selectedCarrier) =>
                            editCarrier(id, "carrier", selectedCarrier)
                          }
                        />
                      ))}
                    </SimpleGrid>
                    <TextInput
                      placeholder="Input Group ID here"
                      onChange={(e) =>
                        editCarrier(id, "group_id", e.currentTarget.value)
                      }
                      description="Group ID"
                      size="md"
                    />
                    <TextInput
                      placeholder="Input Broker FEIN here"
                      onChange={(e) =>
                        editCarrier(
                          id,
                          "broker_federal_ein",
                          e.currentTarget.value,
                        )
                      }
                      error={
                        !broker_federal_ein || validateFein(broker_federal_ein)
                          ? undefined
                          : "Invalid Broker FEIN"
                      }
                      description="Broker FEIN"
                      size="md"
                    />
                    <Group>
                      <Button
                        variant="outline"
                        onClick={() => removeCarrier(id)}
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => onSave(id.toString())}>
                        Save
                      </Button>
                    </Group>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
          <Button
            variant="transparent"
            leftSection={<AddIcon />}
            onClick={addCarrier}
            size="lg"
            fullWidth
          >
            Add a carrier connection
          </Button>
        </Box>
        <Group justify="flex-end">
          <Button
            onClick={onSubmit}
            disabled={!valid}
            loading={data?.isPending}
          >
            Connect benefits
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};
