import { CarrierImage } from "@/components";
import { PartnerRelationshipTypes, PayFrequencyOptions } from "@/constants";
import { snakeCaseToTitleCase } from "@/helpers/string";
import {
  Group as CarrierGroup,
  LineOfCoverage,
  Plan,
  PlanQuote,
} from "@/types/api";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Group,
  List,
  ListItem,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconHelpCircle } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import {
  getEligiblePlansAtom,
  getEnrollmentAtom,
  memberDependentsAtom,
  memberInfoAtom,
} from "../../API";
import {
  PlanQuoteWithDependents,
  useEnrollContext,
} from "../../EnrollProvider";
import { DependentsCard } from "../DependentsCard";

export const BasicSupplementalBenefitCard = ({
  initialPlanQuote,
  onSubmit,
  selected,
  planQuoteMap,
}: {
  initialPlanQuote: PlanQuote;
  onSubmit?: (
    lineOfCoverage: string,
    selectedQuote: PlanQuoteWithDependents,
  ) => void;
  selected: Map<LineOfCoverage, PlanQuoteWithDependents>;
  planQuoteMap?: Map<string, PlanQuote>;
}) => {
  const { dispatch } = useEnrollContext();
  const theme = useMantineTheme();
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const dependents = useAtomValue(memberDependentsAtom);
  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const [planQuote, setPlanQuote] = useState(initialPlanQuote);

  const plan = planQuote?.plan;
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const selectedDependents =
    selected.get(planQuote?.plan?.line_of_coverage)?.dependents || [];
  const filteredDependents = dependents.filter((dependent) => {
    const dependentType = PartnerRelationshipTypes.includes(
      dependent.member_relationship,
    )
      ? "partner"
      : "child";

    if (dependentType === "partner" && !plan.partner_dependents_eligible) {
      return false;
    } else if (dependentType === "child" && !plan.child_dependents_eligible) {
      return false;
    }
    return true;
  });

  let enableSelection = true;
  let enableWaive = true;
  const relatedPlanIds = [];
  if (plan?.bundle && typeof plan?.bundle === "object") {
    const relatedPlans = plan.bundle.plans;
    // If another plan in the bundle has been waived the employee
    // must waive this plan as well. If a bundled plan has been
    // chosen already then the employee must select this plan as well
    relatedPlans.forEach((plan) => {
      relatedPlanIds.push(plan.id);
      const selectedPlan = selected.get(plan.line_of_coverage)?.quote;
      if (plan.id === plan.id || selectedPlan === undefined) return;

      if (selectedPlan === null) {
        // Waived a line of coverage included in the bundle so the employee
        // must waive this plan as well
        enableSelection = false;
      } else if (selectedPlan.plan.id === plan.id) {
        // Bundled plan has been selected for another LOC so the employee
        // must enroll in this plan as well.
        enableWaive = false;
      }
    });
  }
  const onSubmitHelper = (selection: "waive" | "enroll") => {
    onSubmit(plan.line_of_coverage, {
      quote: selection === "waive" ? null : planQuote,
      dependents: selectedDependents,
    });

    relatedPlanIds.forEach((planId) => {
      const planQuote = planQuoteMap.get(planId);
      if (planQuote) {
        onSubmit(planQuote.plan.line_of_coverage, {
          quote: selection === "waive" ? null : planQuote,
          dependents: selectedDependents,
        });
      }
    });
  };

  const onSelectDependents = (dependents) => {
    const existingSelectedQuote = selected.get(plan.line_of_coverage)?.quote;
    dispatch({
      type: "set-supplemental",
      payload: new Map(
        selected.set(plan.line_of_coverage, {
          quote: existingSelectedQuote,
          dependents,
        }),
      ),
    });
    fetchEligiblePlans([
      {
        body: { dependents },
        enrollmentId: enrollment.id,
      },
    ]).then((quotes) => {
      setPlanQuote(quotes.find((quote) => quote.plan.id === plan.id));
    });
  };

  const header = (
    <>
      <Text c="gray.7" fw="600" size="md">
        {plan.plan_name}
      </Text>
      {plan.plan_summary_url && (
        <Anchor
          c="gray.7"
          fw="400"
          href={plan.plan_summary_url}
          target="_blank"
          size="md"
          underline="always"
        >
          View Plan Summary
        </Anchor>
      )}
    </>
  );

  return (
    <Stack>
      {filteredDependents.length > 0 && (
        <>
          <Title size="h5">Select plan participants</Title>
          <DependentsCard
            memberInfo={memberInfo}
            dependents={filteredDependents}
            selectedDependents={selectedDependents}
            onSelectDependents={onSubmit && onSelectDependents}
          />
        </>
      )}
      <Group justify="space-between">
        <Group gap="lg">
          <CarrierImage
            h={75}
            w={75}
            carrierId={(plan.group as CarrierGroup).carrier as string}
          />
          {header}
        </Group>

        <Box>
          <Title size="h2">
            <Group gap="xs">
              ${planQuote.per_pay_period_member_contribution}
              <Tooltip
                label={
                  <Stack gap="xs">
                    <Group gap="xs">
                      <Text size="sm" fw="bold">
                        Pay Period:
                      </Text>

                      <Text size="sm">
                        {
                          PayFrequencyOptions.find(
                            ({ value }) => value === memberInfo.pay_frequency,
                          )?.label
                        }
                      </Text>
                    </Group>

                    <Group gap="xs">
                      <Text size="sm" fw="bold">
                        Covered:
                      </Text>

                      <Text size="sm">
                        {[
                          "You",
                          ...dependents
                            .filter(({ id }) => selectedDependents.includes(id))
                            .map(
                              ({ first_name, last_name }) =>
                                `${first_name} ${last_name}`,
                            ),
                        ].join(", ")}
                      </Text>
                    </Group>
                  </Stack>
                }
                withArrow
              >
                <IconHelpCircle
                  stroke={1.5}
                  size={25}
                  color={theme.colors[theme.primaryColor][5]}
                />
              </Tooltip>
            </Group>
            <Text>Per pay period</Text>
          </Title>
        </Box>
      </Group>

      <PlanDetails plan={planQuote.plan} />
      {onSubmit && (
        <Group justify="flex-end">
          <Tooltip
            label={`Another plan from the bundle "${plan.bundle?.name}" has been selected which requires this plan to be enrolled in as well.`}
            disabled={enableWaive}
          >
            <Button variant="outline" onClick={() => onSubmitHelper("waive")}>
              Waive
            </Button>
          </Tooltip>
          <Tooltip
            label={`The other plan from the bundle "${plan.bundle?.name}" has been waived which requires this plan to also be waived.`}
            disabled={enableSelection}
          >
            <Button onClick={() => onSubmitHelper("enroll")}>Enroll</Button>
          </Tooltip>
        </Group>
      )}
    </Stack>
  );
};

const PlanDetails = ({ plan }: { plan: Plan }) => {
  const theme = useMantineTheme();

  if (["life", "accidental_death"].includes(plan.line_of_coverage)) {
    return (
      <Group wrap="nowrap" align="stretch" gap="xs" pl={100}>
        <List listStyleType="none">
          {plan.plan_details &&
            Object.entries(plan.plan_details).map(([key, value]) => (
              <div>
                {typeof value === "string" ? (
                  <ListItem>
                    <Text size="sm">
                      <b>{snakeCaseToTitleCase(key)}</b>:
                    </Text>
                  </ListItem>
                ) : (
                  <ListItem>
                    <Text size="sm">
                      <b>{value.label}</b>:
                    </Text>
                  </ListItem>
                )}
              </div>
            ))}
        </List>
        <List listStyleType="none">
          {plan.plan_details &&
            Object.entries(plan.plan_details).map(([_, value]) => (
              <div>
                {typeof value === "string" ? (
                  <ListItem>
                    <Text size="sm">{value}</Text>
                  </ListItem>
                ) : (
                  <ListItem>
                    <Text size="sm">{value.info_lines.join("; ")}</Text>
                  </ListItem>
                )}
              </div>
            ))}
        </List>
      </Group>
    );
  }
  return (
    <Alert>
      <SimpleGrid cols={{ xs: 2, md: 4, lg: 4, xl: 4 }}>
        {plan.plan_details &&
          Object.entries(plan.plan_details).map(
            ([key, value], index, array) => (
              <div>
                {typeof value === "string" ? (
                  <Text fw={600} key={key}>
                    <Center>{snakeCaseToTitleCase(key)}</Center>
                    <br />
                    <Center>
                      <Text>{value}</Text>
                    </Center>
                  </Text>
                ) : (
                  <Text fw={600} key={key} pl="md">
                    <Center>
                      {value.label}
                      <Space w="4" />
                      {value.tooltip && (
                        <Tooltip
                          label={value.tooltip}
                          position="top"
                          withinPortal
                          withArrow
                        >
                          <IconHelpCircle
                            stroke={1.5}
                            size={17.5}
                            color={theme.colors[theme.primaryColor][5]}
                          />
                        </Tooltip>
                      )}
                    </Center>
                    <Space h="xs" />
                    <Center>
                      <Stack gap={2} align="center">
                        {value.info_lines.map((line) => (
                          <Text key={line}>{line}</Text>
                        ))}
                      </Stack>
                    </Center>
                  </Text>
                )}
                {index !== array.length - 1 && (
                  <Divider orientation="vertical" />
                )}
              </div>
            ),
          )}
      </SimpleGrid>
    </Alert>
  );
};
