import { UsMediumDateFormatter } from "@/helpers/string";
import { Employer } from "@/types/api";
import { Button, Stack, Text, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconArrowRight } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { getEnrollmentAtom, memberInfoAtom } from "../API";
import { useEnrollContext } from "../EnrollProvider";
import { ACAConsentForm } from "./ACAConsentForm";
import { setEmployeeAcaDigitalFormsConsentAtom } from "./API";

export const TermsAndConditions = ({ onNext }: { onNext: () => void }) => {
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const member = useAtomValue(memberInfoAtom[0]);
  const employer = member.employer as Employer;
  const {
    state: { acaDigitalFormsEnabled },
  } = useEnrollContext();

  const [{ isPending }, setEmployeeAcaDigitalFormConsent] = useAtom(
    setEmployeeAcaDigitalFormsConsentAtom,
  );

  const handleClickNext = async () => {
    if (employer.aca_tracking_enabled) {
      await setEmployeeAcaDigitalFormConsent([acaDigitalFormsEnabled]);

      notifications.show({
        title: "ACA consent updated",
        message: `ACA consent has successfully been updated.`,
      });
    }

    onNext();
  };

  return (
    <Stack gap={24} h="calc(100vh - 64px)" justify="center" align="center">
      <Title order={2}>It's time to enroll in health benefits!</Title>
      <Text c="gray.7">
        In the following steps, you'll be able to select your upcoming set of
        benefits that are offered by{" "}
        <Text component="span" fw="bold">
          {employer.trade_name ?? employer.legal_name}
        </Text>
        .
      </Text>
      <Text c="gray.7">
        Once approved, these benefits will take place:{" "}
        <Text fw="bold" component="span">
          {UsMediumDateFormatter.format(Date.parse(enrollment.change_date))}
        </Text>
      </Text>

      {employer.aca_tracking_enabled && <ACAConsentForm />}

      <Button
        loading={isPending}
        onClick={handleClickNext}
        rightSection={<IconArrowRight size={16} />}
      >
        Select your benefits
      </Button>
    </Stack>
  );
};
