import { useConfirmMemberInfoFormContext } from "./form";
import {
  Paper,
  Select,
  Stack,
  TextInput,
  Title,
  Radio,
  RadioGroup,
  Group,
} from "@mantine/core";
import { BiologicalSexOptions } from "@/constants";

type Props = {
  biologicalSexVisible: boolean;
  ssnVisible: boolean;
  tobaccoUsageVisible: boolean;
};

export const ConfirmMemberInfoForm = ({
  biologicalSexVisible,
  ssnVisible,
  tobaccoUsageVisible,
}: Props) => {
  const form = useConfirmMemberInfoFormContext();

  return biologicalSexVisible || ssnVisible || tobaccoUsageVisible ? (
    <>
      <Title size="h3">2. Confirm your details</Title>
      <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "24rem" }}>
        <form>
          <Stack>
            {ssnVisible && (
              <TextInput
                label="Social Security Number"
                placeholder="Social Security Number"
                type="number"
                min="100000000"
                max="999999999"
                {...form.getInputProps("ssn")}
              />
            )}
            {biologicalSexVisible && (
              <Select
                label="Sex"
                placeholder="Sex"
                data={BiologicalSexOptions}
                {...form.getInputProps("biological_sex")}
              />
            )}
            {tobaccoUsageVisible && (
              <RadioGroup
                label="Tobacco Usage"
                {...form.getInputProps("tobacco_usage")}
              >
                <Group>
                  <Radio value="true" label="Yes" />
                  <Radio value="false" label="No" />
                </Group>
              </RadioGroup>
            )}
          </Stack>
        </form>
      </Paper>
    </>
  ) : null;
};
