import { useAtomValue } from "jotai";
import { visionMembersAtom, visionPlansAtom } from "./API";
import { ReviewPage } from "./ReviewPage";

export const VisionReviewPage = () => {
  const medical_plans = useAtomValue(visionPlansAtom);
  const enrollees = useAtomValue(visionMembersAtom);
  return (
    <ReviewPage
      medical_plans={medical_plans}
      enrollees={enrollees}
      reviewTitle={"Review: Vision Benefits Summary"}
      reviewDescription={
        "Dive into your insurance plan details. Everything you see here is provided directly by your insurance carriers."
      }
      plansTableTitle={"Vision plan details"}
      enrolleeTableTitle={"Vision enrollment summary"}
      nextState={"final_review"}
      stepIndex={7}
    />
  );
};
