import { Button, Group, Text } from "@mantine/core";
import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

interface Props extends PropsWithChildren {
  index: number;
  total: number;
  disabled?: boolean;
  onSaveProgress?: () => void;
  onContinue: () => void;
}

export const StepFooter = ({
  children,
  index,
  total,
  disabled,
  onSaveProgress,
  onContinue,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        <Text fs="italic">
          Step {index} of {total}
        </Text>
        <Group>
          <Button variant="outline" onClick={onSaveProgress}>
            Save Progress
          </Button>
          <Button onClick={onContinue} disabled={disabled}>
            Save and Continue
          </Button>
        </Group>
      </div>
    </div>
  );
};
