import { useNavigate } from "@/stores";
import { useAtom } from "jotai";
import { approvePayrollAtom } from "./API";
import checkIcon from "@/assets/icons/check.svg";
import {
  Button,
  Container,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";

const dataAccess = [
  "Company legal name",
  "Company tax details",
  "Company documents",
  "Company benefits",
  "Employee details",
  "Payroll details",
];

export const ClaspPayrollPage = () => {
  const navigate = useNavigate();
  const [data, approvePayroll] = useAtom(approvePayrollAtom);
  const onApprove = async () => {
    await approvePayroll([undefined]);
    // check if the call failed before navigate
    navigate("form");
  };
  return (
    <Container size={600}>
      <Stack gap="xl">
        <Stack gap="xs">
          <Title order={2}>Authorize access to payroll</Title>
          <Text size="sm">
            Connect your payroll to enable seamless, automated benefit
            deductions. With your permission, we'll access the following
            information:
          </Text>
        </Stack>
        <SimpleGrid cols={2}>
          {dataAccess.map((item, rowIndex) => (
            <Group key={rowIndex} gap="xs">
              <img height={17} src={checkIcon} />
              <Text size="md">{item}</Text>
            </Group>
          ))}
        </SimpleGrid>
        <Group>
          <Button variant="outline">Cancel</Button>
          <Button
            variant="filled"
            loading={data?.isPending}
            onClick={onApprove}
          >
            Connect payroll
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};
