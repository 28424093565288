import { StepFooter } from "@/components/StepFooter";
import { configAtom, useNavigate } from "@/stores";
import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import classes from "./styles.module.scss";

import { useAtom, useAtomValue } from "jotai";
import { allPlansAtom } from "../Review/API";
import { postContributionStrategyFormAtom } from "./API";
import {
  GlobalStrategyFormValues,
  GlobalStrategyFormValuesSchema,
  PerPlanFormValues,
  PerPlanFormValuesSchema,
  initialPlanContributionStrategy,
} from "./Forms/FormContext";
import { GlobalContributionStrategyForm } from "./Forms/GlobalContributionStrategyForm";
import { PerPlanContributionStrategyForm } from "./Forms/PerPlanContributionStrategyForm";

export const ClaspContributionStrategyPage = () => {
  const navigate = useNavigate();
  const apiClient = useAtomValue(configAtom).apiClient;
  const [_, postContributionStrategyForm] = useAtom(
    postContributionStrategyFormAtom,
  );
  const planSummaries = useAtomValue(allPlansAtom);
  const onSubmit = async () => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      if (contributionStrategyType === "per_plan") {
        await perPlanContributionStrategyForm.values.planContributionStrategies.forEach(
          async (strategy) =>
            await postContributionStrategyForm([
              {
                planId: strategy.planId,
                body: strategy.contributionStrategy,
              },
            ]),
        );
      } else {
        planSummaries.forEach(async (plan) => {
          await postContributionStrategyForm([
            {
              planId: plan.id,
              body: globalContributionStrategyForm.values[
                plan.line_of_coverage
              ],
            },
          ]);
        });
      }
      navigate("benefit_mapping");
    }
  };

  const [contributionStrategyType, setContributionStrategyType] = useState<
    "global" | "per_plan"
  >("global");
  const globalContributionStrategyForm = useForm<GlobalStrategyFormValues>({
    initialValues: {
      medical: initialPlanContributionStrategy,
      dental: initialPlanContributionStrategy,
      vision: initialPlanContributionStrategy,
    },
    // TODO: Re-enable validation in demo friendly way
    ...(apiClient == "production" && {
      validate: zodResolver(GlobalStrategyFormValuesSchema),
    }),
  });
  const perPlanContributionStrategyForm = useForm<PerPlanFormValues>({
    initialValues: {
      planContributionStrategies: [],
    },
    // TODO: Re-enable validation in demo friendly way
    ...(apiClient == "production" && {
      validate: zodResolver(PerPlanFormValuesSchema),
    }),
  });
  const form =
    contributionStrategyType === "global"
      ? globalContributionStrategyForm
      : perPlanContributionStrategyForm;
  return (
    <StepFooter index={3} total={8} onContinue={onSubmit}>
      <Stack>
        <Title size="h2">Plan contribution strategy</Title>
        <Text size="sm">
          Configure your benefits contribution strategy to guarantee accurate
          deductions, tailored to your specific plan structure details.
        </Text>
        <Group>
          <Paper
            p="lg"
            shadow="xs"
            withBorder
            style={{ maxWidth: "24rem" }}
            onClick={() => setContributionStrategyType("global")}
            className={
              contributionStrategyType === "global"
                ? classes.activeContributionStrategy
                : null
            }
          >
            <Text fw={600}>
              We have the same contribution strategy for all plans
            </Text>
          </Paper>
          <Paper
            p="lg"
            shadow="xs"
            withBorder
            style={{ maxWidth: "24rem" }}
            onClick={() => setContributionStrategyType("per_plan")}
            className={
              contributionStrategyType === "per_plan"
                ? classes.activeContributionStrategy
                : null
            }
          >
            <Text fw={600}>
              We have a different contribution strategy per plan
            </Text>
          </Paper>
        </Group>
        <Title size="h3">Contribution Details</Title>
        {contributionStrategyType === "global" ? (
          <GlobalContributionStrategyForm form={form} />
        ) : (
          <PerPlanContributionStrategyForm form={form} plans={planSummaries} />
        )}
      </Stack>
    </StepFooter>
  );
};
