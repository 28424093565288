import TableBottomToolbar from "@/components/TableBottomToolbar";
import { IconChevronsSort } from "@/icons/IconChevronsSort";
import { Paginated } from "@/types/api";
import { Text, useMantineTheme } from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  TablerIconsProps,
} from "@tabler/icons-react";
import { UseQueryResult } from "@tanstack/react-query";
import {
  MRT_Row,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import tableClasses from "./Table.module.css";

type TableOptions<TData extends Record<string, any>> = Omit<
  MRT_TableOptions<TData>,
  "data"
> & {
  page?: string;
  setPage: React.Dispatch<React.SetStateAction<string | undefined>>;
  onClickRow?: (row: MRT_Row<TData>) => void | Promise<void>;
  query: UseQueryResult<Paginated<TData>, Error>;
};

export const getDefaultMRTOptions = <
  TData extends Record<string, any>,
>(): Partial<MRT_TableOptions<TData>> => ({
  enableBottomToolbar: true,
  enableColumnActions: false,
  enableSorting: false,
  mantinePaperProps: {
    shadow: undefined,
    style: {
      border: 0,
    },
  },
  mantineTableBodyCellProps: {
    style: {
      maxWidth: "1234",
      minWidth: "1234",
    },
  },
  initialState: { density: "xs" },
  enablePagination: false,
  enableTopToolbar: false,
  mantineTableProps: {
    striped: true,
  },
});

export const getDefaultClaspOptions = <
  TData extends Record<string, any>,
>() => ({
  ...getDefaultMRTOptions<TData>(),

  mantineTableProps: {
    highlightOnHover: true,
  },
  mantineTableHeadCellProps: () => ({
    className: tableClasses.th,
  }),
  mantineTableBodyProps: () => ({
    className: tableClasses.tbody,
  }),
  mantineTableBodyCellProps: () => ({
    className: tableClasses.td,
  }),
  mantineColumnActionsButtonProps: () => ({
    variant: "transparent",
  }),
});

export const useClaspTable = <TData extends Record<string, any>>({
  query,
  setPage,
  onClickRow,
  ...rest
}: TableOptions<TData>) => {
  const { colors } = useMantineTheme();

  const table = useMantineReactTable({
    renderEmptyRowsFallback: (table) => (
      <Text
        style={{
          color: "gray",
          fontStyle: "italic",
          paddingBottom: "2rem",
          paddingTop: "2rem",
          textAlign: "center",
          width: "100%",
        }}
      >
        No records to display
      </Text>
    ),
    data: query.data?.results || [],
    ...getDefaultClaspOptions<TData>(),
    renderBottomToolbar: <TableBottomToolbar data={query} setPage={setPage} />,
    enableSorting: true,

    icons: {
      IconArrowsSort: (props: TablerIconsProps) => (
        <IconChevronsSort
          {...props}
          size={20}
          strokeWidth={1.5}
          color={colors.gray[8]}
        />
      ),
      IconSortAscending: (props: TablerIconsProps) => (
        <IconChevronUp
          {...props}
          size={14}
          strokeWidth={3}
          color={colors.gray[8]}
        />
      ),
      IconSortDescending: (props: TablerIconsProps) => (
        <IconChevronDown
          {...props}
          size={14}
          strokeWidth={3}
          color={colors.gray[8]}
        />
      ),
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        onClickRow?.(row);
      },
      style: {
        cursor: onClickRow ? "pointer" : "default",
      },
    }),
    ...rest,
  });

  return table;
};
