import { APIClient } from "@/stores/APIClient";
import { Enrollment, EnrollmentStatus, Paginated } from "@/types/api";
import {
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";

type ListEnrollmentParams = {
  cursor?: string;
  status?: EnrollmentStatus[];
  expand?: string[];
  client: APIClient;
};
export const useListEnrollments = ({
  client,
  status,
  cursor,
  expand,
}: ListEnrollmentParams): UseQueryResult<Paginated<Enrollment>> =>
  useQuery({
    queryKey: ["enrollments", cursor, status, expand],
    queryFn: () => client.listEnrollments({ cursor, status, expand }),
    placeholderData: keepPreviousData,
  });
