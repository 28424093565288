import { ClaspRouter, ClaspWrapper } from "@/components";
import { StateManagementWrapper } from "@/components/ClaspWrapper/StateManagementWrapper";
import type { ClaspEmployeeConfig, ClaspViewProps } from "@/types";
import { EmployeeHome } from "./Pages/EmployeeHome";
import { EmployeeEnroll } from "./Pages/Enroll";
import { EmployeeQLE } from "./Pages/QLE";
import EnrollmentDetails from "./Pages/EnrollmentDetails";

interface Props extends ClaspViewProps {
  config: ClaspEmployeeConfig;
}

export const ClaspEmployee = ({
  theme,
  config,
  onChangeState,
  claspState,
}: Props) => {
  return (
    <StateManagementWrapper>
      <ClaspWrapper theme={theme} config={config}>
        <ClaspRouter
          onChangeState={onChangeState}
          claspState={claspState}
          routes={{
            employee_home: <EmployeeHome />,
            qle: <EmployeeQLE />,
            enroll: <EmployeeEnroll />,
            enrollment_details: <EnrollmentDetails />,
          }}
        />
      </ClaspWrapper>
    </StateManagementWrapper>
  );
};
