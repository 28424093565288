import { UsDateFormatter, snakeCaseToTitleCase } from "@/helpers/string";
import { useClaspTable } from "@/helpers/useClaspTable";
import {
  Dependent,
  EnrollmentPlan,
  EnrollmentPlanDependent,
} from "@/types/api";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";

export const DependentsTable = ({
  enrollmentPlan,
}: {
  enrollmentPlan: EnrollmentPlan;
}) => {
  const [page, setPage] = useState<string | undefined>();
  const dependents =
    enrollmentPlan.enrolled_dependents as EnrollmentPlanDependent[];
  const query = useQuery({
    queryKey: ["enrollmentPlan", enrollmentPlan.id, "enrolled_dependents"],
    queryFn: () =>
      Promise.resolve({
        next: "",
        previous: "",
        results: dependents.slice((page ? Number(page) : 0) * 25, 25),
      }),
  });

  const columns = useMemo<MRT_ColumnDef<EnrollmentPlanDependent>[]>(
    () => [
      {
        header: "Employee Name",
        accessorFn: (originalRow) => {
          const { first_name, last_name } = originalRow.dependent as Dependent;

          return `${first_name} ${last_name}`;
        },
      },

      {
        header: "Relationship",
        accessorFn: (originalRow) => {
          const { member_relationship } = originalRow.dependent as Dependent;

          return member_relationship;
        },
        Cell: ({ cell }) => snakeCaseToTitleCase(cell.getValue<string>()),
      },
      {
        header: "Date of Birth",
        accessorFn: (originalRow) => {
          const { dob } = originalRow.dependent as Dependent;

          return dob;
        },
        Cell: ({ cell }) =>
          UsDateFormatter.format(dayjs(cell.getValue<string>()).toDate()),
      },
    ],
    [],
  );

  const table = useClaspTable({
    columns,
    query,
    page,
    setPage,
  });

  return <MantineReactTable table={table} />;
};
