import { CarrierImage } from "@/components/CarrierImage";
import { StepFooter } from "@/components/StepFooter";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { formatWaitingPeriod } from "@/helpers/string";
import { useNavigate } from "@/stores";
import { Center, Stack, Text, Title } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import {
  allMembersAtom,
  dentalPlansAtom,
  medicalPlansAtom,
  postSubmitConnectionReviewAtom,
  visionPlansAtom,
} from "./API";

const plans_columns = [
  {
    header: "",
    accessorKey: "group.carrier.id",
    Cell: ({ renderedCellValue }) => (
      <Center>
        <CarrierImage carrierId={`${renderedCellValue}`} />
      </Center>
    ),
  },
  {
    header: "plan type",
    accessorKey: "group.carrier.trade_name",
  },
  {
    header: "plan name",
    accessorKey: "plan_name",
  },
  {
    header: "group number",
    accessorKey: "group.group_number",
  },
  {
    header: "enrollees",
    accessorKey: "enrollees",
    Cell: ({ row }) => (
      <>
        {row.original.member_count} employees
        <br />
        {row.original.dependent_count} dependents
      </>
    ),
  },
  {
    header: "Additional Details",
    accessorKey: "details",
    width: "2fr",
    Cell: ({ row }) => (
      <Stack gap={1}>
        <Text size="sm">
          <b>Termination Policy:</b> <br />
          {row.original.termination_policy}
        </Text>
        <Text size="sm">
          <b>Waiting Period:</b> <br />
          {formatWaitingPeriod(row.original.waiting_period)}
        </Text>
        <Text size="sm">
          <b>Effective Dates:</b> <br />
          {row.original.effective_start} - {row.original.effective_end}
        </Text>
      </Stack>
    ),
  },
];

export const enrollment_columns = [
  {
    header: "subscriber",
    accessorKey: "subscriber",
    Cell: ({ row }) => (
      <div>
        {row.original.first_name} {row.original.last_name}
      </div>
    ),
  },
  {
    header: "effective start",
    accessorKey: "effective_start",
    Cell: ({ row }) => (
      <div>{row.original.enrollments[0]?.effective_start}</div>
    ),
  },
  {
    header: "total premium",
    accessorKey: "total_premium",
    Cell: ({ row }) => (
      <div>{row.original.enrollments[0]?.enrolled_plans[0]?.premium}</div>
    ),
  },
  {
    header: "employer pay",
    accessorKey: "employer_pay",
    // TODO: update value here
    Cell: ({ row }) => (
      <div>
        {row.original.enrollments[0]?.enrolled_plans[0]?.employer_contribution}
      </div>
    ),
  },
  {
    header: "employee pay",
    accessorKey: "employee_pay",
    // TODO: update value here
    Cell: ({ row }) => (
      <div>
        {row.original.enrollments[0]?.enrolled_plans[0]?.member_contribution}
      </div>
    ),
  },
  {
    header: "plan",
    accessorKey: "plan",
    Cell: ({ row }) => (
      <div>
        {row.original.enrollments[0]?.enrolled_plans[0]?.plan.plan_name}
      </div>
    ),
  },
];

export const FinalReviewPage = () => {
  const navigate = useNavigate();
  const [_, postSubmitConnectionReview] = useAtom(
    postSubmitConnectionReviewAtom,
  );
  const onSubmit = async () => {
    await postSubmitConnectionReview([undefined]);
    navigate("admin");
  };
  const medicalPlans = useAtomValue(medicalPlansAtom);
  const visionPlans = useAtomValue(visionPlansAtom);
  const dentalPlans = useAtomValue(dentalPlansAtom);
  const enrollees = useAtomValue(allMembersAtom);

  const medicalPlansTable = useMantineReactTable({
    columns: plans_columns,
    data: medicalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const visionPlansTable = useMantineReactTable({
    columns: plans_columns,
    data: visionPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const dentalPlansTable = useMantineReactTable({
    columns: plans_columns,
    data: dentalPlans,
    ...DEFAULT_TABLE_PROPS,
  });

  const enrollmentsTable = useMantineReactTable({
    columns: enrollment_columns,
    data: useMemo(
      () => enrollees.filter((member) => member.enrollments.length > 0),
      [],
    ),
    ...DEFAULT_TABLE_PROPS,
  });

  return (
    <StepFooter index={8} total={8} onContinue={onSubmit}>
      <Stack>
        <Title order={2}>Final Benefits Overview</Title>
        <Text size="sm">
          Before we move forward, take a moment to review all your plans. Once
          confirmed, they will be seamlessly synced with Neptune.
        </Text>
        <Title order={3}>Medical</Title>
        <MantineReactTable table={medicalPlansTable} />
        <Title order={3}>Dental</Title>
        <MantineReactTable table={dentalPlansTable} />
        <Title order={3}>Vision</Title>
        <MantineReactTable table={visionPlansTable} />
        <Title order={3}>Enrollment Summary</Title>
        <MantineReactTable table={enrollmentsTable} />
      </Stack>
    </StepFooter>
  );
};
