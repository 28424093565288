import { Badge } from "@mantine/core";

export const MemberStatusBadge = ({
  status,
}: {
  status: "active" | "inactive";
}) => {
  switch (status) {
    case "active":
      return (
        <Badge c="green.5" color="green.1" size="xs" radius="xl">
          Active
        </Badge>
      );
    case "inactive":
      return (
        <Badge c="red.5" color="red.1" size="xs" radius="xl">
          Inactive
        </Badge>
      );
  }
};
