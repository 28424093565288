import { UsMediumDateFormatter } from "@/helpers/string";
import { partnerConfigAtom } from "@/stores";
import { Button, List, Stack, Text, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useAtomValue } from "jotai";
import { getEnrollmentAtom } from "../API";

export const CompletedStep = ({ onNext }: { onNext: () => void }) => {
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);

  return (
    <Stack gap={24} h="calc(100vh - 64px)" justify="center" align="center">
      <Title order={2}>Selections complete - here are next steps</Title>

      <List>
        <List.Item>
          We'll work with your broker and carriers to review and fulfill your
          benefits selections.
        </List.Item>
        <List.Item>You'll receive a confirmation email shortly.</List.Item>
        <List.Item>
          Keep an eye out for further emails if additional information is
          needed.
        </List.Item>
      </List>
      <Text>
        Once confirmed, your newly selected benefits will appear in{" "}
        {platformName} on{" "}
        {UsMediumDateFormatter.format(Date.parse(enrollment.change_date))}.
      </Text>

      <Button onClick={onNext} rightSection={<IconArrowRight size={16} />}>
        Back to Benefits Summary
      </Button>
    </Stack>
  );
};
