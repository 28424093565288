import { LineOfCoverage, PlanBundle, PlanQuote } from "@/types/api";
import { Alert, Button, Flex, Stack, Text, Title } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import {
  getEligiblePlansAtom,
  getEnrollmentAtom,
  memberDependentsAtom,
  memberInfoAtom,
} from "../API";
import { PlanQuoteWithDependents, useEnrollContext } from "../EnrollProvider";
import { DependentsCard } from "./DependentsCard";
import { PlanCard } from "./PlanCard";
import { WaiveCard } from "./WaiveCard";
import { useTimeout } from "@mantine/hooks";

const findIneligibleBundlePlan = (
  lineOfCoverage: LineOfCoverage,
  state: ReturnType<typeof useEnrollContext>["state"],
  bundle?: PlanBundle,
) => {
  if (!bundle) return false;
  const otherLinesOfCoverage = bundle.plans
    .map(({ line_of_coverage }) => line_of_coverage)
    .filter((loc) => loc !== lineOfCoverage);

  return otherLinesOfCoverage.find((otherLineOfCoverage) => {
    const quoteWithDependents = state[
      otherLineOfCoverage
    ] as PlanQuoteWithDependents;
    return (
      quoteWithDependents?.quote === null ||
      (quoteWithDependents?.quote &&
        quoteWithDependents?.quote.plan.bundle?.id !== bundle.id)
    );
  });
};

export const CoverageStep = ({
  lineOfCoverage,
}: {
  lineOfCoverage: LineOfCoverage;
}) => {
  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const [eligiblePlans, setEligiblePlans] = useState<PlanQuote[]>([]);
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const dependents = useAtomValue(memberDependentsAtom);
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFakeLoading, setIsFakeLoading] = useState(true);

  const { start } = useTimeout(() => setIsFakeLoading(false), 1.5 * 1000); // 1.5s second timeout

  const { state, dispatch, setStep } = useEnrollContext();

  const { quote: selected, dependents: selectedDependents } = state[
    lineOfCoverage
  ] as PlanQuoteWithDependents;

  const allBundles = eligiblePlans.reduce((acc, { plan: { bundle } }) => {
    if (bundle) {
      acc.set(bundle.id, bundle);
    }
    return acc;
  }, new Map<string, PlanBundle>());

  const selectedBundles = [state.medical, state.dental, state.vision].reduce(
    (acc, { quote }) => {
      if (
        quote?.plan?.bundle?.id &&
        quote.plan.line_of_coverage !== lineOfCoverage
      ) {
        acc.add(quote.plan.bundle.id);
      }

      return acc;
    },
    new Set<string>(),
  );

  const selectedBundle =
    selectedBundles.size > 0
      ? allBundles.get([...selectedBundles][0])
      : undefined;

  const selectedBundlePlanIds = selectedBundle?.plans
    ?.filter((plan) => plan.line_of_coverage === lineOfCoverage)
    ?.map((plan) => plan.id);

  const eligibleBundlePlanIds =
    eligiblePlans
      .filter((planQuote) => selectedBundlePlanIds?.includes(planQuote.plan.id))
      .map(({ plan }) => plan.id) || [];

  const onSelect = (quote: PlanQuote | null) => {
    switch (lineOfCoverage) {
      case "medical": {
        dispatch({
          type: "set-medical",
          payload: { quote },
        });
        break;
      }
      case "vision": {
        dispatch({
          type: "set-vision",
          payload: { quote },
        });
        break;
      }
      case "dental": {
        dispatch({
          type: "set-dental",
          payload: { quote },
        });
        break;
      }
    }
  };

  const onSelectDependents = (dependents: string[]) => {
    switch (lineOfCoverage) {
      case "medical": {
        dispatch({
          type: "set-medical",
          payload: { dependents },
        });
        break;
      }
      case "vision": {
        dispatch({
          type: "set-vision",
          payload: { dependents },
        });
        break;
      }
      case "dental": {
        dispatch({
          type: "set-dental",
          payload: { dependents },
        });
        break;
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setIsFakeLoading(true);
    start();
    fetchEligiblePlans([
      {
        body: { dependents: selectedDependents },
        enrollmentId: enrollment.id,
      },
    ])
      .then((eligiblePlans) => {
        setEligiblePlans(eligiblePlans);
        if (selected) {
          onSelect(
            eligiblePlans.find((quote) => quote.plan.id == selected.plan.id),
          );
        }
      })
      .finally(() => setIsLoading(false));
  }, [enrollment, selectedDependents]);

  const plans = useMemo(
    () =>
      eligiblePlans
        .filter(
          (planQuote) => planQuote.plan.line_of_coverage == lineOfCoverage,
        )
        .sort(
          (first, second) =>
            Number(first.member_contribution) -
            Number(second.member_contribution),
        ),
    [eligiblePlans],
  );

  const mustResetWhenWaivingSelection = eligibleBundlePlanIds.length > 0;
  const hasIneligibleBundlePlans = eligiblePlans.some(
    ({ plan: { bundle } }) =>
      !!findIneligibleBundlePlan(lineOfCoverage, state, bundle),
  );
  const restartSelection = () => {
    dispatch({
      type: "reset",
    });
    setStep(null);
  };

  return (
    <Stack gap={24}>
      <Title size="h3">1. Decide who will be enrolled in this plan</Title>

      <DependentsCard
        memberInfo={memberInfo}
        dependents={dependents}
        selectedDependents={selectedDependents}
        onSelectDependents={onSelectDependents}
      />
      <Title size="h3">2. Choose a plan or waive coverage</Title>

      {eligibleBundlePlanIds.length > 0 && (
        <Alert
          title="You've selected a bundled plan"
          icon={<IconExclamationCircle />}
        >
          <Stack gap="xs">
            <Text>
              Some options in this step are unavailable because you've selected
              the following bundled plan: {selectedBundle.name}. If you would
              like to choose one of the unavailable options, please select a new
              plan in the previous steps or reset all plans.
            </Text>
            <Flex>
              <Button variant="outline" onClick={restartSelection}>
                Reset Selections
              </Button>
            </Flex>
          </Stack>
        </Alert>
      )}
      {eligibleBundlePlanIds.length == 0 && hasIneligibleBundlePlans && (
        <Alert
          title="Certain plan bundles are unavailable"
          icon={<IconExclamationCircle />}
        >
          <Stack gap="xs">
            <Text>
              Some plans in this step are unavailable because they are part of a
              bundled plan. If you would like to choose one of the unavailable
              plans, please select a new plan in the previous steps or reset all
              plans.
            </Text>
            <Flex>
              <Button variant="outline" onClick={restartSelection}>
                Reset Selections
              </Button>
            </Flex>
          </Stack>
        </Alert>
      )}

      {(isLoading || isFakeLoading ? [{ plan: {} } as never] : plans).map(
        (planQuote) => {
          const {
            plan: { bundle, id },
          } = planQuote;

          let mustResetSelection = false;

          // Is this a different bundle (or not in bundle) but one bundle was already selected ?
          if (
            eligibleBundlePlanIds.length > 0 &&
            !eligibleBundlePlanIds.includes(id)
          ) {
            mustResetSelection = true;
          } else if (bundle) {
            // Is this a new bundle that contains plans from other selections?
            mustResetSelection = !!findIneligibleBundlePlan(
              lineOfCoverage,
              state,
              bundle,
            );
          }

          return (
            <PlanCard
              key={id}
              planQuoteWithDependents={{
                quote: planQuote,
                dependents: selectedDependents,
              }}
              selected={selected}
              onSelect={() => onSelect(planQuote)}
              canBeSelected={!mustResetSelection}
              loading={isLoading || isFakeLoading}
            />
          );
        },
      )}
      <WaiveCard
        selected={selected}
        onSelect={() => onSelect(null)}
        canBeSelected={!mustResetWhenWaivingSelection}
      />
    </Stack>
  );
};
