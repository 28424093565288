import { partnerConfigAtom, useNavigate } from "@/stores";
import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { putMagicLinkAtom } from "./API";
import { ClaspBrokerMagicLinkDialog } from "./Magic";

interface Props {
  magicDialog?: boolean;
}

export const ClaspConnectChoicePage = ({ magicDialog = false }: Props) => {
  const navigate = useNavigate();
  const { name: platformName } = useAtomValue(partnerConfigAtom[0]);
  const [data, putMagicLink] = useAtom(putMagicLinkAtom);

  useEffect(() => {
    if (magicDialog) {
      putMagicLink([undefined]);
    }
  }, [magicDialog, putMagicLink]);

  return (
    <Container size={704}>
      <ClaspBrokerMagicLinkDialog opened={magicDialog} data={data} />

      <Stack gap="xl">
        <Title>Connect your benefits to {platformName}</Title>
        <Text size="sm">
          Connect your benefits effortlessly. Onboard yourself with your
          benefits information, or simply send a link to your broker to handle
          it for you.
        </Text>
        <Flex gap="md" wrap="nowrap">
          <Card shadow="xs" radius="lg" padding="xl">
            <Stack align="stretch" justify="space-between" h="100%">
              <Title order={4}>Send your broker a setup link</Title>
              <Text size="sm">
                Not sure about the details? Simply send an onboarding link to
                your insurance broker.
              </Text>
              <Text size="sm">
                They'll fill it in for you. It's hassle-free, secure, and
                ensures accuracy.
              </Text>
              <Space h="sm" />
              <Center>
                <Button onClick={() => navigate("magic")}>
                  Generate broker link
                </Button>
              </Center>
            </Stack>
          </Card>

          {/* TODO: Add back in the line Dividers here */}
          {/* <Divider orientation="vertical" h="40%" /> */}
          <Flex direction="column" justify="space-evenly">
            <Text c="gray.4">or</Text>
          </Flex>

          <Card shadow="xs" radius="lg" padding="xl">
            <Stack align="stretch" justify="space-between" h="100%">
              <Title order={4}>Connect your benefits yourself</Title>
              <Text size="sm">
                If you're equipped with the right information, go ahead and
                input it yourself.
              </Text>
              <Text size="sm">
                With your benefit details on hand, this should take less than 5
                minutes.
              </Text>
              <Space h="sm" />
              <Center>
                <Button variant="outline" onClick={() => navigate("form")}>
                  Connect existing plan
                </Button>
              </Center>
            </Stack>
          </Card>
        </Flex>
      </Stack>
    </Container>
  );
};
