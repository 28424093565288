import { Grid, GridColProps, Stack, Text } from "@mantine/core";

export const PlanInfoItem = ({
  header,
  value,
  ...rest
}: {
  header: React.ReactNode;
  value: React.ReactNode;
} & GridColProps) => (
  <Grid.Col {...rest}>
    <Stack>
      <Text size="sm" fw="bold" c="gray.6" style={{ whiteSpace: "nowrap" }}>
        {header}
      </Text>
      <Text size="sm" fw="bold">
        {value}
      </Text>
    </Stack>
  </Grid.Col>
);
