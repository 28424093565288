import { useAtomValue } from "jotai";
import { medicalMembersAtom, medicalPlansAtom } from "./API";
import { ReviewPage } from "./ReviewPage";

export const MedicalReviewPage = () => {
  const medical_plans = useAtomValue(medicalPlansAtom);
  const enrollees = useAtomValue(medicalMembersAtom);
  return (
    <ReviewPage
      medical_plans={medical_plans}
      enrollees={enrollees}
      reviewTitle={"Review: Medical Benefits Summary"}
      reviewDescription={
        "Dive into your insurance plan details. Everything you see here is provided directly by your insurance carriers."
      }
      plansTableTitle={"Medical plan details"}
      enrolleeTableTitle={"Medical enrollment summary"}
      nextState={"dental_review"}
      stepIndex={5}
    />
  );
};
