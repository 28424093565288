import { isEmbedded } from "@/helpers";
import { usePostMessageToWrapper } from "@/helpers/hooks";
import { Alert, Center, Text } from "@mantine/core";
import { ErrorBoundary as SentryErrorBoundry } from "@sentry/react";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
type ErrorBoundaryProps = {
  errorMessage?: string;
};
export const ErrorBoundary = ({ errorMessage }: ErrorBoundaryProps) => {
  const error = useRouteError();
  const postMessageToWrapper = usePostMessageToWrapper();

  useEffect(
    () =>
      error &&
      postMessageToWrapper({
        type: "clasp-app-error",
        error: error.toString(),
      }),
    [error, postMessageToWrapper],
  );

  return (
    <SentryErrorBoundry>
      <Center h="100dvh">
        <Alert
          color="gray"
          title="Sorry, we've encountered an error"
          icon={<IconExclamationCircle />}
          maw={650}
        >
          {isEmbedded() ? (
            <Text>
              {errorMessage ||
                "Something went wrong, please refresh and try again."}
            </Text>
          ) : (
            <Text>
              {errorMessage ||
                "Something went wrong, please request a new link and try again."}
            </Text>
          )}
        </Alert>
      </Center>
    </SentryErrorBoundry>
  );
};
