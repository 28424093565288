import { WaitingPeriod } from "@/types/api";

export const snakeCaseToTitleCase = (input?: string | null): string => {
  return input
    ? input.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase())
    : "";
};

export const parseDate = (isoString: string): Date => {
  const dateParts = isoString.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);
  return new Date(year, month, day);
};

export const normalizeDate = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const UsDateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "short",
  timeZone: "UTC",
});

export const UsMediumDateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "long",
  timeZone: "UTC",
});

export const UsCurrencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  style: "currency",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const toISODateString = (date: Date) => date.toISOString().split("T")[0];

export const formatSSN = (ssn: string) =>
  ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");

export const formatWaitingPeriod = (waitingPeriod: WaitingPeriod) => {
  switch (waitingPeriod.policy) {
    case "first_of_month":
      return `First of Month after ${waitingPeriod.days} Days`;
    case "end_of_waiting_period":
      return `${waitingPeriod.days} Days after Hire Date`;
    default:
      return `${waitingPeriod.days} Days`;
  }
};
