import { useState } from "react";
import { EnrollProvider } from "./EnrollProvider";
import { EnrollStepper } from "./EnrollStepper";
import { TermsAndConditions } from "./TermsAndConditions";
import { CompletedStep } from "./CompletedStep";
import { useNavigate } from "@/stores";

export const EmployeeEnroll = () => {
  const [isPreScreen, setIsPreScreen] = useState(true);
  const [isPostScreen, setIsPostScreen] = useState(false);
  const navigate = useNavigate();

  const handleCompletedStep = () => {
    navigate("employee_home");
  };

  return (
    <EnrollProvider>
      {isPreScreen && (
        <TermsAndConditions onNext={() => setIsPreScreen(false)} />
      )}
      {isPostScreen && <CompletedStep onNext={handleCompletedStep} />}
      {!isPreScreen && !isPostScreen && (
        <EnrollStepper onCompleted={() => setIsPostScreen(true)} />
      )}
    </EnrollProvider>
  );
};
