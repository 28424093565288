export { ClaspEmployee } from "@/ClaspEmployee";
export { ClaspEmployer } from "@/ClaspEmployer";
export * from "@/types";

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

if (
  ["sandbox", "production"].includes(import.meta.env.VITE_CLASP_ENVIRONMENT)
) {
  Sentry.init({
    dsn: "https://b002f862155c65521ecff5a040aa493f@o4506352357933056.ingest.us.sentry.io/4507487489687552",
    integrations: [Sentry.captureConsoleIntegration()],
    environment: import.meta.env.VITE_CLASP_ENVIRONMENT,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
