import { Employer, EnrollmentStatus, Member } from "@/types/api";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconBell, IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EnrollmentOverview from "./EnrollmentOverview";
import {
  useApproveEnrollment,
  useDeleteEnrollment,
  useGetEnrollment,
} from "./hooks";

export const ViewEnrollmentDetail = () => {
  const { enrollmentId } = useParams();
  const { data } = useGetEnrollment(enrollmentId!);
  const navigate = useNavigate();
  const [approveEnrollmentModalOpened, setApproveEnrollmentModalOpened] =
    useState(false);
  const [deleteEnrollmentModalOpened, setDeleteEnrollmentModalOpened] =
    useState(false);
  const member = data?.member as Member | undefined;
  const handleReturnToList = () => {
    navigate("/employer/view-enrollments");
  };

  const effectiveEnd =
    data?.effective_end === "9999-12-31" ? "- " : data?.effective_end;

  return (
    <Stack gap="xl">
      <Breadcrumbs separator={<IconChevronRight size={17} />}>
        <Anchor onClick={handleReturnToList}>Enrollments</Anchor>
        <Anchor c="gray.8" underline="never">
          {member?.first_name} {member?.last_name}
        </Anchor>
      </Breadcrumbs>
      <Stack gap={"xs"}>
        <Group justify="space-between">
          <Text size={"xl"} fw={600}>
            {(member?.employer as Employer)?.trade_name ||
              (member?.employer as Employer)?.legal_name}{" "}
            - {member?.first_name} {member?.last_name} Enrollment (
            {data?.change_date} to {effectiveEnd})
          </Text>
          {data?.status === EnrollmentStatus.Pending && (
            <Group gap="xs">
              <Button
                size="xs"
                variant="outline"
                onClick={() => setDeleteEnrollmentModalOpened(true)}
              >
                Delete Enrollment
              </Button>
              <Button
                size="xs"
                onClick={() => setApproveEnrollmentModalOpened(true)}
              >
                Approve Enrollment
              </Button>
            </Group>
          )}
        </Group>
        <EnrollmentOverview />
        <ApproveEnrollmentModal
          opened={approveEnrollmentModalOpened}
          onClose={() => setApproveEnrollmentModalOpened(false)}
        />
        <DeleteEnrollmentModal
          opened={deleteEnrollmentModalOpened}
          onClose={() => setDeleteEnrollmentModalOpened(false)}
        />
      </Stack>
    </Stack>
  );
};

type ModalProps = {
  opened: boolean;
  onClose: () => void;
};

const ApproveEnrollmentModal = ({ opened, onClose }: ModalProps) => {
  const approveEnrollment = useApproveEnrollment();
  const { enrollmentId } = useParams() as { enrollmentId: string };
  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false}>
      <Stack align="center">
        <IconBell />
        <Text fw={600}>Approve Qualifying Life Event</Text>
        <Text>
          Approving this qualifying life event will automatically push changes{" "}
          to certain carriers.
        </Text>
        <Button
          fullWidth
          onClick={() => {
            approveEnrollment.mutate(
              { enrollmentId },
              {
                onSuccess: () => {
                  notifications.show({
                    color: "green",
                    title: "Enrollment Approved",
                    message:
                      "You can find tasks associated to this enrollment in the Tasks tab.",
                  });
                  onClose();
                },
              },
            );
          }}
          loading={approveEnrollment.isPending}
        >
          Approve
        </Button>
        <Button fullWidth variant="subtle" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};
const DeleteEnrollmentModal = ({ opened, onClose }: ModalProps) => {
  const deleteEnrollment = useDeleteEnrollment();
  const { enrollmentId } = useParams() as { enrollmentId: string };
  const navigate = useNavigate();
  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false}>
      <Stack align="center">
        <IconBell />
        <Text fw={600}>Delete Qualifying Life Event</Text>
        <Text>
          Are you sure you want to delete this qualifying life event? This is an
          irreversible action.
        </Text>
        <Button
          fullWidth
          onClick={() => {
            deleteEnrollment.mutate(
              { enrollmentId },
              {
                onSuccess: () => {
                  navigate("/employer/view-enrollments");
                },
              },
            );
          }}
          loading={deleteEnrollment.isPending}
        >
          Delete
        </Button>
        <Button fullWidth variant="subtle" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};
