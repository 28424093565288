import { authAtom } from "@/App";
import { getClaspApiUrl } from "@/helpers";
import { entityIdAtom } from "@/stores";
import { useStytch } from "@stytch/react";
import axios from "axios";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const AppAuthenticate = () => {
  const stytchClient = useStytch();
  const [searchParams] = useSearchParams();
  const setEntityId = useSetAtom(entityIdAtom);
  const navigate = useNavigate();
  const setAuth = useSetAtom(authAtom);

  useEffect(() => {
    const login = async () => {
      const token = searchParams.get("token");
      const sessionData = await stytchClient.magicLinks.authenticate(token, {
        session_duration_minutes: 60,
      });
      const environment = sessionData.user.trusted_metadata.environment as
        | "development"
        | "sandbox"
        | "production";
      const baseUrl = getClaspApiUrl(environment);
      const resp = await axios.post(
        `${baseUrl}/access/authenticate_stytch_session`,
        {},
        {
          withCredentials: true,
          headers: { "Stytch-Session-ID": sessionData.session_token },
        },
      );
      setAuth(resp.data);
      localStorage.setItem(
        "member_id",
        sessionData.user.trusted_metadata.member_id as string,
      );
      setEntityId(sessionData.user.trusted_metadata.member_id as string);
      localStorage.removeItem("employer_id");
      localStorage.setItem("environment", environment);
      localStorage.setItem("component", "member");
      navigate("/member");
    };
    login();
  }, [searchParams]);

  return <></>;
};
