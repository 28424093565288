import { useAtomValue } from "jotai";
import { dentalMembersAtom, dentalPlansAtom } from "./API";
import { ReviewPage } from "./ReviewPage";

export const DentalReviewPage = () => {
  const medical_plans = useAtomValue(dentalPlansAtom);
  const enrollees = useAtomValue(dentalMembersAtom);
  return (
    <ReviewPage
      medical_plans={medical_plans}
      enrollees={enrollees}
      reviewTitle={"Review: Dental Benefits Summary"}
      reviewDescription={
        "Dive into your insurance plan details. Everything you see here is provided directly by your insurance carriers."
      }
      plansTableTitle={"Dental plan details"}
      enrolleeTableTitle={"Dental enrollment summary"}
      nextState={"vision_review"}
      stepIndex={6}
    />
  );
};
