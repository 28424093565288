import { clientAtom, configAtom } from "@/stores";
import { ClaspEmployeeConfig } from "@/types";
import { mutationAtomType } from "@/types/jotai";
import dayjs from "dayjs";
import { Atom } from "jotai";
import { atomsWithMutation, queryClientAtom } from "jotai-tanstack-query";

export const [, setEmployeeAcaDigitalFormsConsentAtom] = atomsWithMutation(
  (get) => ({
    mutationKey: ["postMember"],
    mutationFn: async (acaDigitalFormsEnabled: boolean) => {
      const client = await get(clientAtom);
      return await client.updateMember(
        get(configAtom as unknown as Atom<ClaspEmployeeConfig>).employeeId,
        {
          aca_digital_forms_enabled_at: acaDigitalFormsEnabled
            ? dayjs().format()
            : null,
        },
      );
    },
    onSuccess(_, { memberId }) {
      const queryClient = get(queryClientAtom);
      queryClient.invalidateQueries({ queryKey: ["members", memberId] });
    },
  }),
) as [any, mutationAtomType];
