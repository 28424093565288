import { CarrierImage } from "@/components/CarrierImage";
import { StepFooter } from "@/components/StepFooter";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { formatWaitingPeriod } from "@/helpers/string";
import { useNavigate } from "@/stores";
import { Anchor, Stack, Text, Title } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { enrollment_columns } from "./Final";

interface Props {
  medical_plans: any; // TODO : type this once it's determined what it'll be
  enrollees: any;
  reviewTitle: string;
  reviewDescription: string;
  plansTableTitle: string;
  enrolleeTableTitle: string;
  nextState: string;
  stepIndex: number;
}

const plan_columns = [
  {
    header: "",
    accessorKey: "group.carrier.id",
    Cell: ({ renderedCellValue }) => (
      <CarrierImage carrierId={`${renderedCellValue}`} />
    ),
  },
  {
    header: "plan type",
    accessorKey: "group.carrier.trade_name",
  },
  {
    header: "plan name",
    accessorKey: "plan_name",
  },
  {
    header: "group number",
    accessorKey: "group.group_number",
  },
  {
    header: "enrollees",
    accessorKey: "enrollees",
    Cell: ({ row }) => (
      <>
        {row.original.member_count} employees
        <br />
        {row.original.dependent_count} dependents
      </>
    ),
  },
  {
    header: "Additional Details",
    accessorKey: "details",
    width: "2fr",
    Cell: ({ row }) => (
      <Stack gap={1}>
        <Text size="sm">
          <b>Termination Policy:</b> <br />
          {row.original.termination_policy}
        </Text>
        <Text size="sm">
          <b>Waiting Period:</b> <br />
          {formatWaitingPeriod(row.original.waiting_period)}
        </Text>
        <Text size="sm">
          <b>Effective Dates:</b> <br />
          {row.original.effective_start} - {row.original.effective_end}
        </Text>
      </Stack>
    ),
  },
];

export const ReviewPage = ({
  medical_plans,
  enrollees,
  reviewTitle,
  reviewDescription,
  plansTableTitle,
  enrolleeTableTitle,
  nextState,
  stepIndex,
}: Props) => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate(nextState);
  };
  const enrollmentTable = useMantineReactTable({
    columns: enrollment_columns,
    data: enrollees,
    ...DEFAULT_TABLE_PROPS,
  });
  const detailsTable = useMantineReactTable({
    columns: plan_columns,
    data: medical_plans,
    ...DEFAULT_TABLE_PROPS,
  });

  return (
    <StepFooter index={stepIndex} total={8} onContinue={onSubmit}>
      <Stack gap="lg">
        <Title order={2}>{reviewTitle}</Title>
        <Text>{reviewDescription}</Text>
        <Stack gap="xs">
          <Title order={3}>{plansTableTitle}</Title>
          <Anchor target="_blank" href="https://withclasp.com" fs="italic">
            See errors in your plan details? Report an issue.
          </Anchor>
        </Stack>
        <MantineReactTable table={detailsTable} />
        <Title order={3}>{enrolleeTableTitle}</Title>
        <MantineReactTable table={enrollmentTable} />
      </Stack>
    </StepFooter>
  );
};
